<app-navbar [collapse]="collapsed"></app-navbar>
<div *ngIf="localStorageDisabled" style="height: 100vh; width: 100%; padding-top: 10%;"
    class="d-flex align-items-center flex-column">
    <h1>Cookies and LocalStorage have been disabled</h1>
    <h2>This site requires cookies to function properly. Please enable cookies in your browser settings</h2>
    <a mat-raised-button href="https://www.nap.edu/content/enabling-cookies-in-your-browser" target="_blank">How to
        enable cookies</a>
</div>
<div #router *ngIf="!localStorageDisabled">
    <router-outlet></router-outlet>
</div>