<div class="wrapper">
    <div class="landingPageContainer" smoothScroll id='infinite'>
        <div class="mountains" *ngIf="!platform.IOS && !platform.SAFARI "> </div>
        <div class="mountains-NonFixed" *ngIf="platform.IOS || platform.SAFARI "> </div>
        <div class="particles"> </div>
        <div class="smoke"></div>
        <div class="landingPageMainImageContainer" id="shadow">
            <h1 class="landingPageHeader">Cof Airsoft</h1>
            <div class="landingPageImage"></div>
            <div class="triangleContainer">
                <app-nodes [ngStyle]="{'z-index': 2}"></app-nodes>
            </div>
        </div>
        <app-about-us-page></app-about-us-page>
        <app-events-page></app-events-page>
        <app-contact-us-page></app-contact-us-page>
        <div #contact> </div>
    </div>
</div>