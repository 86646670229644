<div class="bg">
    <app-nodes class="nodes" [ngStyle]="{'z-index': 0}"></app-nodes>
    <div class="container">
        <div class="rules-container p-2">
            <div class="w-75">
                <h1 class="rulesHeading">Rulebook </h1>
            </div>

            <mat-accordion>
                <mat-expansion-panel *ngFor="let key of ruleNames; index as idx" class="panel" color="accent"
                    (opened)="openIndex(idx)" (closed)="opened = opened == idx ? -1 : opened">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon [ngClass]="opened != idx ? 'text-white': 'text-dark'" style="margin-right: 5px;"
                                class="d-flex align-items-center">{{ruleData[idx].icon}}
                            </mat-icon>

                        </mat-panel-title>
                        <p [ngClass]="opened != idx ? 'text-white': 'text-dark'" class="rule">
                            {{key}}

                        </p>
                    </mat-expansion-panel-header>
                    <p *ngFor="let rule of ruleData[idx].ruleDescription">- {{rule}}</p>
                </mat-expansion-panel>

            </mat-accordion>
        </div>


    </div>

</div>