import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';
import { SnippetService } from './snippet.service';

@Injectable({
  providedIn: 'root'
})
export class UpdateServiceService {

  constructor(public updates: SwUpdate, private snippet: SnippetService) {
    if (updates.isEnabled) {
      interval(15000).subscribe(() => updates.checkForUpdate()
        .then().catch(err => console.error(err)));
    }
  }

  public checkForUpdates(): void {
    this.updates.available.subscribe(event => this.promptUser());
  }

  private promptUser(): void {
    console.log('updating to new version');
    this.snippet.openUpdateSnippet();
  }
}

