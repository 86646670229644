import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from './material';

import { NavbarComponent } from './navbar/navbar.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { NodesComponent } from './nodes/nodes.component';
import { EventsPageComponent } from './pages/events-page/events-page.component';
import { AboutUsPageComponent } from './pages/about-us-page/about-us-page.component';
import { ContactUsPageComponent } from './pages/contact-us-page/contact-us-page.component';

//firebase
// 1. Import the libs you need
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';

import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import {
	DialogComponent,
	LoginComponent,
	EventDialogComponent,
	UserRegistrationDialogComponent,
	PasswordResetDialog,
	EmailReminderDialog,
	BookingDialogComponent,
	RefundNotificationComponent,
} from './dialog/dialog.component';
import { EventsDetailPageComponent } from './pages/events-detail-page/events-detail-page.component';
import { RankingsPageComponent } from './pages/rankings-page/rankings-page.component';

//maps
import { AgmCoreModule } from '@agm/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { EditorModule } from '@tinymce/tinymce-angular';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SmoothScrollModule } from 'ngx-scrollbar/smooth-scroll';
import { RoleGuardService } from './services/role-guard.service';
import { SnackErrorComponent, SnackSuccessComponent, UpdateSnackComponent } from './services/snippet.service';
import { ResponsiveSidenavDirective } from './responsive-sidenav/responsive-sidenav.directive';
import { RulesComponent } from './pages/rules/rules.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { ConfirmDialogComponent } from './adminPages/confirm-dialog/confirm-dialog.component';

import { Router, Scroll, Event } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { filter } from 'rxjs/operators';
import { OrderByPipe } from './pipes/sort-by.pipe';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { consentConfig } from './cookies';
import { TextFieldModule } from '@angular/cdk/text-field';

// 2. Add your credentials from step 1
// apiKey: 'AIzaSyAmDyI1Yg1UiDqvgBerJnX78_DMMFIaNPw',
// authDomain: 'cofairsoft-a1d63.firebaseapp.com',
// databaseURL: 'https://cofairsoft-a1d63.firebaseio.com',
// projectId: 'cofairsoft-a1d63',
// storageBucket: 'cofairsoft-a1d63.appspot.com',
// messagingSenderId: '256190960268',
// appId: '1:256190960268:web:44428e80917e0456ebe1a9',
// measurementId: 'G-Z0YZ9GNFNW',
const firebaseConfig = {
	apiKey: 'AIzaSyAmDyI1Yg1UiDqvgBerJnX78_DMMFIaNPw',
	authDomain: 'cofairsoft-a1d63.firebaseapp.com',
	databaseURL: 'https://cofairsoft-a1d63.firebaseio.com',
	projectId: 'cofairsoft-a1d63',
	storageBucket: 'cofairsoft-a1d63.appspot.com',
	messagingSenderId: '256190960268',
	appId: '1:256190960268:web:44428e80917e0456ebe1a9',
	measurementId: 'G-Z0YZ9GNFNW',
};

@NgModule({
	declarations: [
		AppComponent,
		NavbarComponent,
		NodesComponent,
		//dialogs & pages
		LandingPageComponent,
		AboutUsPageComponent,
		EventsPageComponent,
		ContactUsPageComponent,
		DialogComponent,
		LoginComponent,
		PasswordResetDialog,
		EmailReminderDialog,
		UserRegistrationDialogComponent,
		EventDialogComponent,
		EventsDetailPageComponent,
		RankingsPageComponent,
		ConfirmDialogComponent,
		SnackErrorComponent,
		SnackSuccessComponent,
		UpdateSnackComponent,
		ResponsiveSidenavDirective,
		RulesComponent,
		OrderByPipe,
		BookingDialogComponent,
		RefundNotificationComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		MaterialModule,
		FormsModule,
		ReactiveFormsModule,
		AngularFireModule.initializeApp(firebaseConfig),
		AngularFirestoreModule,
		AngularFirestoreModule.enablePersistence(),
		AngularFireAuthModule,
		AngularFireStorageModule,
		AgmCoreModule.forRoot({
			// apiKey: 'AIzaSyBrmQh0JtJIfhZlRjI-GSwerM-uL0TGjkU'
			apiKey: 'AIzaSyDZH1hOSrThy7z5Raze7pgsmIRU6FySSCM',
		}),
		HttpClientModule,
		InfiniteScrollModule,
		EditorModule,
		NgScrollbarModule,
		SmoothScrollModule,
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production,
			registrationStrategy: 'registerWithDelay:1000',
		}),
		AngularFireAnalyticsModule,
		NgcCookieConsentModule.forRoot(consentConfig),
		TextFieldModule,
	],
	entryComponents: [
		LoginComponent,
		EventDialogComponent,
		UserRegistrationDialogComponent,
		ConfirmDialogComponent,
		PasswordResetDialog,
		SnackSuccessComponent,
		EmailReminderDialog,
		SnackErrorComponent,
		UpdateSnackComponent,
	],
	providers: [
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: { appearance: 'outline' },
		},
		RoleGuardService,
	],
	bootstrap: [AppComponent],
	exports: [ResponsiveSidenavDirective],
})
export class AppModule {
	constructor(router: Router, viewportScroller: ViewportScroller) {
		router.events.pipe(filter((e: Event): e is Scroll => e instanceof Scroll)).subscribe((e) => {
			if (e.position) {
				// backward navigation
				viewportScroller.scrollToPosition(e.position);
			} else if (e.anchor) {
				// anchor navigation
				viewportScroller.scrollToAnchor(e.anchor);
			} else {
				// forward navigation
				viewportScroller.scrollToPosition([0, 0]);
			}
		});
	}
}
