import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-about-us-page',
  templateUrl: './about-us-page.component.html',
  styleUrls: ['./about-us-page.component.scss']
})
export class AboutUsPageComponent implements OnInit {

  constructor(public el: ElementRef<HTMLElement>) { }

  mouseX: number;
  mouseY: number;


  angleLogo(event: MouseEvent) {
    // 
    this.mouseX = event.pageX - this.el.nativeElement.offsetLeft;
    this.mouseY = event.pageY - this.el.nativeElement.offsetTop;
  }

  ngOnInit(): void {

  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    this.angleLogo(event);
  }

}
