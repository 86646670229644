// src/app/auth/role-guard.service.ts
import { Injectable } from '@angular/core';
import {
	Router,
	CanActivate,
	ActivatedRouteSnapshot
} from '@angular/router';
import { AuthService } from './auth.service';
import { Observable, of } from 'rxjs';
import { DatabaseService } from './database.service';
import { map, switchMap } from 'rxjs/operators';



@Injectable()
export class RoleGuardService implements CanActivate {
	constructor(public auth: AuthService, public router: Router, private db: DatabaseService) { }
	canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {

		return new Observable(sub => {

			const expectedRole = route.data.expectedRole;


			this.auth.isLoggedIn()
				.then(
					(loggedIn) => {
						if (loggedIn) {

							if (expectedRole == 'user') {
								sub.next(true)
							} else {
								let obs = this.auth.afAuth.user.pipe(
									map(user => {
										if (!user) {

											sub.next(false)
											return null
										}
										return user.uid;
									}),
									switchMap(userID => {
										if (userID)
											return this.db.isAdmin(userID, expectedRole)
										return of(false)
									})
								).subscribe(
									isAdmin => {
										sub.next(isAdmin)
									},
									err => {

										sub.next(false)
									},
									() => {
										obs.unsubscribe()
									}
								)
							}


						}
						else {
							sub.next(false)
						}
					}
				)

		})
	}
}