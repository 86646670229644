import { Component, OnDestroy, OnInit, Inject } from '@angular/core';;
import { Subscription, of } from 'rxjs';
import { GpsHelpComponent } from 'src/app/pages/live-event-page/participation/GpsHelp.component';
import { AuthService } from 'src/app/services/auth.service';
import { SnippetService } from 'src/app/services/snippet.service';
import { DatabaseService } from "../../../services/database.service"
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import firebase from 'firebase/app';

@Component({
    selector: 'GpsRegistration',
    templateUrl: 'liveEventGpsRegistration.html',
})
export class GpsRegistrationSheet implements OnInit, OnDestroy {
    constructor(
        private _bottomSheetRef: MatBottomSheetRef<GpsRegistrationSheet>,
        private db: DatabaseService,
        private auth: AuthService,
        private snippet: SnippetService,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    ) {
    }

    userID: string;
    GPS_ID: number;
    GPSstate: string;
    GPSError: string;
    currentPosition: any;
    eventPosition: any;

    ngOnInit() {

        // check the satelite location first and ensure it is within a range of the event gps 
        this.auth.user.subscribe(user => {
            this.userID = user.uid;
            this.findCurrentLocation()
        })
    }


    ngOnDestroy() {
        if (this.registerParticipantSub != null)
            this.registerParticipantSub.unsubscribe();
        if (this.GPS_ID)
            navigator.geolocation.clearWatch(this.GPS_ID);
        if (this.bonusXPSub)
            this.bonusXPSub.unsubscribe();



    }


    findCurrentLocation() {
        if (navigator.geolocation) {
            this.GPS_ID = navigator.geolocation.watchPosition(this.showPosition, this.showError, { enableHighAccuracy: true, maximumAge: 0, timeout: 7000 });
        } else {
            //change this to a snippet
            alert("Geolocation is not supported by this browser.");
        }
    }

    registerParticipant(claimBonuses: boolean = false) {
        // fetch the team of the user first 

        //if you remove this people register in loops :O
        //stop watching since we are in range now
        navigator.geolocation.clearWatch(this.GPS_ID);

        if (this.registerParticipantSub != null)
            this.registerParticipantSub.unsubscribe();

        this.registerParticipantSub = this.db.RegisterParticipantForEvent(this.auth.user.value.uid, this.data.eventID, Number(this.data.team))
            .subscribe((batch) => {
                this.registerParticipantSub.add(
                    of(batch.commit())
                        .subscribe(() => {
                            this.GPSstate = "Registered"
                            this.snippet.openSuccessSnackBar("Welcome to the event soldier!")
                            firebase.analytics().logEvent('event_participant', {
                                success: 'true',
                                BonusXP: "false",
                                event_title: this.data.eventTitle,
                                GPS: "true"
                            });
                            if (claimBonuses)
                                this.ClaimBonusXP()
                            else {
                                this.registerParticipantSub.unsubscribe()
                                this._bottomSheetRef.dismiss()
                            }
                        })
                )
            }, err => {
                this.snippet.openErrorSnackBar(err)
                this.registerParticipantSub.unsubscribe();
                this._bottomSheetRef.dismiss()
            })
    }


    bonusXPSub: Subscription;
    ClaimBonusXP() {
        if (this.bonusXPSub != null)
            this.bonusXPSub.unsubscribe();

        this.bonusXPSub = this.db.ClaimBonusXP(this.data.eventID, this.userID).subscribe(batchStatement => {
            this.bonusXPSub.add(of(batchStatement[0][2].commit()).subscribe(
                () => {
                    this.snippet.openSuccessSnackBar("Rewards Claimed!")
                    firebase.analytics().logEvent('event_participant', {
                        success: 'true',
                        BonusXP: "true",
                        event_title: this.data.eventTitle,
                        GPS: "true"
                    });
                    this.bonusXPSub.unsubscribe();
                    this._bottomSheetRef.dismiss()
                }
            ))
        }, err => {
            console.error(err);
            this.snippet.openErrorSnackBar(err)
            this._bottomSheetRef.dismiss()
            this.bonusXPSub.unsubscribe();
        })


    }

    gpsFeedback = ""
    //this has to be a es5 function as a property of class , for some reason typscript methods dont have the same scope as the javascript ones -_-
    showPosition = (position) => {

        this.GPSstate = "Awaiting";
        this.currentPosition = { lat: position.coords.latitude, long: position.coords.longitude };
        let distance = this.distance(position.coords.latitude, position.coords.longitude, this.data.eventLocation[0], this.data.eventLocation[1], "K");


        // IF WITHIN 1KM of THE EVENT ALLOW REGISTER
        if (distance <= 1.0) {

            //check the mode one is registration the other is claim bonus XP 
            if (this.data.mode == 'register')
                this.registerParticipant(this.data.bonusButtonDisabled);
            else if (this.data.mode == 'bonusXP') {
                navigator.geolocation.clearWatch(this.GPS_ID);
                this.ClaimBonusXP();
            }

        }
        else {
            this.gpsFeedback = "<div class='font-weight-bold'>Current distance from event:</div> " + distance.toPrecision(3).toString() + "km. " + "<p></p><div class='font-weight-bold'> Device GPS Accuracy: </div> " + Number(position.coords.accuracy).toFixed(2).toString() + " m."
            this.GPSstate = "OutOfRange"
        }


    }

    //     openHelpDialog() {
    //         this.helpdialog.open(GpsHelpComponent, {
    //             width: '80%',
    //         })
    //     }

    showError = (error) => {
        switch (error.code) {
            case error.PERMISSION_DENIED:
                this.GPSError = "ERROR <p class='text-dark'>User denied the request for Geolocation!</p> <p class='text-dark'>Please check your settings and allow this page to access gps information</p>"
                break;
            case error.POSITION_UNAVAILABLE:
                this.GPSError = "Location information is unavailable."
                break;
            case error.TIMEOUT:
                this.GPSError = "The request to get user location timed out."
                break;
            case error.UNKNOWN_ERROR:
                this.GPSError = "An unknown error occurred."
                break;

        }

    }

    distance(lat1, lon1, lat2, lon2, unit) {
        if ((lat1 == lat2) && (lon1 == lon2)) {
            return 0;
        }
        else {
            let radlat1 = Math.PI * lat1 / 180;
            let radlat2 = Math.PI * lat2 / 180;
            let theta = lon1 - lon2;
            let radtheta = Math.PI * theta / 180;
            let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
            if (dist > 1) {
                dist = 1;
            }
            dist = Math.acos(dist);
            dist = dist * 180 / Math.PI;
            dist = dist * 60 * 1.1515;
            if (unit == "K") { dist = dist * 1.609344 }
            if (unit == "N") { dist = dist * 0.8684 }
            return dist;
        }
    }

    registerParticipantSub: Subscription;



}
