import { ChangeDetectorRef, Component, EventEmitter, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DatabaseService } from '../../services/database.service'
import { AuthService } from '../../services/auth.service';
import { Observable, of, forkJoin, combineLatest, Subscription } from 'rxjs';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { map, switchMap } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { SnippetService } from 'src/app/services/snippet.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { LogData, ReasonIdType } from '../../services/database.service';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { ConfirmDialogComponent } from '../../adminPages/confirm-dialog/confirm-dialog.component';

class BottomSheetData {
	userPatchList: any[];
	userPendingPatchList: any[];
	success: EventEmitter<string>
}


//bottom sheets 
import { MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';


/**
 * @title Bottom Sheet Overview
 */
@Component({
	selector: 'profile-sheet',
	templateUrl: 'profile-sheet.html',
	styleUrls: ['profile-sheet.scss']
})
export class ProfileSheet implements OnInit, OnDestroy {
	constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: BottomSheetData, private _bottomSheetRef: MatBottomSheetRef<ProfileSheet>, private authService: AuthService, private db: DatabaseService, private snippet: SnippetService, private changeDetector: ChangeDetectorRef) { }



	patches: any;
	selectedPatch: any;
	loadingPatches: boolean = true;


	ngOnDestroy() {
		if (this.ProfileSheetSub)
			this.ProfileSheetSub.unsubscribe();
	}



	ProfileSheetSub: Subscription;
	ngOnInit() {

		this.ProfileSheetSub = new Subscription()
		let newSub = this.db.GetFullPatchList().subscribe(
			patches => {


				this.patches = patches;
				this.loadingPatches = false;
				this.changeDetector.markForCheck();
			}
		)
		this.ProfileSheetSub.add(newSub);
	}

	selectPatch(patch) {

		if (this.data.userPatchList && this.data.userPatchList.find(p => { return patch[0].id == p; })) {
			this.snippet.openErrorSnackBar("You already have this patch")
			return;
		}
		if (this.data.userPendingPatchList && this.data.userPendingPatchList.find(p => { return patch[0].id == p; })) {
			this.snippet.openErrorSnackBar("You have already requested this patch")
			return;
		}
		this.selectedPatch = patch;

	}

	FetchPatchImage(patch): Observable<string> {
		return this.db.FetchPatchImage(patch.id)
	}

	private imageIn: any;
	imageUploaded: boolean = false;

	uploadPatchImage(imageInput: any) {
		this.imageIn = imageInput;
		const file: File = this.imageIn.files[0];

		if (file) {
			const reader = new FileReader();

			reader.addEventListener('loadend', (event: any) => {
				this.imageUploaded = true;
				this.changeDetector.markForCheck();
			});



			reader.addEventListener('error', (event: any) => {
				this.imageUploaded = false;
				this.snippet.openErrorSnackBar("Image upload failed.")
			});

			reader.readAsDataURL(file);
		}
	}

	submitting: boolean;
	uploadRequest() {
		this.submitting = true;
		const file: File = this.imageIn.files[0];
		if (file) {
			const reader = new FileReader();
			reader.addEventListener('loadend', (event: any) => {

				let authsub = this.authService.user.subscribe(user => {
					this.db.uploadPatchRequestImage(file, user.uid, this.selectedPatch[0].id)
						.then(complete => {
							this.snippet.openSuccessSnackBar("Patch request submitted");
							this.data.success.emit("success")
							this._bottomSheetRef.dismiss();
						}).catch(() => {
							this.snippet.openErrorSnackBar("Patch request failed!")
							this.data.success.emit("failed")
							this._bottomSheetRef.dismiss();
						});
				},
					(err: any) => {
						console.error(err);
						this.snippet.openErrorSnackBar("Patch request failed!");
						this.data.success.emit("failed")
						authsub.unsubscribe()
						this._bottomSheetRef.dismiss();
					},
					() => { authsub.unsubscribe() })

			});

			reader.readAsDataURL(file);
		}
	}

}


//end sheets




@Component({
	selector: 'app-profile-page',
	templateUrl: './profile-page.component.html',
	styleUrls: ['./profile-page.component.scss'],
})
export class ProfilePageComponent implements OnInit, OnDestroy {

	displayedInfo: string = "profile"

	sanitize(url: string) {
		return this.sanitization.bypassSecurityTrustStyle(`url('${url}')`);
	}

	sanitizeImage(url: string) {
		return this.sanitization.bypassSecurityTrustUrl(url);
	}


	constructor(
		private authService: AuthService,
		private db: DatabaseService,
		private sanitization: DomSanitizer,
		private router: Router,
		private active: ActivatedRoute,
		private snippet: SnippetService,
		private _bottomSheet: MatBottomSheet,
		private changeDetector: ChangeDetectorRef,
		private dialog: MatDialog
	) { }
	success: EventEmitter<string>;


	public get ReasonIdType(): typeof ReasonIdType {
		return ReasonIdType;
	}

	getBadgesSub: Subscription;
	getPatchesSub: Subscription;
	ngOnDestroy() {
		if (this.getPatchesSub)
			this.getPatchesSub.unsubscribe()
		if (this.getBadgesSub)
			this.getBadgesSub.unsubscribe()
		if (this.initSubscription)
			this.initSubscription.unsubscribe()
		if (this.getPendingSub)
			this.getPendingSub.unsubscribe()
	}



	private viewingUser: any;
	canSeeLogs: boolean = false;
	viewingProfileIsUser: boolean = false;
	userProfile: any;
	serviceTime: any;
	initSubscription: Subscription;
	mode = 'view';
	ngOnInit(): void {
		this.success = new EventEmitter<string>();
		this.initSubscription = new Subscription();


		this.initSubscription.add(
			this.authService.afAuth.user.subscribe(user => {
				if (user != null) {
					this.viewingUser = user;

					this.initSubscription.add(
						this.active.queryParams.pipe(
							map(params => {
								return params.userID
							})
						).subscribe(
							userID => {
								this.mode = 'view'
								this.viewingProfileIsUser = false;
								this.canSeeLogs = false
								if (!userID)
									this.LoadProfile(this.viewingUser.uid, this.initSubscription);
								else {
									this.LoadProfile(userID, this.initSubscription);
								}
							}
						)
					)



				} else {
					this.router.navigate(["/"]);
				}
			})
		)

	}

	IsAdmin() {
		this.initSubscription.add(this.db.isAdmin(this.viewingUser.uid, 'admin').subscribe(
			isAdmin => {
				this.canSeeLogs = isAdmin;

				if (!isAdmin && this.viewingUser.uid == this.userProfile.user.id)
					this.canSeeLogs = true;
			}));
	}

	LoadProfile(paramUserID: string, teardownSub: Subscription) {
		teardownSub.add(

			this.db.FetchUser(paramUserID)
				.pipe(
					map(user => {
						return [
							of(user),
							this.db.FetchProfileImage(user.id),
							this.db.FetchTeamImage(user.id)
						]
					}),
					switchMap(data => { return forkJoin(data) })
				)
				.subscribe(data => {
					this.calculateRank(data[0].data().xp)
					const epochNow = Date.now() / 1000;
					this.serviceTime = epochNow - Number(data[0].data()['serviceTime']['seconds']);
					this.userProfile = { user: data[0], image: data[1], teamImage: data[2] }
					if (this.viewingUser.uid == this.userProfile.user.id) {
						this.viewingProfileIsUser = true;
					}
					this.IsAdmin();

				}, err => {
					this.router.navigate(["/"]);
				})
		)
	}



	//logging
	displayedColumns: string[] = ['Timestamp', 'Log', 'IssuerId'];
	dataSource: MatTableDataSource<LogData>;
	/** Constants used to fill up our data base. */
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;
	LogsSub: Subscription;

	FetchLogs(userID: string) {
		this.displayedInfo = 'logs'
		this.db.FetchUserLogs(userID).pipe(
			map(docs => {
				return docs.docs.map(
					log => {
						const data = log.data();
						let reason: Observable<string>;
						if (data.reasonIdType == ReasonIdType.User)
							reason = this.db.GetUsername(data.reasonID)
						else if (data.reasonIdType == ReasonIdType.Event)
							reason = this.db.GetEventName(data.reasonID)
						else
							reason = of("XP Redeemed")

						return combineLatest([
							of(data),
							reason
						])
					}
				)
			}), switchMap(data => {
				return forkJoin(data)
			})
		).subscribe(
			logs => {
				let logsArray: LogData[] = []
				logs.forEach((log) => {
					if (log[1]) {
						let newLog = { logMessage: log[0].logMessage, logType: log[0].logType, reasonID: log[1], timeStamp: log[0].timeStamp, reasonIdType: log[0].reasonIdType }
						logsArray.push(newLog)
					}
				})

				logsArray.sort((a, b) => {
					return b.timeStamp - a.timeStamp;
				})


				this.dataSource = new MatTableDataSource(logsArray);
				this.dataSource.paginator = this.paginator;
				this.dataSource.sort = this.sort;
			},
			err => {
				console.error(err);

			}
		)
	}

	GetUserName(userID: string): Observable<string> {
		return this.db.GetUsername(userID)
	}

	GetEventName(eventID: string) {
		return this.db.GetEventName(eventID)
	}


	calcServiceTime() {
		return (Number(this.serviceTime) * 0.00000038).toFixed(0);
	}



	rank: any;
	maxrank = false;
	bufferValue: number = 100;
	calculateRank(xp: number) {
		this.db.FetchRanks().subscribe(data => {

			let playerRank = 0;

			let keys = Object.keys(data.data().ranks);
			for (let i = 0; i < keys.length - 1; i++) {
				playerRank = i;
				if (Number(keys[i]) >= xp) {
					if (i > 0)
						playerRank--;
					break;
				}
			}

			let values = Object.values(data.data().ranks)
			let percentComplete: number = 100;
			let xpToNext = 0;

			if (playerRank < Number(keys[keys.length - 2])) {
				let rankDiff = Number(keys[playerRank + 1]) - Number(keys[playerRank]);
				let xpToNext = Number(keys[playerRank + 1]) - xp;
				if (rankDiff == rankDiff)
					percentComplete = ((rankDiff - xpToNext) / rankDiff) * 100;
				else {
					percentComplete = 100;
					this.maxrank = true;
				}
			} else {
				playerRank = Number(keys[keys.length - 1]);
				percentComplete = 100;
			}


			if (percentComplete == 0)
				percentComplete = 2;

			let nextLevel = keys[playerRank + 1];
			if (!nextLevel)
				nextLevel = keys[playerRank]


			this.rank = {
				rank: values[playerRank],
				percent: 0,
				level: playerRank,
				xpToNext: nextLevel,
				xp: xp
			}
			this.bufferValue = 0;
			setTimeout(() => {
				this.rank.percent = percentComplete
			}, 300)


		})
	}

	usernameValidation(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
		return this.db.CheckIfUserExists(control.value).pipe(
			map((data, idx) => {
				if (!data.empty) {

					control.setErrors({ notUnique: "Username is not unique" })

					return of(false);
				}
				else {

					return null;
				}
			})
		)
	}


	RequestPatch() {
		this._bottomSheet.open(ProfileSheet,
			{
				data: {
					userPatchList: Object.keys(this.userProfile.user.data().patches),
					userPendingPatchList: this.pendingPatchesStringList,
					success: this.success
				}
			}
		);

		let sub = this.success.subscribe(complete => {
			if (complete == 'success') {
				this.FetchUserPendingPatches()
			}
			this.success = new EventEmitter<string>()
			sub.unsubscribe();
		})
	}

	showSpinner: boolean;
	SaveProfile() {


		let obj = {}



		if (this.profileForm.valid) {



			if (this.profileForm.controls.username.value != null && this.profileForm.controls.username.value != '') {
				obj["userID"] = this.profileForm.controls.username.value;
			}
			if (this.profileForm.controls.phone.value != null && this.profileForm.controls.phone.value != '') {
				obj["phoneNumber"] = this.profileForm.controls.phone.value;
			}
			if (this.profileForm.controls.fullname.value != null && this.profileForm.controls.fullname.value != '') {
				obj["fullname"] = this.profileForm.controls.fullname.value;
			}
			if (this.profileForm.controls.social.value != null && this.profileForm.controls.social.value != '') {
				obj["social"] = this.profileForm.controls.social.value;
			}

			if (this.croppedImage != null) {
				this.showSpinner = true;
				this.uploadProfilePicture(this.imageFile, !Object.keys(obj).length);
			}

			if (Object.keys(obj).length) {
				this.showSpinner = true;
				this.db.UpdateUserDocument(obj, this.userProfile).subscribe(
					promise => {
						promise.then(() => {
							this.snippet.openSuccessSnackBar("Profile Updated")
							this.profileForm.reset()
							this.LoadProfile(this.userProfile.user.id, this.initSubscription);
							this.showSpinner = false;
						}).catch(
							err => {
								console.error(err)
								this.snippet.openErrorSnackBar("Failed to Update Profile!")
								this.showSpinner = false;
							}
						);
					}
				)



			}
		}

	}


	ResetProfile() {
		this.profileForm.reset();
		this.croppedImage = null;
		this.imageChangedEvent = '';
		this.imageFile = null;
		this.changeDetector.markForCheck();
	}

	DeleteTeam() {
		let dialogRef: MatDialogRef<ConfirmDialogComponent, any> = this.dialog.open(ConfirmDialogComponent, { data: { confirmString: 'Please confirm to delete your team details' } })
		dialogRef.afterClosed().subscribe((result: boolean) => {
			if (result) {
				this.db.DeleteUserTeam(this.userProfile.user.id)
					.subscribe(
						(promise => {
							promise
								.then(
									() => {
										this.snippet.openSuccessSnackBar("Team details deleted")
										delete this.userProfile.user.data().team
										window.location.reload();
									}
								).catch(err => {
									this.snippet.openErrorSnackBar("Error deleting team details")
								})
						})
					)
			}
		})
	}

	SaveTeam() {
		if (this.profileTeamForm.valid) {


			if (!this.userProfile.teamImage) {
				this.snippet.openErrorSnackBar("A Team Image is required");
				return;
			}
			this.authService.user.subscribe(
				user => {
					this.db.SetUserTeam(user.uid, this.profileTeamForm.controls.teamName.value).then(() => {
						this.LoadProfile(this.userProfile.user.id, this.initSubscription);
						this.snippet.openSuccessSnackBar("Team Updated");
						this.profileTeamForm.reset();
					});
				}
			)

		} else {
			this.snippet.openErrorSnackBar("Team Form invalid");
		}
	}

	profileForm = new FormGroup({
		username: new FormControl(null, {
			updateOn: "change",
			validators: Validators.pattern("^[A-Za-z0-9_.]+$"),
			asyncValidators: [
				this.usernameValidation.bind(this)
			]
		}),
		fullname: new FormControl(null, {
			updateOn: 'change',
			validators: Validators.pattern("^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$")
		}),
		phone: new FormControl(null, {
			updateOn: 'change',
			validators: Validators.pattern("^\\d{3}(-| )*\\d{3}(-| )*\\d{4}$|^\\+\\d{4}(-| )*\\d{3}(-| )*\\d{4}$|^\\+\\d{2}(-| )*\\d{2}(-| )*\\d{3}(-| )*\\d{4}$")
		}),
		social: new FormControl(null, {
			updateOn: 'change',
			validators: Validators.max(35)
		})
	})

	profileTeamForm = new FormGroup({
		teamName: new FormControl(null, {
			updateOn: "change",
			validators: Validators.max(25)
		})


	})

	rewardsDataLoaded: boolean = false;
	LoadRewards(user) {

		if (!this.rewardsDataLoaded) {
			this.FetchPatches(user.data().patches);
			this.FetchBadges(user.data().badges);
			this.FetchUserPendingPatches();
		}


		this.rewardsDataLoaded = true;
	}


	ChangeMode(mode: string) {
		this.mode = mode;

	}

	badges: any;
	loadingBadges = false;
	badgesEmpty = false;
	FetchBadges(badges) {
		if (Object.keys(badges).length) {
			this.loadingBadges = true;
			this.getBadgesSub = this.db.GetUserBadges(badges).subscribe(badges => {
				this.loadingBadges = false;
				this.badges = badges;

			}, (err) => {
				console.error(err)
			})
		} else {
			this.badgesEmpty = true;
		}

	}

	patches: any;
	loadingPatches = false;
	patchesEmpty = false;
	FetchPatches(patches) {
		if (Object.keys(patches).length) {
			this.loadingPatches = true;
			this.getPatchesSub = this.db.GetUserPatches(patches).subscribe(patches => {
				this.loadingPatches = false;
				this.patches = patches

			}, (err) => {
				console.error(err)
			})
		} else {
			this.patchesEmpty = true;
		}
	}

	pendingPatches: any;
	pendingPatchesStringList: string[]
	getPendingSub: Subscription;
	fetchPendingComplete: boolean = false;
	FetchUserPendingPatches() {
		if (this.getPendingSub)
			this.getPendingSub.unsubscribe()

		this.getPendingSub = this.db.GetUserPendingPatchRequests(this.userProfile.user.id)
			.subscribe(pendingList => {
				this.pendingPatches = pendingList;

				if (pendingList != null)
					this.pendingPatchesStringList = pendingList.map(pending => {
						return pending[0].data().patchID;
					})
				this.fetchPendingComplete = true;
			},
				(err => {
					if (err == "pending.empty")
						this.fetchPendingComplete = true;
					else
						console.error(err)
				}))
	}


	uploadingProfilePicture: boolean = false;
	uploadProfilePicture(imageInput: any, clearSpinner) {

		this.uploadingProfilePicture = true;
		const file: File = imageInput

		if (file) {
			const reader = new FileReader();

			reader.addEventListener('loadend', (event: any) => {

				let authsub = this.authService.user.subscribe(user => {
					return this.db.uploadFile(file, user.uid)
						.then(task => {



							let sub = this.db.FetchProfileImage(user.uid).subscribe(
								(image) => {

									this.userProfile.image = image;
									this.croppedImage = null;
									this.imageChangedEvent = '';
									this.imageFile = null;
									this.changeDetector.markForCheck();
									this.uploadingProfilePicture = false;
									if (clearSpinner) {
										this.snippet.openSuccessSnackBar("image Updated");
										this.showSpinner = false;
									}
								}
								, err => {
									this.snippet.openSuccessSnackBar("Failed to load new image");
								},
								() => {
									sub.unsubscribe();
								}
							)

						});
				},
					(err: any) => {
						console.error(err);
						this.snippet.openErrorSnackBar("Failed to update image");
						authsub.unsubscribe()
					},
					() => { authsub.unsubscribe() })

			});

			reader.readAsDataURL(file);
		}

	}

	uploadProfileTeamPicture(imageInput: any) {
		const file: File = imageInput.files[0];
		if (file) {
			const reader = new FileReader();

			reader.addEventListener('loadend', (event: any) => {

				let authsub = this.authService.user.subscribe(user => {
					this.db.uploadTeamImage(file, user.uid).then(task => {
						this.snippet.openSuccessSnackBar("image Updated");

						let sub = this.db.FetchTeamImage(user.uid).subscribe(
							(image) => {
								this.userProfile.teamImage = image;
							}
							, err => {
								this.snippet.openSuccessSnackBar("Failed to load new image");
							},
							() => {
								sub.unsubscribe();
							}
						)


					}).catch(() => {
						this.snippet.openErrorSnackBar("image upload failed!")
					});;
				},
					(err: any) => {
						console.error(err);
						this.snippet.openErrorSnackBar("Failed to update image");
						authsub.unsubscribe()
					},
					() => { authsub.unsubscribe() })

			});

			reader.readAsDataURL(file);
		}

	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
		this.dataSource.sort;

		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}


	convertDataUrlToBlob(dataUrl): Blob {
		const arr = dataUrl.split(',');
		const mime = arr[0].match(/:(.*?);/)[1];
		const bstr = atob(arr[1]);
		let n = bstr.length;
		const u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}

		return new Blob([u8arr], { type: mime });
	}

	imageChangedEvent: any = '';
	croppedImage: any = null;
	showCropper = false;
	imageFile: any;

	fileChangeEvent(event: any): void {
		this.imageChangedEvent = event;
	}
	imageCropped(event: ImageCroppedEvent) {
		// this.imageFile = this.b64toBlob(event.base64, 'jpg', 256)
		this.croppedImage = event.base64;
		// console.log(this.croppedImage);
		this.imageFile = this.convertDataUrlToBlob(event.base64);
	}
	imageLoaded(image: LoadedImage) {
		// show cropper
		console.log("image uploaded");
		this.showCropper = true;
	}
	cropperReady() {
		// cropper ready
	}
	loadImageFailed() {
		console.log("image upload failed");

		// show message
	}

}


