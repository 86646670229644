<div>
	<div class="userCreated">
		<div class="w-100">
			<h1 class="display-1">
				Welcome to CofAirsoft {{regForm.controls.username.value}}
			</h1>
		</div>
	</div>
	<hr style="width: 90%;" />
	<div class="d-flex flex-column justify-content-center align-items-center" *ngIf="!getAuthService()">
		<button mat-flat-button style="outline: none !important; width: 252px;" (click)=" onGoogleClick()">
			<img style="max-width: 185px; height: 50px;" src="../../assets/images/googleSignIn.png" />
		</button>

		<hr style="width: 90%;" />
		<h3 class="display-4 w-100 text-center">Or</h3>
		<h3 class="display-4 w-100 text-center">Register with email</h3>
	</div>

	<form hidden *ngIf="!userCreated" [formGroup]="regForm" class="profileForm d-flex flex-column justify-content-center align-items-center">
		<mat-form-field class="m-3" style="width: 85%; max-width: 600px;" appearance="outline">
			<mat-label>Email</mat-label>
			<input formControlName="email" matInput placeholder="Cof@CofAirsoft.co.za" />
			<mat-icon matSuffix>email</mat-icon>
			<mat-error class="text-danger" *ngIf="regForm.controls.email.errors && regForm.controls.email.errors.pattern">
				Email format is incorrect
			</mat-error>
			<mat-error class="text-danger" *ngIf="regForm.controls.email.errors && regForm.controls.email.errors.required">
				Email is required
			</mat-error>
			<mat-error *ngIf="regForm.controls.email.errors && regForm.controls.email.errors.saveError">
				{{regForm.controls.email.errors.saveError}}</mat-error
			>
		</mat-form-field>

		<mat-form-field style="width: 85%; max-width: 600px;" class="m-1" appearance="outline">
			<mat-label>Enter a callsign</mat-label>
			<input formControlName="username" matInput placeholder="Cool new Username" />
			<mat-icon matSuffix>border_color</mat-icon>
			<mat-error class="text-danger" *ngIf="NotUnique">
				Callsign is not unique
			</mat-error>
			<mat-error class="text-danger" *ngIf="regForm.controls.username.errors && regForm.controls.username.errors.pattern">
				Callsign must use [A-z][0-9][ ._] characters only
			</mat-error>
			<mat-error class="text-danger" *ngIf="regForm.controls.username.errors && regForm.controls.username.errors.required">
				Callsign is required
			</mat-error>
		</mat-form-field>

		<mat-form-field style="width: 85%; max-width: 600px;" class="m-1" appearance="outline">
			<mat-label>Enter your fullname</mat-label>

			<input formControlName="fullname" matInput placeholder="Fullname" />
			<mat-icon matSuffix>border_color</mat-icon>
			<mat-error class="text-danger" *ngIf="regForm.controls.fullname.invalid">
				Name format not valid ,[A-z] and spacing only
			</mat-error>
		</mat-form-field>

		<mat-form-field style="width: 85%; max-width: 600px;" class="m-1" appearance="outline">
			<mat-label>Password</mat-label>
			<input formControlName="password" matInput [type]="hide ? 'password' : 'text'" placeholder="..." />
			<button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
				<mat-icon matSuffix>border_color</mat-icon>
			</button>
			<mat-error class="text-danger" style="font-size: x-small;" *ngIf="regForm.controls.password.invalid">
				Password must be [8-20] digits & at least one number.
			</mat-error>
		</mat-form-field>

		<mat-form-field style="width: 85%; max-width: 600px;" class="m-1" appearance="outline">
			<mat-label>Confirm Password</mat-label>
			<input formControlName="confirmPassword" matInput [type]="hide ? 'password' : 'text'" />
			<button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
				<mat-icon matSuffix>border_color</mat-icon>
			</button>
			<mat-error class="text-danger" *ngIf="passwordMismatch">
				Passwords do not match
			</mat-error>
		</mat-form-field>

		<mat-form-field style="width: 85%; max-width: 600px;" class="m-1" appearance="outline">
			<mat-label>Enter your phone number </mat-label>

			<input formControlName="phone" matInput placeholder="+2784 123 4567" />
			<mat-icon matSuffix>border_color</mat-icon>
			<mat-hint>*optional</mat-hint>
		</mat-form-field>

		<mat-form-field style="width: 85%; max-width: 600px;" class="m-1" appearance="outline">
			<mat-label>Enter any Social link </mat-label>
			<input formControlName="social" matInput placeholder="@CofAirsoft" />
			<mat-icon matSuffix>share</mat-icon>
			<mat-hint>*optional</mat-hint>
		</mat-form-field>

		<mat-form-field style="width: 85%; max-width: 600px;" class="m-1" appearance="outline">
			<mat-label>Enter Referral Email Address</mat-label>
			<input formControlName="referral" matInput placeholder="awesomeplayer@gmail.com" />
			<mat-icon matSuffix>border_color</mat-icon>
			<mat-hint
				>Did a player refer introduce you to Cof Airsoft? Enter his email address and you'll both receive 5 free XP when you first register, pay for,
				and join a game.</mat-hint
			>
		</mat-form-field>

		<mat-dialog-actions class="m-1" align="end">
			<button (click)="registerUser()" mat-raised-button style="margin-right: 5px;">
				Save
			</button>
			<button [mat-dialog-close] mat-raised-button style="margin-right: 5px;">
				Cancel
			</button>
		</mat-dialog-actions>
	</form>
</div>
