import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import firebase from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/auth";
import { DatabaseService } from './database.service';
import { BehaviorSubject, forkJoin, Observable, of, Subscription } from 'rxjs';
import { userData } from 'src/app/services/database.service'
import { HttpClient } from '@angular/common/http';
import { SnippetService } from './snippet.service';
import { map, switchMap } from 'rxjs/operators';

interface existingUser { new: boolean, user: userData }

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	user: BehaviorSubject<firebase.User>;
	public admin: Observable<boolean | null>;
	public marshal: Observable<boolean | null>;

	constructor(public afAuth: AngularFireAuth, public router: Router, private db: DatabaseService, private http: HttpClient, private snippet: SnippetService) {
		// console.log("AUTH")
		this.getUser();
	}

	FetchUserSubs: Subscription;
	getUser() {
		let authState = this.afAuth.authState.subscribe(userAuth => {
			if (userAuth) {
				this.user = new BehaviorSubject<firebase.User>(userAuth);

				this.user.subscribe(user => {
					if (user) {
						localStorage.setItem('user', JSON.stringify(user));
						let getUserSub = this.db.GetUserDocument(user.uid).get().subscribe(data => {
							//When using providers if no existing user account data exists we must fetch user data and create their profile
							if (!data.exists && data.data() == null) {

								firebase.analytics().logEvent('sign_up', {
									method: "Google"
								});

								let obj = {

									admin: false,
									marshal: false,
									fullname: user.displayName,
									matchesPlayed: 0,
									phoneNumber: user.phoneNumber,
									serviceTime: firebase.firestore.Timestamp.fromDate(new Date()),
									userID: user.displayName,
									xp: 0,
									badges: {},
									patches: {},
									social: "",
								};

								//try fetch their profile image and upload it to storageBucket
								this.http.get(this.user.value.photoURL, { responseType: 'blob', headers: { 'Access-Control-Request-Method': 'GET', "Access-Control-Request-Headers": "Content-Type" } }).subscribe(val => {
									this.db.uploadFile(val, user.uid);
								});

								this.db.CreateNewUserDocument(obj, user.uid).then(success => {
									try {
										this.db.RedeemXP(user.uid, user.email.toLowerCase())
									} catch (error) {
										if (error == 1)
											this.db.RedeemXPError(user.email.toLowerCase());
									}
									this.admin = of(false);
									this.marshal = of(false);
								}).catch(err => {

									console.log("Failed to create user account");
								});

							} else {
								this.admin = of(data.data().admin);
								this.marshal = of(data.data().marshal);
							}
						}, (err: any) => {

							getUserSub.unsubscribe();
						}, () => {
							getUserSub.unsubscribe();
						})
					}

				})


			}
		}, (err: any) => {

			authState.unsubscribe()
		}, () => {
			authState.unsubscribe();
		})

	}

	login(email: string, password: string) {
		return this.afAuth.signInWithEmailAndPassword(email, password);
	}



	async register(email: string, password: string) {
		let result = await this.afAuth.createUserWithEmailAndPassword(email, password)
		this.sendEmailVerification();

	}

	async sendEmailVerification() {
		(await this.afAuth.currentUser).sendEmailVerification();
	}

	async sendPasswordResetEmail(passwordResetEmail: string) {
		return await this.afAuth.sendPasswordResetEmail(passwordResetEmail);
	}

	async logout() {
		await this.afAuth.signOut();
		this.user = null;
		localStorage.removeItem('user');
	}

	async isLoggedIn() {
		return JSON.parse(localStorage.getItem('user'));
	}

	loginWithGoogle() {
		return this.afAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider()).then(() => { })
	}



	onGoogleClickIOS() {

		let provider = new firebase.auth.GoogleAuthProvider();
		// Start a sign in process for an unauthenticated user.
		provider.addScope('profile');
		provider.addScope('email');


		this.afAuth.signInWithRedirect(provider).then()
		this.afAuth.getRedirectResult().then(function (result) {

			firebase.analytics().logEvent('login', {
				method: "Google"
			});

			this.user = new BehaviorSubject(result.user);
			this.snippet.openSuccessSnackBar("Welcome back")
		}).catch(
			err => {
				console.error(err);
			}
		);

	}

}
