import { Component, Input, OnDestroy, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of, Subscription } from 'rxjs';
import { map, switchMap, } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database';
import { AuthService } from '../services/auth.service';
import { DatabaseService } from '../services/database.service';
import { FormControl, Validators } from '@angular/forms';
import { BezierEasingOptions, SmoothScroll, SmoothScrollElement } from 'ngx-scrollbar/smooth-scroll';
import firebase from 'firebase';


interface message {
	photo: any;
	username: String;
	message: String;
	timeStamp: number;
}


@Component({
	selector: 'app-chat',
	templateUrl: './chat.component.html',
	styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {

	@ViewChild(SmoothScroll) infinite: SmoothScroll;
	@ViewChildren('bottom') bottom: SmoothScrollElement;
	@Input() eventID: String;
	@Input() teamName: string;
	@Input() teamIndex: Number;
	item: Observable<any>;
	public chat: Observable<any>;
	private userID: string;
	private userName: string;
	private userImage: string;
	public messageList: Map<String, any>;
	private chatSub: Subscription;
	private HistorySub: Subscription;
	private lowestTimeStamp: number;

	loadingMessages: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(true);

	throttle = 50;
	scrollDistance = 1;
	scrollUpDistance = 2.5;

	constructor(private realTimeDB: AngularFireDatabase, private auth: AuthService, private db: DatabaseService) {

	}



	ngOnInit(): void {

		let scrollSub = this.loadingMessages.subscribe(
			(loading) => {
				if (!loading) {

					setTimeout(() => {
						let Options: BezierEasingOptions = { x1: 0, y1: 0, x2: 0.58, y2: 1 };
						this.infinite.scrollToElement(this.bottom, { duration: 2, easing: Options })
					}, 1000);
				}
			}
		)


		this.loadingMessages.next(true);
		this.auth.afAuth.user
			.pipe(
				switchMap(user => {
					return combineLatest([this.db.GetUsername(user.uid), this.db.FetchProfileImage(user.uid), of(user.uid)])
				})
			)
			.subscribe(
				(userData) => {

					this.lowestTimeStamp = Date.now();
					this.userID = userData[2];
					this.userImage = userData[1];
					this.userName = userData[0];

					this.chat = this.realTimeDB.list(`${this.eventID}/${this.teamIndex}`, ref => ref.limitToFirst(10)).snapshotChanges();
					this.messageList = new Map<String, any>();
					this.chatSub = this.chat.pipe(
						map(messages => {

							if (messages.length == 0) {
								this.loadingMessages.next(false);
							}
							return messages.map(a => {

								const data = a.payload.val();
								const messageID = a.payload.key
								// console.log(data)
								let ID = Object.keys(data).filter((key) => {
									return key != "timeStamp"
								})[0]

								this.lowestTimeStamp = data.timeStamp < this.lowestTimeStamp ? data.timeStamp : this.lowestTimeStamp;
								return combineLatest([
									of({ messageID, data, ID }),
									this.db.GetUserDocument(ID).get(),
									this.db.FetchProfileImage(ID)
								])
							})
						}),
						switchMap(data => {
							return forkJoin(data)
						})

					).subscribe(messages => {

						let index = 0;
						// console.log(messages)
						messages.forEach(message => {
							let text = message[0].data[message[0].ID]
							index++
							if (index == messages.length)
								this.loadingMessages.next(false);


							if (!this.messageList.has(message[0].messageID))
								this.messageList.set(message[0].messageID, { message: text, username: message[1].data().userID, photo: message[2], timeStamp: message[0].data.timeStamp, IsUserMessage: (message[1].uid == this.userID) })

						})
						this.loadingMessages.next(false);

					})
				}
			)
	}




	ngOnDestroy() {
		if (this.chatSub)
			this.chatSub.unsubscribe();
		if (this.HistorySub)
			this.HistorySub.unsubscribe()
	}


	chatFormControl = new FormControl('', [
		Validators.minLength(1),
	]);


	pad(num) {
		return ("0" + num).slice(-2);
	}
	getTimeFromDate(timestamp) {
		var date = new Date(timestamp);
		var hours = date.getHours();
		var minutes = date.getMinutes();
		var seconds = date.getSeconds();
		return this.pad(hours) + ":" + this.pad(minutes) + ":" + this.pad(seconds)
	}

	sendMessage() {
		let obj = {}
		let msg: string = this.chatFormControl.value;
		if (msg.trim() != "" && this.chatFormControl.value) {
			obj[this.userID] = this.chatFormControl.value;
			obj["timeStamp"] = Date.now();
			this.realTimeDB.list(`${this.eventID}/${this.teamIndex}`).push(obj).then((
				(dbRef: firebase.database.ThenableReference) => {
					dbRef.setPriority(0 - Date.now(), () => { }).then()
					this.messageList.set(dbRef.key.toString(), { message: msg, username: this.userName, photo: this.userImage, timeStamp: obj["timeStamp"] })

				}
			));

		}
		this.chatFormControl.reset();

	}


	onScrollUp() {

		this.FetchPreviousMessages();
	}


	onScrollDown() {

	}

	FetchPreviousMessages() {
		if (this.HistorySub)
			this.HistorySub.unsubscribe()
		this.HistorySub = this.realTimeDB.list(
			`${this.eventID}/${this.teamIndex}`, ref => ref.orderByChild("timeStamp").endAt(this.lowestTimeStamp).limitToLast(10))
			.snapshotChanges()
			.subscribe(
				messages => {
					messages.forEach(message => {
						of(message).pipe(
							switchMap(
								(res, index) => {
									let data = res.payload.val();
									// 
									return combineLatest([
										of(res.payload),
										this.db.GetUserDocument(Object.keys(data)[0]).get(),
										this.db.FetchProfileImage(Object.keys(data)[0])]
									);
								}
							)
						).subscribe(
							data => {
								let message = String(Object.values(data[0].val())[0]);
								let messageID = data[0].key;

								this.lowestTimeStamp = data[0].val()["timeStamp"] < this.lowestTimeStamp ? data[0].val()["timeStamp"] : this.lowestTimeStamp;
								this.messageList.set(messageID, { message: message, username: data[1].data().userID, photo: data[2], timeStamp: data[0].val()["timeStamp"] })
							}, (err) => {

							}, () => { }
						)
					});

				}, (err) => {

				})
	}

}
