import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfilePageComponent } from './pages/profile-page/profile-page.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { EventsDetailPageComponent } from './pages/events-detail-page/events-detail-page.component';
import { RankingsPageComponent } from './pages/rankings-page/rankings-page.component';
import { LiveEventPageComponent } from './pages/live-event-page/live-event-page.component';
import { RoleGuardService } from './services/role-guard.service';
import { RulesComponent } from './pages/rules/rules.component';






const routes: Routes = [
	{ path: '', component: LandingPageComponent },
	{ path: 'aboutUs', component: LandingPageComponent, data: { scrollTo: 'aboutUs' } },
	{ path: 'contactUs', component: LandingPageComponent, data: { scrollTo: 'contactUs' } },
	{ path: 'rules', component: RulesComponent },
	{
		path: 'profile', component: ProfilePageComponent, canActivate: [RoleGuardService],
		data: {
			expectedRole: 'user'
		},
		loadChildren: './pages/profile-page/profile.module#ProfileModule'
	},
	{ path: 'events', component: EventsDetailPageComponent },
	{ path: 'rankings', component: RankingsPageComponent },
	{
		path: 'liveEvent', component: LiveEventPageComponent, canActivate: [RoleGuardService],
		data: {
			expectedRole: 'user'
		},
		loadChildren: './pages/live-event-page/live-event.module#LiveEventModule'
	},
	{ path: 'admin', loadChildren: './adminPages/admin/admin.module#AdminModule' }


];

@NgModule({
	imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' })],
	exports: [RouterModule]
})

export class AppRoutingModule {

	constructor() {

	}
}
