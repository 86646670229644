<div class="background">
    <div class="nodes">
        <app-nodes [ngStyle]="{'z-index': 0}"></app-nodes>
    </div>
    <div class="wrapper">

        <div mat-subheader class=" heading1">Player Rankings</div>

        <mat-list class="list " infinite-scroll [scrollWindow]="false" [infiniteScrollDistance]="scrollDistance"
            [infiniteScrollUpDistance]="scrollUpDistance" [infiniteScrollThrottle]="throttle"
            (scrolled)="onScrollDown()">
            <mat-list-item *ngFor="let user of userList; index as rank" routerLink="/profile" class="listItem"
                [queryParams]="{userID: user.key}">
                <div mat-list-icon class="example-header-image" [style.background-image]="user.value.image"></div>
                <div mat-line>
                    <div class="wide ">
                        <div class="username ">{{ user.value.data.userID }}</div>
                        <div>#{{ rank + 1}}</div>
                    </div>
                </div>
                <div mat-line class="subtitle ">XP:{{ user.value.data.xp }} </div>

            </mat-list-item>
            <div class="w-100 d-flex justify-content-center" *ngIf="loadingUsers">
                <mat-spinner color="warn"></mat-spinner>
            </div>
        </mat-list>

    </div>

</div>