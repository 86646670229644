import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DatabaseService } from 'src/app/services/database.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
	selector: 'app-events-page',
	templateUrl: './events-page.component.html',
	styleUrls: ['./events-page.component.scss']
})
export class EventsPageComponent implements OnInit {

	mainEvents: any[];

	images: string[] = []
	currentState = 0;
	@ViewChild('mainEvent') mainEvent: ElementRef;


	emptyEvent = {
		eventTitle: "Coming Soon",
		eventStartTime: Date.now().toString(),
		eventDescription: "A major event has not been selected yet for this space",
	}

	loading: Boolean = true

	public current: any;

	ScrollToMainEvent(state) {
		this.clickToggle(state)
		this.mainEvent.nativeElement.scrollIntoView({ behavior: 'smooth' });
	}

	ngOnInit(): void {

		this.db.GetMainEvents().subscribe(events => {
			this.mainEvents = [];

			events.forEach(event => {

				this.current = { key: event[0].id, data: event[0].data() }
				this.mainEvents.push({ key: event[0].id, data: event[0].data() });
				this.images.push(event[1])
			})

			let rem = 2 - this.mainEvents.length
			for (rem; rem >= 0; rem--) {
				this.mainEvents.push({ key: null, data: this.emptyEvent });
				this.images.push("../../../assets/images/grey.png")
			}
			this.loading = false;
		})
	}
	constructor(private db: DatabaseService, private sanitization: DomSanitizer, private router: Router) {

	}

	@ViewChild('backgroundLayer') backgroundLayer;

	sanitize(url: string) {
		return this.sanitization.bypassSecurityTrustStyle(`url('${url}')`);
	}

	sanitizeBG(url: string) {
		return this.sanitization.bypassSecurityTrustStyle(` url('${url}'), linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))`);
	}



	navigate(key) {

		this.router.navigate(["/events"], { queryParams: { eventID: key } })
	}

	toggle(state: number) {
		if (this.mainEvents && this.mainEvents.length - 1 >= state && !this.clickToggled) {
			if (this.mainEvents[state]) {

				this.current = this.mainEvents[state];
				this.currentState = state

			}
		}
	}
	clickToggled: boolean = false;
	clickToggle(state: number) {
		if (this.mainEvents && this.mainEvents.length - 1 >= state) {
			if (this.mainEvents[state]) {
				this.clickToggled = true;
				this.current = this.mainEvents[state];
				this.currentState = state

			}
		}
	}




}
