<div class="about-us-container" #aboutUs id='aboutUs'>
    <div class="cofLogo">
        <img class="image" src="../../../assets/images/Logo.png">
    </div>
    <div class="aboutContainer aboutFont">
        <h1 class="aboutFontHeading ">About Us</h1>
        <div class="padder">
            <p class="lead">A community of comrades who enjoy the sport of airsoft.</p>
            <hr class="my-4" style="background-color: white;">
            <p class="lead">View the events and register to start your COF Airsoft experience</p>
            <p class="lead">We also provide rental gear & host private games, parties and team building upon bookings.
            </p>
        </div>
    </div>
</div>