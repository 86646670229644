<mat-card class="messagesContainer">
    <mat-card-header class="p-2">
        <!-- <img mat-card-avatar *ngIf="eventImage | async as image" [src]="sanitize(image)"> -->
        <mat-card-subtitle class="text-muted" style="font-size: x-small;">
            *Only your team {{teamName}} will be able to see your messages
        </mat-card-subtitle>
    </mat-card-header>

    <div class="w-100 d-flex justify-content-center" *ngIf="(loadingMessages | async)">
        <mat-spinner></mat-spinner>
    </div>
    <div smoothScroll id='infinite' #infinite *ngIf="!(loadingMessages | async)" class="messageList" infinite-scroll
        [scrollWindow]="false" [infiniteScrollDistance]="scrollDistance" [infiniteScrollUpDistance]="scrollUpDistance"
        [infiniteScrollThrottle]="throttle" (scrolled)="onScrollDown()" (scrolledUp)="onScrollUp()">

        <mat-card class="message-card" *ngFor="let message of messageList |  keyvalue "
            [ngClass]="message.IsUserMessage ? 'IsUserMessage' : '' ">
            <mat-card-header>
                <img mat-card-avatar src="{{message.value.photo}}" alt="{{message.value.username}}"
                    class="profilePhoto">
                <mat-card-title style="font-size: small;">{{message.value.username}}</mat-card-title>

                <mat-card-subtitle class="time" style="font-size: x-small;">
                    {{getTimeFromDate(message.value.timeStamp)}}
                </mat-card-subtitle>


            </mat-card-header>
            <hr style="width: 90; background-color: black; color: black; margin-top: -8px;" />
            <mat-card-content>
                <p style="font-size: small; margin-top: -10px; max-width: 100%; word-break: break-all;">
                    {{message.value.message}}
                </p>
            </mat-card-content>
        </mat-card>
        <div #bottom id="bottom" class="m-3">
            End of messages...
        </div>
    </div>

    <mat-card-actions class=" p-1 " align="center">
        <form class="d-flex messageForm">
            <mat-form-field appearance="outline" class="w-100 m-1">
                <mat-label>Send a message</mat-label>
                <textarea style="height: 20px; " [formControl]=" chatFormControl" matInput></textarea>
            </mat-form-field>
            <div class="d-flex m-1" style="height: 100%; margin-top: 15px !important;">
                <button (click)="sendMessage()" color="primary" mat-mini-fab>
                    <mat-icon>send</mat-icon>
                </button>
            </div>
        </form>
    </mat-card-actions>
</mat-card>