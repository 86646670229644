<div mat-dialog-content>
	<mat-card>
		<mat-card-title>Team Selection</mat-card-title>
		<mat-card-subtitle class="text-danger"> *Warning</mat-card-subtitle>
		<div class="p-1 ">
			<p class="lead bold border-1 " style="line-height: 25px;"> Choose A Team!
			</p>
			<strong> this team selection can be changed later</strong>

		</div>
		<mat-spinner *ngIf="LoadingTeams"></mat-spinner>

		<mat-card-actions class="d-flex flex-column justify-content-center"
			*ngIf="!LoadingTeams && injected.loadedEvent.userRegistedPrior">
			<button *ngFor="let team of injected.loadedEvent.eventData.eventTeams; index as i" class="m-1"
				mat-raised-button [disabled]="TeamIsFull(i)" style="margin-right: 5px;" (click)="SwitchUserTeam(i)">
				{{ TeamIsFull(i) ?
				team + ' Is Full' : team}}</button>

		</mat-card-actions>
		<mat-card-actions class="d-flex flex-column justify-content-center"
			*ngIf="!LoadingTeams && !injected.loadedEvent.userRegistedPrior">
			<button *ngFor="let team of injected.loadedEvent.eventData.eventTeams; index as i" class="m-1"
				mat-raised-button [disabled]="TeamIsFull(i)" style="margin-right: 5px;" (click)="RegisterForEvent(i)">
				{{ TeamIsFull(i) ?
				team + ' Is Full' : team}}</button>

		</mat-card-actions>

	</mat-card>


</div>