
import { Component, OnDestroy, OnInit, EventEmitter } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, interval, Observable, of, Subscription } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { DatabaseService, eventData } from 'src/app/services/database.service';
import { SnippetService } from 'src/app/services/snippet.service';
import { GpsRegistrationSheet } from './participation/liveEventGpsRegistration.component';
import { QrScanner } from './participation/QrScanner.component';


export interface Tile {
	color: string;
	cols: number;
	rows: number;
	text: string;
}




interface Timer {
	Hours: number;
	Minutes: number;
	Seconds: number;
}

@Component({
	selector: 'app-live-event-page',
	templateUrl: './live-event-page.component.html',
	styleUrls: ['./live-event-page.component.scss']
})
export class LiveEventPageComponent implements OnInit, OnDestroy {

	event: eventData;
	eventID: String;
	userID: String;
	eventImage: Observable<String | null>
	participantList: Map<String, any>;
	participationStatus: Observable<any | null>;
	selectedTeam: Number;
	teamIndexArray: Number[];
	userTeam: number = -1;
	eventParticipantsSub: Subscription;
	participantSub: Subscription;


	constructor(
		private route: ActivatedRoute,
		private db: DatabaseService,
		private auth: AuthService,
		private router: Router,
		private sanitization: DomSanitizer,
		private snippet: SnippetService,
		private gpsRegistration: MatBottomSheet,
		public dialog: MatDialog
	) { }







	sanitize(url: String) {
		return this.sanitization.bypassSecurityTrustResourceUrl(url.toString());
	}


	ConvertObject(obj: any) {
		return JSON.stringify(obj);
	}

	userIsRegistered(eventInfo) {
		if (!eventInfo[0].payload.exists || eventInfo[0].payload.data().status != 2) {
			this.router.navigate(['/events']);
		}
	}

	ngOnInit() {

		this.selectedTeam = this.userTeam;
		combineLatest([this.auth.afAuth.user, this.route.queryParams])
			.pipe(
				switchMap((data) => {
					this.userID = data[0].uid;
					this.eventID = data[1].eventID;
					return combineLatest([
						this.db.GetUserRegistrationStatus(data[1].eventID, data[0].uid).pipe(take(1)),
						this.db.GetEvent(data[1].eventID)
					])
				})
			).subscribe(
				eventInfo => {

					this.userIsRegistered(eventInfo);

					//LOAD TEAM DATA
					this.teamIndexArray = [];
					this.userTeam = Number(eventInfo[0].payload.data().team);
					this.selectedTeam = this.userTeam;

					let index = 0;
					eventInfo[1].data().eventTeams.forEach(team => {
						this.teamIndexArray.push(index++);
					});

					this.event = eventInfo[1].data()
					this.eventImage = this.db.FetchEventImage(this.eventID)
					this.participationStatus = this.WatchUserParticipantStatus()

					this.RestartTimer();
					this.WatchEventParticipants();

					const epochNow = (new Date).getTime() / 1000;
					if ((Number(this.event.eventStartTime.seconds) - Number(epochNow)) <= 0 && Number(this.event.eventEndTime.seconds) + this.bonusClaimTime - Number(epochNow) >= 0) {
						//watch to open dialog on claim lobby open

						setTimeout(() => {
							this.participationStatus.subscribe(status => {
								if (!status.participant) {
									this.openGPSRegistrationSheet('register')
								} else if (status.participant && !status.data.bonusXPClaimed && this.EventHasRewards(this.event) && Number(this.event.eventEndTime.seconds) < Number(epochNow)) {
									console.log(`${Number(this.event.eventEndTime.seconds)}, ${Number(epochNow)}`)
									this.openGPSRegistrationSheet('bonusXP')
								}
							})
						}, 2000);


					}
				}
			)


	}

	BonusXP(success: EventEmitter<boolean>) {
		success.subscribe((data) => {
			if (data)
				this.snippet.openSuccessSnackBar("Claimed bonus rewards!")
			else {
				this.snippet.openErrorSnackBar("Failed to claim bonus XP");
			}
		})
	}

	registration(success: EventEmitter<boolean>) {
		success.subscribe((valid) => {
			if (valid) {
				this.snippet.openSuccessSnackBar("Welcome to the event soldier");
				// this.WatchEventParticipants();
			}
		})
	}


	WatchUserParticipantStatus() {
		return this.db.WatchUserParticipantStatus(this.userID, this.eventID)
			.pipe(
				map(data => {
					if (!data.payload.exists || data.type == 'removed') {
						return { participant: false };
					}
					else if (data.type == 'added' || data.type == 'modified') {
						return { participant: true, data: data.payload.data() };
					}
				})
			)


	}

	EventHasRewards(eventData: any): boolean {
		return (eventData.bonusXP > 0 || eventData.badges.length > 0 || eventData.patches.length > 0)
	}




	tickDelay: Observable<Number> = interval(1000);




	bonusEndTimer: Timer = { Hours: 0, Minutes: 0, Seconds: 0 };
	bonusTimer: Timer = { Hours: 0, Minutes: 0, Seconds: 0 };
	eventTimer: Timer = { Hours: 0, Minutes: 0, Seconds: 0 };

	secondsTillBonus: Number = 5000;
	totalSeconds: number;
	totalEndSeconds: number;
	secondsTillEnd: number;
	bonusClaimTime: number = 3 * 3600;

	RestartTimer() {
		this.tickDelay.subscribe(time => {
			const epochNow = (new Date).getTime() / 1000;

			this.secondsTillEnd = Number(this.event.eventEndTime.seconds) - Number(epochNow)
			this.secondsTillBonus = Number((this.secondsTillEnd).toFixed(0));
			this.secondsTillEnd += this.bonusClaimTime;

			this.bonusEndTimer = this.GetTimer(this.secondsTillEnd, this.bonusEndTimer)
			this.bonusTimer = this.GetTimer(this.secondsTillBonus, this.bonusTimer)
			this.eventTimer = this.GetTimer(this.totalSeconds, this.eventTimer)

			this.totalSeconds = (Number(this.event.eventStartTime.seconds) - Number(epochNow));

		})
	}

	GetTimer(timeIn, timer) {
		timeIn = Number(timeIn).toFixed(0)
		let hours, minutes, seconds = 0;
		hours = Math.floor(timeIn / 3600);
		timeIn %= 3600;
		minutes = Math.floor(timeIn / 60);
		seconds = timeIn % 60; timeIn
		if (hours + minutes + seconds < 0) {
			hours = 0;
			minutes = 0;
			seconds = 0;
		}
		let h = (hours <= 9) ? "0".concat(String(hours)) : String(hours);
		let m = (minutes <= 9) ? "0".concat(String(minutes)) : String(minutes);
		let s = (seconds <= 9) ? "0".concat(String(seconds)) : String(seconds);
		timer.Hours = h;
		timer.Minutes = m;
		timer.Seconds = s;
		return timer
	}

	openScanQRDialog(registerMode: string): void {

		this.gpsRegistration.open(QrScanner,
			{
				data: {
					eventTitle: this.event.eventTitle,
					mode: registerMode,
					bonusButtonDisabled: this.event.bonusButtonDisabled,
					eventID: this.eventID,
					team: this.userTeam
				}
			}
		)

	}

	openGPSRegistrationSheet(registerMode: string) {

		this.gpsRegistration.open(GpsRegistrationSheet,
			{
				data: {
					mode: registerMode,
					bonusButtonDisabled: this.event.bonusButtonDisabled,
					eventID: this.eventID,
					team: this.userTeam,
					eventLocation: [this.event.eventPosition.latitude, this.event.eventPosition.longitude],
					eventTitle: this.event.eventTitle
				}
			}
		)


	}



	WatchEventParticipants() {

		this.eventParticipantsSub = this.db.WatchEventParticipants(this.eventID).subscribe(document => {
			document.forEach((userRow) => {

				of(userRow).pipe(
					switchMap((userRow, index) => {

						return combineLatest([
							of(userRow),
							this.db.FetchUser(userRow.payload.doc.id),
							this.db.FetchProfileImage(userRow.payload.doc.id)
						])
					})
				).subscribe(
					data => {

						if (this.participantList == null)
							this.participantList = new Map<String, any>()

						if (data[0].type == 'added' || data[0].type == 'modified')
							this.participantList.set(data[1].id, { user: data[1].data(), photo: data[2], team: data[0].payload.doc.data().team });
						if (data[0].type == 'removed')
							this.participantList.delete(data[1].id);

					}
				);
			})
		})




	}

	ngOnDestroy() {
		if (this.eventParticipantsSub)
			this.eventParticipantsSub.unsubscribe();
		if (this.participantSub)
			this.participantSub.unsubscribe()

	}

}
