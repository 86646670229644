import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { NgcCookieConsentService, NgcInitializeEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { StateServiceService } from './services/state-service.service';
import { UpdateServiceService } from './services/update-service.service';
import firebase from 'firebase/app'
import 'firebase/storage'
import 'firebase/analytics'

// declare google analytics
declare const gtag: any;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnDestroy {
	title = 'CofAirsoft';
	localStorageDisabled = false;
	@ViewChild('router', { static: false }) router: ElementRef;
	clickedElement: Subscription = new Subscription();
	collapsed: EventEmitter<boolean> = new EventEmitter();

	//keep refs to subscriptions to be able to unsubscribe later
	private popupOpenSubscription: Subscription;
	private popupCloseSubscription: Subscription;
	private initializeSubscription: Subscription;
	private statusChangeSubscription: Subscription;
	private revokeChoiceSubscription: Subscription;
	private noCookieLawSubscription: Subscription;




	//inject state service at the top of the tree
	constructor(private UpdatesSW: UpdateServiceService, private stateService: StateServiceService, private ccService: NgcCookieConsentService) {

	}

	ngOnInit(): void {
		this.lsTest()
		this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
			// you can use this.ccService.getConfig() to do stuff...
			firebase.analytics().setAnalyticsCollectionEnabled(this.ccService.hasConsented());
			gtag('consent', this.ccService.hasConsented() ? 'granted' : 'denied', {
				'ad_storage': this.ccService.hasConsented() ? 'granted' : 'denied'
			});
		});

		this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
			// you can use this.ccService.getConfig() to do stuff...
			firebase.analytics().setAnalyticsCollectionEnabled(this.ccService.hasConsented());
			gtag('consent', this.ccService.hasConsented() ? 'granted' : 'denied', {
				'ad_storage': this.ccService.hasConsented() ? 'granted' : 'denied'
			});
		});

		this.initializeSubscription = this.ccService.initialize$.subscribe(
			(event: NgcInitializeEvent) => {
				// you can use this.ccService.getConfig() to do stuff...
				firebase.analytics().setAnalyticsCollectionEnabled(this.ccService.hasConsented());
				gtag('consent', this.ccService.hasConsented() ? 'granted' : 'denied', {
					'ad_storage': this.ccService.hasConsented() ? 'granted' : 'denied'
				});
			}
		);

		this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
			(event: NgcStatusChangeEvent) => {
				// you can use this.ccService.getConfig() to do stuff...
				firebase.analytics().setAnalyticsCollectionEnabled(this.ccService.hasConsented());
				gtag('consent', this.ccService.hasConsented() ? 'granted' : 'denied', {
					'ad_storage': this.ccService.hasConsented() ? 'granted' : 'denied'
				});
			}
		);

		this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
			() => {
				// you can use this.ccService.getConfig() to do stuff...
				firebase.analytics().setAnalyticsCollectionEnabled(this.ccService.hasConsented());
				gtag('consent', this.ccService.hasConsented() ? 'granted' : 'denied', {
					'ad_storage': this.ccService.hasConsented() ? 'granted' : 'denied'
				});
			}
		);

		this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
			(event: NgcNoCookieLawEvent) => {
				// you can use this.ccService.getConfig() to do stuff...
				firebase.analytics().setAnalyticsCollectionEnabled(this.ccService.hasConsented());
				gtag('consent', this.ccService.hasConsented() ? 'granted' : 'denied', {
					'ad_storage': this.ccService.hasConsented() ? 'granted' : 'denied'
				});
			}
		);

	}

	ngAfterViewInit() {
		this.ccService.getStatus()
		firebase.analytics().setAnalyticsCollectionEnabled(this.ccService.hasConsented());

		gtag('consent', this.ccService.hasConsented() ? 'granted' : 'denied', {
			'ad_storage': this.ccService.hasConsented() ? 'granted' : 'denied'
		});
	}

	lsTest() {
		var test = 'test';
		try {
			localStorage.setItem(test, test);
			localStorage.removeItem(test);
			this.localStorageDisabled = false;
		} catch (e) {
			console.error(e)
			this.localStorageDisabled = true;

		}
	}



	ngOnDestroy() {

		this.popupOpenSubscription.unsubscribe();
		this.popupCloseSubscription.unsubscribe();
		this.initializeSubscription.unsubscribe();
		this.statusChangeSubscription.unsubscribe();
		this.revokeChoiceSubscription.unsubscribe();
		this.noCookieLawSubscription.unsubscribe();

	}

	eventCookie(event) {

	}
}
