import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { combineLatest, forkJoin, from, Observable, of, throwError } from 'rxjs';
import { catchError, finalize, first, map, pluck, retry, switchMap, take, tap } from 'rxjs/operators';
import firestore from 'firebase/app';
import { AngularFireDatabase } from '@angular/fire/database';
import { HttpClient } from '@angular/common/http';
import { P } from '@angular/cdk/keycodes';

export enum REGISTRATION_STATUS {
	NotAttending,
	Interested,
	Attending,
	InQueue,
}

export enum LogType {
	XP,
	Badge,
	Patch,
	Participant,
	Registration,
	BonusRewards,
	RedeemXP,
	Permissions,
	none,
}
export enum ReasonIdType {
	User,
	Event,
	RedeemXP,
}
export interface LogData {
	logMessage: string;
	logType: LogType;
	reasonID: string;
	reasonIdType: ReasonIdType;
	timeStamp: number;
}

interface badgeData {
	badgeDescription: string;
	badgeName: string;
	createdBy: string;
	timestamp: number;
}

interface patchData {
	patchDescription: string;
	patchName: string;
	createdBy: string;
	timestamp: number;
}

interface patchRequestData {
	UserID: string;
	patchID: string;
}
export interface eventData {
	bookingCutoffTime: firestore.firestore.Timestamp;
	eventTitle: string;
	bonusButtonDisabled: boolean;
	eventDescription: string;
	eventEndTime: firestore.firestore.Timestamp;
	eventStartTime: firestore.firestore.Timestamp;
	eventPosition: firestore.firestore.GeoPoint;
	maxParticipants: number;
	eventTeams: [];
	eventTeamsMaxPlayers: [];
	badges: [];
	patches: [];
	xp: string;
	bonusXP: number;
	toggleBonusXP: boolean;
	minimumXP: number;
	eventBookable: boolean;
	rentals: Array<any>;
	minEventBookingXP: number;
	bookingStartTime: firestore.firestore.Timestamp;
}

interface ranksData {
	ranks: {};
}

interface locationData {
	locationName: string;
	position: [];
}

interface participantData {
	bonusXPClaimed: boolean;
	team: number;
}

export interface registrationData {
	callsign: string;
	xp: number;
	status: REGISTRATION_STATUS;
	team: number;
	timestamp: number;
}

interface logData {
	logMessage: string;
	logType: LogType;
	reasonID: string;
	reasonIdType: ReasonIdType;
	timeStamp: number;
}

export interface userData {
	admin: boolean;
	fullname: string;
	marshal: boolean;
	matchesPlayed: number;
	patches: { [key: string]: number };
	badges: { [key: string]: number };
	phoneNumber: string;
	serviceTime: firestore.firestore.Timestamp;
	social: string;
	userID: string;
	xp: number;
}

interface redeemXPData {
	redeemEmail: string;
	xp: number;
}

function AutoUnsub() {
	return function (constructor) {
		const orig = constructor.prototype.ngOnDestroy;
		constructor.prototype.ngOnDestroy = function () {
			for (const prop in this) {
				const property = this[prop];
				if (typeof property.subscribe === 'function') {
					property.unsubscribe();
				}
			}
			orig.apply();
		};
	};
}

@Injectable({
	providedIn: 'root',
})
export class DatabaseService {
	uploadPercent: Observable<number>;
	profileUpload: Observable<string>;
	eventUpload: Observable<string>;

	constructor(
		private readonly afs: AngularFirestore,
		private storage: AngularFireStorage,
		private realTimeDB: AngularFireDatabase,
		private httpClient: HttpClient
	) {}

	RedeemXPError(email: string) {
		this.afs
			.collection('RedeemXpError')
			.add({ email: email })
			.then()
			.catch((err) => {
				console.error(err);
			});
	}

	SetUserTeam(uid: string, teamName: string): Promise<any> {
		return this.afs.collection('Users').doc(uid).update({ team: teamName });
	}

	RedeemXP(userID: string, redeemID: string) {
		return this.afs
			.collection<redeemXPData>('RedeemXP', (ref) => ref.where('redeemEmail', '==', redeemID))
			.get()
			.pipe(first())
			.subscribe((ret) => {
				if (!ret.empty) {
					let data = ret.docs[0];
					let batch = this.afs.firestore.batch();

					batch.delete(data.ref);
					batch.update(this.afs.collection('Users').doc<userData>(userID).ref, {
						xp: firestore.firestore.FieldValue.increment(Number(data.data().xp)),
					});
					this.log(LogType.RedeemXP, `Redeemed ${data.data().xp}`, ReasonIdType.RedeemXP, userID, userID, batch);
					batch
						.commit()
						.then()
						.catch((err) => {
							this.log(LogType.RedeemXP, `An error occured redeeming previous xp`, ReasonIdType.RedeemXP, userID, userID, batch);
							console.error(err);
						});
				}
			});
	}

	LoadRuleBook() {
		return this.afs.collection('Rules').doc('RuleBook');
	}

	togglePermissions(userId: string, mode: string, value: boolean, reasonID: string) {
		let batch = this.afs.firestore.batch();
		if (mode == 'admin') {
			batch.update(this.afs.collection('Users').doc(userId).ref, {
				admin: value,
			});
			this.log(LogType.Permissions, `Admin permissions set to ${value}`, ReasonIdType.User, userId, reasonID, batch);
		} else if (mode == 'marshal') {
			batch.update(this.afs.collection('Users').doc(userId).ref, {
				marshal: value,
			});
			this.log(LogType.Permissions, `Marshal permissions set to ${value}`, ReasonIdType.User, userId, reasonID, batch);
		}
		return batch.commit();
	}

	AddUserXP(userID: string, amount: number, reasonID: string) {
		let batch = this.afs.firestore.batch();
		this.log(LogType.XP, `Awarded ${amount} Xp to player`, ReasonIdType.User, userID, reasonID, batch);
		batch.update(this.afs.collection('Users').doc<userData>(userID).ref, {
			xp: firestore.firestore.FieldValue.increment(amount),
		});
		return batch.commit();
	}

	RemoveUserXP(userID: string, amount: number, reasonID: string) {
		let batch = this.afs.firestore.batch();
		this.log(LogType.XP, `Removed ${amount} Xp from player`, ReasonIdType.User, userID, reasonID, batch);
		batch.update(this.afs.collection('Users').doc(userID).ref, {
			xp: firestore.firestore.FieldValue.increment(-1 * amount),
		});
		return batch.commit();
	}

	isAdmin(userID: string, role: string): Observable<boolean> {
		return this.afs
			.collection('Users')
			.doc<userData>(userID)
			.get()
			.pipe(
				map((user) => {
					return user.data().admin || (role == 'marshal' && user.data().marshal);
				})
			);
	}

	GetAdminType(userID: string): Observable<string> {
		return this.afs
			.collection('Users')
			.doc<userData>(userID)
			.get()
			.pipe(
				map((user) => {
					return user.data().admin ? 'admin' : user.data().marshal ? 'marshal' : '';
				})
			);
	}

	GetEventBonusXP(eventID: string) {
		return this.afs
			.collection('Event')
			.doc(eventID)
			.snapshotChanges()
			.pipe(
				map((data) => {
					return data.payload.data();
				}),
				pluck('toggleBonusXP')
			);
	}

	GetMainEvents() {
		return this.afs
			.collection('Event', (ref) => ref.orderBy('eventStartTime', 'desc'))
			.get()
			.pipe(
				map((data) => {
					return data.docs.slice(0, 3).map((event) => {
						return combineLatest([this.GetEvent(event.id), this.FetchEventImage(event.id)]);
					});
				}),
				switchMap((event) => {
					return forkJoin(event);
				})
			);
	}

	ToggleMainEvents(obj: any) {
		return this.afs.collection('MainEvents').doc('events').update(obj);
	}

	uploadFile(file, uid: String) {
		const filePath = 'profileImages/' + uid;
		const fileRef = this.storage.ref(filePath);
		const task = this.storage.upload(filePath, file);

		// get notified when the download URL is available
		task
			.snapshotChanges()
			.pipe(finalize(() => (this.profileUpload = fileRef.getDownloadURL())))
			.subscribe();
		return task;
	}

	uploadTeamImage(file, uid: String) {
		const filePath = 'teamImages/' + uid;
		const fileRef = this.storage.ref(filePath);
		const task = this.storage.upload(filePath, file);

		// get notified when the download URL is available
		task
			.snapshotChanges()
			.pipe(finalize(() => (this.profileUpload = fileRef.getDownloadURL())))
			.subscribe();

		return task;
	}

	uploadPatchRequestImage(file, uid: string, patchID: string) {
		let id: string = this.afs.createId();
		const data = { UserID: uid, patchID: patchID };
		return this.afs
			.collection('PatchRequests')
			.doc(id)
			.set(data)
			.then(() => {
				const filePath = 'patchRequestImages/' + id;
				const fileRef = this.storage.ref(filePath);
				const task = this.storage.upload(filePath, file);

				return task;
			});
	}

	uploadEventImage(file, uid: String) {
		const filePath = 'eventImages/' + uid;
		const fileRef = this.storage.ref(filePath);
		const task = this.storage.upload(filePath, file);

		// observe percentage changes
		this.uploadPercent = task.percentageChanges();
		// get notified when the download URL is available
		return task;
	}

	GetEventInterested(eventID: String) {
		return this.afs
			.collection('Registration')
			.doc(`${eventID}`)
			.collection('EventRegistration', (ref) => ref.where('status', '==', 1))
			.valueChanges();
	}

	GetEventNotAttending(eventID: String) {
		return this.afs
			.collection('Registration')
			.doc(`${eventID}`)
			.collection('EventRegistration', (ref) => ref.where('status', '==', 0))
			.valueChanges();
	}

	ToggleXP(eventID: string, xp: boolean) {
		return this.afs.collection('Event').doc(eventID).update({ toggleBonusXP: xp });
	}

	GetUserDocument(uid: String) {
		return this.afs.collection<userData>('Users').doc(`${uid}`);
	}

	GetParticipantDetails(uid: String) {
		return this.afs.collection<userData>('Users').doc(`${uid}`).get();
	}

	CreateNewUserDocument(obj: any, userID: String) {
		return this.afs.collection<userData>('Users').doc(`${userID}`).set(obj);
	}

	UpdateUserDocument(obj: any, user: any): Observable<Promise<any>> {
		console.log('UPDATING DOC');
		if (obj.userID) {
			console.log(obj.userID);
			return this.afs
				.collection('UserRegistrations')
				.doc(user.user.id)
				.get()
				.pipe(
					map((registrationData) => {
						console.log(registrationData);
						let batch = this.afs.firestore.batch();
						[...Object.values(registrationData.data())].forEach((registration) => {
							let eventID = registration.path.split('/')[1];
							batch.update(this.afs.collection('Registration').doc(eventID).collection('EventRegistration').doc(user.user.id).ref, {
								callsign: obj.userID,
							});
						});
						batch.update(this.afs.collection('Users').doc(`${user.user.id}`).ref, obj);
						return batch.commit();
					})
				);
		} else {
			return of(this.afs.collection('Users').doc(`${user.user.id}`).update(obj));
		}
	}

	CheckIfUserExists(username: String) {
		return this.afs.collection('Users', (ref) => ref.where('userID', '==', `${username}`)).get();
	}

	CheckIfBadgeNameExists(badgeName: string) {
		return this.afs.collection('Badges', (ref) => ref.where('badgeName', '==', badgeName)).get();
	}

	CheckIfPatchNameExists(badgeName: string) {
		return this.afs.collection('Patches', (ref) => ref.where('patchName', '==', badgeName)).get();
	}

	KickUsersFromEvent(eventID: string, usersToKick: string[], reasonID: string) {
		return new Observable((sub) => {
			// Once we get the results, begin a batch
			let batch = this.afs.firestore.batch();
			usersToKick.forEach((userID) => {
				//check if they are Participants too
				let ref = this.afs.collection('Participant').doc(eventID).collection('Lobby').doc(userID).ref;
				if (ref) {
					this.log(LogType.Participant, `Participation status deleted`, ReasonIdType.User, userID, reasonID, batch);
					batch.delete(ref);
				}
				this.log(LogType.Registration, `Registration status deleted`, ReasonIdType.User, userID, reasonID, batch);
				batch.delete(this.afs.collection('Registration').doc(eventID).collection('EventRegistration').doc(userID).ref);
			});
			batch
				.commit()
				.then(() => {
					sub.next(true);
				})
				.catch((err) => sub.next(err));
		});
	}

	FetchProfileImage(uid: String): Observable<any> {
		return this.storage
			.ref('profileImages/' + uid)
			.getDownloadURL()
			.pipe(
				map((image) => {
					return image;
				}),
				catchError((err) => this.storage.ref('profileImages/defaultPP.png').getDownloadURL())
			);
	}

	FetchTeamImage(uid: String): Observable<any> {
		return this.storage
			.ref('teamImages/' + uid)
			.getDownloadURL()
			.pipe(
				map((image) => {
					return image;
				}),
				catchError((err) => this.storage.ref('teamImages/noTeam.png').getDownloadURL())
			);
	}

	FetchEventImage(eventUID: String): Observable<any> {
		return this.storage.ref('eventImages/' + eventUID).getDownloadURL();
	}

	UpdateEvent(obj: any, eventID: string) {
		return this.afs.collection('Event').doc(eventID).update(obj);
	}

	SwitchUsersTeams(eventID: string, usersToMove: string[], teamIndex: Number, reasonID, eventName: string): Observable<void> {
		let batch = this.afs.firestore.batch();
		return this.afs
			.collection('Participant')
			.doc(`${eventID}`)
			.collection('Lobby')
			.get()
			.pipe(
				map((users) => {
					let participants = new Map<string, boolean>();
					users.docs.map((doc) => {
						participants.set(doc.id, true);
					});
					usersToMove.forEach((userID) => {
						//if the user is already participating in the game switch the team in Participant table too
						if (participants.get(userID)) {
							this.log(
								LogType.Participant,
								`Participation team switched to team ${teamIndex} for event ${eventName}`,
								ReasonIdType.User,
								userID,
								reasonID,
								batch
							);
							batch.update(this.afs.collection('Participant').doc(eventID).collection('Lobby').doc(userID).ref, { team: teamIndex });
						}
						this.log(
							LogType.Participant,
							`Registration team switched to team ${teamIndex} for event ${eventName}`,
							ReasonIdType.User,
							userID,
							reasonID,
							batch
						);
						batch.update(this.afs.collection('Registration').doc(eventID).collection('EventRegistration').doc(userID).ref, { team: teamIndex });
					});

					return batch;
				}),
				switchMap((batch) => {
					return batch.commit();
				})
			);
	}

	// fetch all event documents and order by start date
	GetAllEventDocuments(docIndex): Observable<any> {
		if (docIndex)
			return this.afs
				.collection<Event>('Event', (ref) => ref.orderBy('eventStartTime', 'asc').startAfter(docIndex).limit(16))
				.get();
		return this.afs
			.collection<Event>('Event', (ref) => ref.orderBy('eventStartTime', 'asc').limit(16))
			.get();
	}

	// fetch all event documents and order by start date
	GetAllEventDocumentsDateFilter(filter, docIndex): Observable<any> {
		if (docIndex)
			return this.afs
				.collection<Event>('Event', (ref) => ref.orderBy('eventStartTime', filter).endAt(docIndex).limit(10))
				.get();
		return this.afs
			.collection<Event>('Event', (ref) => ref.orderBy('eventStartTime', filter).limit(10))
			.get();
	}

	// fetch all event documents and order by start date
	GetAllEventFilterExpired(expired: Boolean, docIndex): Observable<any> {
		if (expired) {
			if (docIndex)
				return this.afs
					.collection<Event>('Event', (ref) =>
						ref
							.where('eventEndTime', '<=', new firestore.firestore.Timestamp(Date.now() / 1000 - 10800, 0))
							.orderBy('eventEndTime', 'desc')
							.startAfter(docIndex)
							.limit(20)
					)
					.get();
			return this.afs
				.collection<Event>('Event', (ref) =>
					ref
						.where('eventEndTime', '<=', new firestore.firestore.Timestamp(Date.now() / 1000 - 10800, 0))
						.orderBy('eventEndTime', 'desc')
						.limit(20)
				)
				.get();
		}
		if (docIndex)
			return this.afs
				.collection<Event>('Event', (ref) =>
					ref
						.where('eventEndTime', '>=', new firestore.firestore.Timestamp(Date.now() / 1000 - 10800, 0))
						.startAfter(docIndex)
						.limit(20)
				)
				.get();
		return this.afs
			.collection<Event>('Event', (ref) => ref.where('eventEndTime', '>=', new firestore.firestore.Timestamp(Date.now() / 1000 - 10800, 0)).limit(20))
			.get();
	}

	// fetch all event documents and order by start date
	FindEventDocuments(eventTitle: string): Observable<any> {
		return this.afs
			.collection<Event>('Event', (ref) =>
				ref
					.orderBy('eventTitle')
					.startAt(eventTitle)
					.endAt(eventTitle + '\uf8ff')
					.limit(10)
			)
			.get();
	}

	FinduserDocuments(userName: string): Observable<any> {
		return this.afs
			.collection<Event>('Users', (ref) =>
				ref
					.orderBy('userID')
					.startAt(userName)
					.endAt(userName + '\uf8ff')
					.limit(10)
			)
			.get();
	}

	FinduserDocumentsByFullname(userName: string): Observable<any> {
		return this.afs
			.collection<Event>('Users', (ref) =>
				ref
					.orderBy('fullname')
					.startAt(userName)
					.endAt(userName + '\uf8ff')
					.limit(15)
			)
			.get();
	}

	UpdateBadge(obj: any, badgeID: string) {
		return this.afs.collection('Badges').doc(badgeID).update(obj);
	}

	UpdatePatch(obj: any, patchID: string) {
		return this.afs.collection('Patches').doc(patchID).update(obj);
	}

	UploadBadgeImage(data: any, badgeID: string) {
		return this.storage.upload('badgeImages/' + badgeID, data);
	}

	UploadPatchImage(data: any, badgeID: string) {
		return this.storage.upload('patchImages/' + badgeID, data);
	}

	// fetch all Badges by badge Name
	FindBadgeDocuments(badgeName: string): Observable<any> {
		return this.afs
			.collection<Event>('Badges', (ref) =>
				ref
					.orderBy('badgeName')
					.startAt(badgeName)
					.endAt(badgeName + '\uf8ff')
					.limit(10)
			)
			.get();
	}

	// fetch all Badges by Patch Name
	FindPatchDocuments(patchName: string): Observable<any> {
		return this.afs
			.collection<Event>('Patches', (ref) =>
				ref
					.orderBy('patchName')
					.startAt(patchName)
					.endAt(patchName + '\uf8ff')
					.limit(10)
			)
			.get();
	}

	GetEventRegisteredParticipants(eventUid: String) {
		return this.afs
			.collection('Registration')
			.doc<registrationData>(`${eventUid}`)
			.collection('EventRegistration', (ref) => ref.where('status', '==', REGISTRATION_STATUS.Attending).orderBy('timestamp', 'desc'))
			.stateChanges(['added', 'removed']);
	}

	DeleteUserTeam(uid: string) {
		return this.storage
			.ref('teamImages/' + uid)
			.delete()
			.pipe(
				map(() => {
					return this.afs.collection('Users').doc(uid).update({ team: firestore.firestore.FieldValue.delete() });
				})
			);
	}

	DeleteUserBadge(userID: string, badgeID: string, badgeName: string, reasonID: string) {
		return this.afs
			.collection('Users')
			.doc<userData>(userID)
			.get()
			.pipe(
				map((user) => {
					let badges = user.data().badges;
					let badgeCount = badges[badgeID];
					let batch = this.afs.firestore.batch();

					this.log(LogType.Badge, `Achievement ${badgeName} Deleted, ${badgeCount - 1} remaining`, ReasonIdType.User, userID, reasonID, batch);
					if (badgeCount > 1) {
						badges[badgeID] = badgeCount - 1;
						batch.update(user.ref, { badges: badges });
						return batch.commit();
					}
					delete badges[badgeID];
					batch.update(user.ref, { badges: badges });
					return batch.commit();
				})
			);
	}

	DeleteUserPatch(userID: string, patchID: string, patchName: string, reasonID: string) {
		return this.afs
			.collection('Users')
			.doc<userData>(userID)
			.get()
			.pipe(
				map((user) => {
					let patches = user.data().patches;
					let patchCount = patches[patchID];
					let batch = this.afs.firestore.batch();

					this.log(LogType.Patch, `Patch ${patchName} Deleted, ${patchCount - 1} remaining`, ReasonIdType.User, userID, reasonID, batch);

					if (patchCount > 1) {
						patches[patchID] = patchCount - 1;
						batch.update(user.ref, { patches: patches });
						return batch.commit();
					}
					delete patches[patchID];
					batch.update(user.ref, { patches: patches });
					return batch.commit();
				})
			);
	}

	Deleteuser(userID: string) {
		return new Observable((sub) => {
			this.afs
				.collection('Event')
				.get()
				.pipe(
					map((data) => {
						return data.docs.map((doc) => {
							return combineLatest([
								this.afs.collection('Registration').doc(doc.id).collection('EventRegistration').doc(userID).get(),
								this.afs.collection('Participant').doc(doc.id).collection('Lobby').doc(userID).get(),
							]);
						});
					}),
					switchMap((data) => {
						return forkJoin(data);
					})
				)
				.subscribe(
					(data) => {
						let batch = this.afs.firestore.batch();
						data.forEach((pair) => {
							// delete from Registration
							if (pair[0].exists) batch.delete(pair[0].ref);
							//delete from Participants
							if (pair[1].exists) batch.delete(pair[1].ref);
						});

						batch.delete(this.afs.collection('Users').doc(userID).ref);
						batch
							.commit()
							.then(
								() => {
									//delete image
									try {
										this.storage
											.ref('profileImages/' + userID)
											.delete()
											.subscribe(() => {
												sub.next(true);
											});
									} catch (err) {
										//users without profile pictures will break spinner in UI here
										sub.next(true);
									}
								},
								(err) => {
									throw err;
								}
							)
							.catch((err) => {
								throw err;
							});
					},
					(err) => sub.error(err)
				);
		});
	}

	DeleteBadge(badgeID: string) {
		return combineLatest([
			this.afs.collection('Badges').doc<badgeData>(badgeID).get(),
			this.afs.firestore
				.collection('Users')
				.where('badges.' + badgeID, '>=', 1)
				.get(),
			this.afs.firestore.collection('Event').where('badges', 'array-contains', badgeID).get(),
		]).pipe(
			map((data) => {
				let batch = this.afs.firestore.batch();
				let badgeRef = data[0];
				let usersWithBadge = data[1];
				let eventsWithBadge = data[2];

				if (!usersWithBadge.empty) {
					usersWithBadge.docs.forEach((user) => {
						let obj = {};
						obj['badges.' + badgeID] = firestore.firestore.FieldValue.delete();
						batch.update(user.ref, obj);
					});
				}
				if (!eventsWithBadge.empty) {
					eventsWithBadge.docs.forEach((event) => {
						batch.update(event.ref, {
							badges: firestore.firestore.FieldValue.arrayRemove(badgeID),
						});
					});
				}
				batch.delete(badgeRef.ref);
				batch.commit().then(() => {
					this.storage
						.ref('badgeImages/' + badgeID)
						.delete()
						.subscribe();
				});
			})
		);

		// return new Observable(sub => {

		// 	this.afs.collection('Badges').doc(badgeID).get()
		// 		.subscribe((querySnapshot) => {
		// 			// Once we get ref , begin a batch
		// 			let batch = this.afs.firestore.batch();
		// 			// let batch = this.afs.firestore.batch();
		// 			this.afs.firestore.collection('Users')
		// 				.where("badges." + badgeID, ">=", 1)
		// 				.get()
		// 				.then(data => {
		// 					this.afs.firestore.collection("Event").where("badges", "array-contains", badgeID)
		// 						.get()
		// 						.then(
		// 							events => {
		// 								if (!data.empty) {
		// 									data.forEach(user => {
		// 										let obj = {}
		// 										obj["badges." + badgeID] = firestore.firestore.FieldValue.delete()
		// 										batch.update(user.ref, obj);
		// 									})
		// 								}
		// 								if (!events.empty) {
		// 									events.forEach(event => {
		// 										let obj = {}
		// 										obj["badges"] = firestore.firestore.FieldValue.arrayRemove(badgeID);
		// 										batch.update(event.ref, obj);
		// 									})
		// 								}

		// 								batch.delete(querySnapshot.ref)
		// 								batch.commit().then(() => {
		// 									this.storage.ref('badgeImages/' + badgeID).delete().subscribe(
		// 										() => {
		// 											sub.next(true)
		// 											sub.complete()
		// 										}
		// 									)
		// 								})
		// 							}
		// 						).catch(err => { throw err })
		// 				})

		// 		}, (err) => { sub.error(err) })
		// })
	}

	DeletePatch(patchID: string) {
		return combineLatest([
			this.afs.collection('Patches').doc<badgeData>(patchID).get(),
			this.afs.firestore
				.collection('Users')
				.where('patches.' + patchID, '>=', 1)
				.get(),
			this.afs.firestore.collection('Event').where('badges', 'array-contains', patchID).get(),
		]).pipe(
			map((data) => {
				let batch = this.afs.firestore.batch();
				let patchRef = data[0];
				let usersWithPatch = data[1];
				let eventsWithPatch = data[2];

				if (!usersWithPatch.empty) {
					usersWithPatch.docs.forEach((user) => {
						let obj = {};
						obj['patches.' + patchID] = firestore.firestore.FieldValue.delete();
						batch.update(user.ref, obj);
					});
				}
				if (!eventsWithPatch.empty) {
					eventsWithPatch.docs.forEach((event) => {
						batch.update(event.ref, {
							badges: firestore.firestore.FieldValue.arrayRemove(patchID),
						});
					});
				}
				batch.delete(patchRef.ref);
				batch.commit().then(() => {
					this.storage
						.ref('patchImages/' + patchID)
						.delete()
						.subscribe();
				});
			})
		);

		// return new Observable(sub => {
		// 	this.afs.collection('Patches').doc(patchID).get()
		// 		.subscribe((querySnapshot) => {
		// 			// Once we get ref , begin a batch
		// 			let batch = this.afs.firestore.batch();
		// 			// let batch = this.afs.firestore.batch();
		// 			this.afs.firestore.collection('Users')
		// 				.where("patches." + patchID, ">=", 1)
		// 				.get()
		// 				.then(data => {
		// 					this.afs.firestore.collection("Event").where("patches", "array-contains", patchID)
		// 						.get()
		// 						.then(
		// 							events => {
		// 								if (!data.empty) {
		// 									data.forEach(user => {
		// 										let obj = {}
		// 										obj["patches." + patchID] = firestore.FieldValue.delete()
		// 										batch.update(user.ref, obj);
		// 									})
		// 								}
		// 								if (!events.empty) {
		// 									events.forEach(event => {
		// 										let obj = {}
		// 										obj["patches"] = firestore.FieldValue.arrayRemove(patchID);
		// 										batch.update(event.ref, obj);
		// 									})
		// 								}

		// 								batch.delete(querySnapshot.ref)
		// 								batch.commit().then(() => {
		// 									this.storage.ref('patchImages/' + patchID).delete().subscribe(
		// 										() => {
		// 											sub.next(true)
		// 											sub.complete()
		// 										}
		// 									)
		// 								})
		// 							}
		// 						).catch(err => { throw err })
		// 				})

		// 		}, (err) => { sub.error(err) })
		// })
	}

	DeleteEvent(eventID: string) {
		return this.afs
			.collection('Event')
			.doc<eventData>(eventID)
			.get()
			.pipe(
				map(
					(event) => {
						let batch = this.afs.firestore.batch();
						batch.delete(this.afs.collection('Participant').doc(eventID).ref);
						batch.delete(this.afs.collection('Registration').doc(eventID).ref);
						batch.delete(this.afs.collection('Event').doc(eventID).ref);
						return from(batch.commit());
					},
					forkJoin((sub) => {
						return from(this.realTimeDB.database.ref(eventID).remove());
					})
				)
			);

		// return new Observable(sub => {
		// 	this.afs.collection('Event').doc(eventID).get()
		// 		.subscribe((querySnapshot) => {
		// 			// Once we get the results, begin a batch
		// 			let batch = this.afs.firestore.batch();
		// 			batch.delete(this.afs.collection('Participant').doc(eventID).ref)
		// 			batch.delete(this.afs.collection('Registration').doc(eventID).ref)
		// 			batch.delete(this.afs.collection('Event').doc(eventID).ref)
		// 			// Commit the batch
		// 			batch.commit().then(() => {
		// 				//remove the event image
		// 				let deletSub = this.storage.ref('eventImages/' + eventID)
		// 					.delete()
		// 					.subscribe(() => {
		// 						//delete the chats
		// 						this.realTimeDB.database.ref(eventID).remove().then(() => {
		// 							sub.next(true)
		// 							sub.complete();
		// 						}).catch(err => { throw err });
		// 					}, (err) => { deletSub.unsubscribe(); throw (err); }, () => {
		// 						deletSub.unsubscribe();
		// 					})

		// 			}).catch(err => { throw err })
		// 		}, (err) => { sub.error(err); sub.complete(); })
		// })
	}

	FetchUser(userID: String): Observable<any> {
		return this.afs
			.collection('Users')
			.doc(`${userID}`)
			.get()
			.pipe(retry(3))
			.pipe(
				map((user) => {
					if (!user.exists) throwError('user doc not found');
					return user;
				})
			);
	}

	GetEventTeamLists(eventID: string) {
		return combineLatest([
			this.afs.collection('Event').doc<eventData>(eventID).get(),
			this.afs
				.collection('Registration')
				.doc<registrationData>(eventID)
				.collection('EventRegistration', (ref) => ref.where('status', '==', REGISTRATION_STATUS.Attending))
				.get(),
		]).pipe(
			map((data) => {
				let teamsCountArr: number[] = [];
				for (const inx of data[0].data().eventTeams) {
					teamsCountArr.push(0);
				}
				data[1].docs.forEach((doc) => {
					teamsCountArr[doc.data()['team']] += 1;
				});
				const output = {
					limits: data[0].data()['eventTeamsMaxPlayers'],
					teamNumbers: teamsCountArr,
				};
				return output;
			})
		);
	}

	//LIVE EVENT PARTICIPANT TABLE

	RegisterParticipantForEvent(userID: string, eventID: string, team: Number, Scanned: boolean = false) {
		return combineLatest([
			this.afs.collection('Users').doc(userID).get(),
			this.afs.collection('Participant').doc(eventID).collection('Lobby').doc(userID).get(),
			this.afs
				.collection('Event')
				.doc(`${eventID}`)
				.get()
				.pipe(
					map((data) => data.data()),
					pluck('xp')
				),
		]).pipe(
			map((data) => {
				if (data[1].metadata.fromCache) {
					throw new Error('Cannot set participation status user is offline');
				}
				if (data[1].exists) {
					throw new Error('Already participating in event');
				}

				let batch = this.afs.firestore.batch();

				if (!Scanned) this.log(LogType.Participant, `Participating in Event using GPS`, ReasonIdType.Event, userID, eventID, batch);
				else this.log(LogType.Participant, `Participating in Event using QR`, ReasonIdType.Event, userID, eventID, batch);

				batch.set(this.afs.collection('Participant').doc(`${eventID}`).collection('Lobby').doc(`${userID}`).ref, { team: team });
				batch.update(data[0].ref, {
					xp: firestore.firestore.FieldValue.increment(Number(data[2])),
					matchesPlayed: firestore.firestore.FieldValue.increment(1),
				});

				return batch;
			})
		);
	}

	WatchEventParticipants(eventID: String) {
		return this.afs.collection('Participant').doc(`${eventID}`).collection('Lobby').stateChanges();
	}

	WatchEventQueue(eventID: String) {
		return this.afs
			.collection('Registration')
			.doc(`${eventID}`)
			.collection('EventRegistration', (ref) => ref.where('status', '==', REGISTRATION_STATUS.InQueue).orderBy('timestamp', 'asc'))
			.snapshotChanges();
	}

	WatchEvent(eventID: String) {
		return this.afs.collection('Event').doc(`${eventID}`).snapshotChanges();
	}

	GetUserParticipantStatus(userID: String, eventID: String) {
		return this.afs.collection('Participant').doc(`${eventID}`).collection('Lobby').doc(`${userID}`).get();
	}

	WatchUserParticipantStatus(userID: String, eventID: String) {
		return this.afs.collection('Participant').doc(`${eventID}`).collection('Lobby').doc(`${userID}`).snapshotChanges();
	}

	GetUsername(userID: string): Observable<string> {
		return this.afs
			.collection('Users')
			.doc(userID)
			.get()
			.pipe(
				map((data) => {
					if (data.exists) return data.data();
					else return { userID: null };
				}),
				pluck('userID')
			);
	}

	GetEvent(eventID: String) {
		return this.afs.collection('Event').doc<eventData>(`${eventID}`).get();
	}

	ClaimBonusXP(eventID: string, userID: string) {
		return combineLatest([
			this.afs.collection('Users').doc<userData>(userID).get(),
			this.afs.collection('Participant').doc(eventID).collection('Lobby').doc<participantData>(userID).get(),
			this.afs.collection('Event').doc<eventData>(`${eventID}`).get(),
		]).pipe(
			map((data) => {
				if (data[1].data() && data[1].data()['bonusXPClaimed'] != null) {
					throw new Error('bonus XP already claimed');
				}
				let user = data[0];
				let participant = data[1];
				let event = data[2].data();

				let batch = this.afs.firestore.batch();

				if (event.bonusXP > 0 || event.patches.length || event.badges.length)
					this.log(LogType.BonusRewards, `Claimed Bonus Rewards For Event`, ReasonIdType.Event, userID, eventID, batch);

				if (event.bonusXP > 0) {
					this.log(LogType.BonusRewards, `bonus ${event.bonusXP} XP for event redeemed`, ReasonIdType.Event, userID, eventID, batch);
					batch.update(user.ref, {
						xp: firestore.firestore.FieldValue.increment(Number(event.bonusXP)),
					});
				}
				if (participant.data()) {
					batch.update(participant.ref, { bonusXPClaimed: true });
				} else {
					batch.set(participant.ref, {
						bonusXPClaimed: true,
						team: participant.data().team,
					});
				}

				return combineLatest([
					this.AddUserPatchesBatch(data[0].id, event.patches, batch, eventID),
					this.AddUserBadgesBatch(data[0].id, event.badges, batch, eventID),
					of(batch),
				]);
			}),
			switchMap((obs) => {
				return forkJoin([obs]);
			})
		);
	}

	CreateEventDocument(obj, imageData): Observable<any> {
		return new Observable((sub) => {
			this.afs
				.collection('Event')
				.add(obj)
				.then((ref) => {
					this.storage
						.upload('eventImages/' + `${ref.id}`, imageData)
						.then(() => {
							sub.next(true);
						})
						.catch((err) => {
							throw err;
						});
				})
				.catch((err) => {
					sub.error(err);
				});
		});
	}

	//EVENTDETAILS REGISTRATION
	SetUserRegistrationStatus(eventUid: String, userUid: string, statusToSet: Number, loadedEvent: any, registrationStatus: any) {
		return this.afs
			.collection('Users')
			.doc<userData>(`${userUid}`)
			.get()
			.pipe(
				map((data) => {
					let obj: registrationData = {
						status: statusToSet as REGISTRATION_STATUS,
						team: registrationStatus.team,
						timestamp: Date.now(),
						callsign: data.data().userID,
						xp: data.data().xp,
					};
					let maxParticipants = loadedEvent.eventData.maxParticipants;
					let allRegistrantsCount = loadedEvent.totalEventParticipants.length;
					let minimumXP = loadedEvent.eventData.minimumXP;

					if (minimumXP && data.data().xp < minimumXP) throw new Error(`Event requires a minimum player XP of ${minimumXP}`);

					//over Max participants and register status?
					if (allRegistrantsCount >= maxParticipants && statusToSet == REGISTRATION_STATUS.Attending) {
						obj.status = REGISTRATION_STATUS.InQueue;
						statusToSet = REGISTRATION_STATUS.InQueue;
					}

					let batch = this.afs.firestore.batch();
					//if someone is dropping out i.e status == NotAttending  && the queue is full
					if (allRegistrantsCount >= maxParticipants && statusToSet == REGISTRATION_STATUS.NotAttending) {
						this.log(LogType.Registration, `Registration status set to [Not Attending]`, ReasonIdType.Event, userUid, eventUid.toString(), batch);
						this.afs
							.collection('Registration')
							.doc(`${eventUid}`)
							.collection('EventRegistration')
							.doc(`${userUid}`)
							.set(obj)
							.then(() => {
								console.log('Bump Queue');

								this.BumpEventQueue(eventUid.toString(), maxParticipants, batch, eventUid.toString(), ReasonIdType.Event).subscribe(
									() => {
										batch.commit();
									},
									(err) => {
										console.error(err);
									}
								);
							})
							.catch((err) => {
								console.error(err);
							});
					} else {
						let reference = {};
						if (statusToSet != REGISTRATION_STATUS.NotAttending)
							reference[`${eventUid}`] = this.afs.collection('Registration').doc(`${eventUid}`).collection('EventRegistration').doc(`${userUid}`).ref;
						else reference[`${eventUid}`] = firestore.firestore.FieldValue.delete();

						if (statusToSet == REGISTRATION_STATUS.InQueue) {
							batch.set(this.afs.collection('UserRegistrations').doc(userUid).ref, reference, { merge: true });
							this.log(LogType.Registration, `Registration status set to [In Queue]`, ReasonIdType.Event, userUid, eventUid.toString(), batch);
						} else if (statusToSet == REGISTRATION_STATUS.Attending) {
							batch.set(this.afs.collection('UserRegistrations').doc(userUid).ref, reference, { merge: true });
							this.log(LogType.Registration, `Registration status set to [Registered]`, ReasonIdType.Event, userUid, eventUid.toString(), batch);
						} else if (statusToSet == REGISTRATION_STATUS.NotAttending) {
							batch.set(this.afs.collection('UserRegistrations').doc(userUid).ref, reference, { merge: true });
							this.log(LogType.Registration, `Registration status set to [Not Attending]`, ReasonIdType.Event, userUid, eventUid.toString(), batch);
						}
						batch.set(this.afs.collection('Registration').doc(`${eventUid}`).collection('EventRegistration').doc(`${userUid}`).ref, obj);
						batch.commit().catch((err) => {
							console.error(err);
						});
					}
					return statusToSet;
				})
			);
	}

	GetUserRegistrationStatus(eventID: String, userID: String) {
		return this.afs.collection('Registration').doc(`${eventID}`).collection('EventRegistration').doc<registrationData>(`${userID}`).snapshotChanges();
	}

	GetUserBookingStatus(eventID: String, userID: String) {
		return this.afs.collection('Booking').doc(`${eventID}`).collection('UserBooking').doc(`${userID}`).snapshotChanges();
	}

	BumpEvent(eventID: string, reasonID: string) {
		let sub = this.GetEvent(eventID)
			.pipe(
				switchMap((event) => {
					let batch = this.afs.firestore.batch();
					return this.BumpEventQueue(eventID, event.data().maxParticipants, batch, reasonID, ReasonIdType.User);
				})
			)
			.subscribe(
				(batch) => {
					batch
						.commit()
						.then()
						.catch((err) => console.error(err));
					sub.unsubscribe();
				},
				(err) => {
					console.error(err);
				},
				() => {
					sub.unsubscribe();
				}
			);
	}

	BumpEventQueue(eventID: string, maxParticipants: number, batch: firestore.firestore.WriteBatch, reasonID: string, reasonType: ReasonIdType) {
		return this.afs
			.collection('Registration')
			.doc(eventID)
			.collection('EventRegistration', (ref) => ref.orderBy('timestamp', 'asc'))
			.get()
			.pipe(
				map((queue) => {
					let docs = queue.docs;
					let subbedCount = queue.docs.filter((user) => {
						return user.data().status == REGISTRATION_STATUS.Attending;
					}).length;

					docs = queue.docs.filter((user) => {
						return user.data().status == REGISTRATION_STATUS.InQueue;
					});

					if (docs.length > 0) {
						let diff = maxParticipants - subbedCount;
						let index = 0;
						while (diff) {
							this.log(LogType.Registration, `Event Queue changed new status set to [Registered]`, reasonType, docs[index].id, reasonID, batch);
							batch.update(docs[index].ref, {
								status: REGISTRATION_STATUS.Attending,
							});
							// console.log(docs[index])
							index++;
							diff--;
						}
					}
					return batch;
				})
			);
	}

	CreateBadge(obj: any, imageData: any) {
		return new Observable((sub) => {
			this.afs
				.collection('Badges')
				.add(obj)
				.then((ref) => {
					this.storage
						.upload('badgeImages/' + `${ref.id}`, imageData)
						.then(() => {
							sub.next(true);
						})
						.catch((err) => {
							throw err;
						});
				})
				.catch((err) => {
					sub.error(err);
				});
		});
	}

	CreatePatch(obj: any, imageData: any) {
		return new Observable((sub) => {
			this.afs
				.collection('Patches')
				.add(obj)
				.then((ref) => {
					this.storage
						.upload('patchImages/' + `${ref.id}`, imageData)
						.then(() => {
							sub.next(true);
						})
						.catch((err) => {
							throw err;
						});
				})
				.catch((err) => {
					sub.error(err);
				});
		});
	}

	GetBadge(badgeID: string) {
		return this.afs.collection('Badges').doc(badgeID).get();
	}

	GetPatch(patchID: string) {
		return this.afs.collection('Patches').doc<patchData>(patchID).get();
	}

	FetchRanks() {
		return this.afs.collection('Ranks').doc<ranksData>('rankList').get();
	}

	FetchBadgeImage(badgeID: string) {
		return this.storage.ref('badgeImages/' + badgeID).getDownloadURL();
	}

	FetchPatchImage(badgeID: string) {
		return this.storage.ref('patchImages/' + badgeID).getDownloadURL();
	}

	FetchPatchRequestImage(badgeID: string) {
		return this.storage.ref('patchRequestImages/' + badgeID).getDownloadURL();
	}

	GetFullPatchList() {
		return this.afs
			.collection('Patches', (ref) => ref.orderBy('patchName', 'desc'))
			.get()
			.pipe(
				map((patches) => {
					return patches.docs.map((patch) => {
						return combineLatest([of(patch), this.storage.ref('patchImages/' + patch.id).getDownloadURL()]);
					});
				}),
				switchMap((data) => {
					return forkJoin(data);
				})
			);
	}

	GetAllBadgesNoLimit() {
		return this.afs.collection('Badges', (ref) => ref.orderBy('badgeName', 'desc')).snapshotChanges();
	}

	GetAllPatchesNoLimit() {
		return this.afs.collection('Patches', (ref) => ref.orderBy('patchName', 'desc')).snapshotChanges();
	}

	AddUserBadges(userID: string, newBadges: string[], reasonID: string, badges: string[]) {
		return this.afs
			.collection('Users')
			.doc<userData>(userID)
			.get()
			.pipe(
				map((data) => {
					let badgeMap = data.data().badges;
					if (!badgeMap) badgeMap = {};

					newBadges.forEach((nb) => {
						if (badgeMap[nb]) badgeMap[nb] = badgeMap[nb] + 1;
						else badgeMap[nb] = 1;
					});
					let batch = this.afs.firestore.batch();

					batch.update(data.ref, { badges: badgeMap });
					this.log(LogType.BonusRewards, `Added Achievements [${badges.join(',')}] to account`, ReasonIdType.User, userID, reasonID, batch);
					return batch.commit();
				})
			);
	}

	AddUserPatches(userID: string, newPatches: string[], reasonID: string, patches: string[]) {
		return this.afs
			.collection('Users')
			.doc<userData>(userID)
			.get()
			.pipe(
				map((data) => {
					let patchMap = data.data().patches;
					if (!patchMap) patchMap = {};

					newPatches.forEach((nb) => {
						if (patchMap[nb]) patchMap[nb] = patchMap[nb] + 1;
						else patchMap[nb] = 1;
					});
					let batch = this.afs.firestore.batch();
					batch.update(data.ref, { patches: patchMap });
					this.log(LogType.BonusRewards, `Added Patches [${patches.join(',')}] to account`, ReasonIdType.User, userID, reasonID, batch);
					return batch.commit();
				})
			);
	}

	AcceptPatchRequest(
		userID: string,
		patchRequestID: string,
		newPatch: string,
		reasonID: string,
		patchName: string
	): Observable<firestore.firestore.WriteBatch> {
		return new Observable<firestore.firestore.WriteBatch>((sub) => {
			let innersub = this.afs
				.collection('Users')
				.doc<userData>(userID)
				.get()
				.pipe(
					map((userdoc) => {
						return combineLatest([of(userdoc), this.storage.ref('patchRequestImages/' + patchRequestID).delete()]);
					}),
					switchMap((data) => {
						return data;
					})
				)
				.subscribe((data) => {
					let patchMap = data[0].data().patches;
					if (!patchMap) patchMap = {};

					if (patchMap[newPatch]) patchMap[newPatch] = patchMap[newPatch] + 1;
					else patchMap[newPatch] = 1;

					let batch = this.afs.firestore.batch();
					batch.update(data[0].ref, { patches: patchMap });
					batch.delete(this.afs.collection('PatchRequests').doc(patchRequestID).ref);
					this.log(LogType.BonusRewards, `Accepted ${patchName} patch request`, ReasonIdType.User, userID, reasonID, batch);
					this.log(LogType.BonusRewards, `Accepted user patch request`, ReasonIdType.User, reasonID, userID, batch);
					sub.next(batch);
					sub.complete();
					innersub.unsubscribe();
				});
		});
	}

	DeclinePatchRequest(userID: string, patchRequestID: string, patchName: string, declinerUserID: string): Observable<firestore.firestore.WriteBatch> {
		return this.storage
			.ref('patchRequestImages/' + patchRequestID)
			.delete()
			.pipe(
				map(() => {
					let batch = this.afs.firestore.batch();
					batch.delete(this.afs.collection('PatchRequests').doc(patchRequestID).ref);
					this.log(LogType.Patch, `Rejected Patch request for ${patchName}`, ReasonIdType.User, userID, declinerUserID, batch);
					this.log(LogType.BonusRewards, `Rejected user patch request`, ReasonIdType.User, declinerUserID, userID, batch);
					return batch;
				})
			);
	}

	AddUserPatchesBatch(userID: string, newBadges: string[], batch: firestore.firestore.WriteBatch, reasonID: string) {
		return this.afs
			.collection('Users')
			.doc<userData>(userID)
			.get()
			.pipe(
				map((data) => {
					let patchMap = data.data().patches;
					if (!patchMap) patchMap = {};

					newBadges.forEach((nb) => {
						if (patchMap[nb]) patchMap[nb] = patchMap[nb] + 1;
						else patchMap[nb] = 1;
					});
					if (newBadges.length != 0) {
						this.log(LogType.BonusRewards, `Redeemed patches for event bonus rewards`, ReasonIdType.Event, userID, reasonID, batch);
						return batch.update(data.ref, { patches: patchMap });
					}
					return batch;
				})
			);
	}

	AddUserBadgesBatch(userID: string, newBadges: string[], batch: firestore.firestore.WriteBatch, reasonID: string) {
		return this.afs
			.collection('Users')
			.doc<userData>(userID)
			.get()
			.pipe(
				map((data) => {
					let badgeMap = data.data().badges;
					if (!badgeMap) badgeMap = {};

					newBadges.forEach((nb) => {
						if (badgeMap[nb]) badgeMap[nb] = badgeMap[nb] + 1;
						else badgeMap[nb] = 1;
					});
					if (newBadges.length != 0) {
						this.log(LogType.BonusRewards, `Redeemed badges for event bonus rewards`, ReasonIdType.Event, userID, reasonID, batch);
						return batch.update(data.ref, { badges: badgeMap });
					}
					return batch;
				})
			);
	}

	GetAllBadges(timeOrder: Boolean, filter: firestore.firestore.OrderByDirection, lastBadgeID: firestore.firestore.DocumentSnapshot) {
		if (timeOrder) {
			if (lastBadgeID != null)
				return this.afs.collection('Badges', (ref) => ref.orderBy('timestamp', filter).startAfter(lastBadgeID).limit(10)).snapshotChanges();
			return this.afs.collection('Badges', (ref) => ref.orderBy('timestamp', filter).limit(10)).snapshotChanges();
		}
		if (lastBadgeID != null)
			return this.afs.collection('Badges', (ref) => ref.orderBy('badgeName', filter).startAfter(lastBadgeID).limit(10)).snapshotChanges();
		return this.afs.collection('Badges', (ref) => ref.orderBy('badgeName', filter).limit(10)).snapshotChanges();
	}

	GetEventRegisteredLimited(eventUid: String, lastParticipantID: firestore.firestore.DocumentSnapshot) {
		return this.afs
			.collection('Registration')
			.doc(`${eventUid}`)
			.collection<registrationData>('EventRegistration', (ref) =>
				ref.where('status', '==', REGISTRATION_STATUS.Attending).orderBy('timestamp', 'desc').startAfter(lastParticipantID).limit(20)
			)
			.get();
	}

	GetAllPatchRequests() {
		return this.afs.collection('PatchRequests').get();
	}

	GetAllPatchRequestsLimted(lastPatchIdRequest: firestore.firestore.DocumentReference) {
		if (lastPatchIdRequest != null)
			return this.afs
				.collection<patchRequestData>('PatchRequests', (ref) => ref.orderBy('UserID').startAfter(lastPatchIdRequest).limit(15))
				.get();
		return this.afs
			.collection<patchRequestData>('PatchRequests', (ref) => ref.orderBy('UserID').limit(15))
			.get();
	}

	GetUserPendingPatchRequests(userID: string) {
		return this.afs
			.collection<patchRequestData>('PatchRequests', (ref) => ref.where('UserID', '==', userID))
			.get()
			.pipe(
				map((pending) => {
					if (pending.empty) throw 'pending.empty';

					return pending.docs.map((doc) => {
						return combineLatest([of(doc), this.GetPatch(doc.data().patchID), this.FetchPatchRequestImage(doc.id)]);
					});
				}),
				switchMap((data) => {
					return forkJoin(data);
				})
			);
	}

	GetAllPatches(timeOrder: Boolean, filter: firestore.firestore.OrderByDirection, lastPatchID: firestore.firestore.DocumentSnapshot) {
		if (timeOrder) {
			if (lastPatchID != null)
				return this.afs.collection('Patches', (ref) => ref.orderBy('timestamp', filter).startAfter(lastPatchID).limit(10)).snapshotChanges();
			return this.afs.collection('Patches', (ref) => ref.orderBy('timestamp', filter).limit(10)).snapshotChanges();
		}
		if (lastPatchID != null)
			return this.afs.collection('Patches', (ref) => ref.orderBy('patchName', filter).startAfter(lastPatchID).limit(10)).snapshotChanges();
		return this.afs.collection('Patches', (ref) => ref.orderBy('patchName', filter).limit(10)).snapshotChanges();
	}

	GetAllUsers(filter: firestore.firestore.OrderByDirection, LastUserID: firestore.firestore.DocumentSnapshot) {
		if (LastUserID != null)
			return this.afs.collection('Users', (ref) => ref.orderBy('userID', filter).startAfter(LastUserID).limit(10)).snapshotChanges();
		return this.afs.collection('Users', (ref) => ref.orderBy('userID', filter).limit(10)).snapshotChanges();
	}

	GetUsers() {
		return this.afs.collection('Users').get();
	}

	GetUsersByRank(LastUserID: firestore.firestore.DocumentSnapshot) {
		if (LastUserID != null)
			return this.afs
				.collection<userData>('Users', (ref) => ref.orderBy('xp', 'desc').startAfter(LastUserID).limit(15))
				.get();
		return this.afs
			.collection<userData>('Users', (ref) => ref.orderBy('xp', 'desc').limit(20))
			.get();
	}

	GetUserBadges(badgesData: string[]) {
		return of(Object.keys(badgesData)).pipe(
			map((patches) => {
				return patches.map((badge) => {
					return combineLatest([this.GetBadge(badge), this.storage.ref('badgeImages/' + badge).getDownloadURL(), of(badgesData[badge])]);
				});
			}),
			switchMap((data) => {
				return forkJoin(data);
			})
		);
	}

	//try get from cache first then from server
	GetUserCallsignAndXP(userID: string) {
		return this.afs
			.collection('Users')
			.doc<userData>(userID)
			.get()
			.pipe(
				map((user) => {
					if (!user.exists) throwError('user doc not found');
					else {
						const obj = { callsign: user.data().userID, xp: user.data().xp };
						return obj;
					}
				})
			);
	}

	GetUserPatches(patchesData: string[]) {
		return of(Object.keys(patchesData)).pipe(
			map((patches) => {
				return patches.map((patch) => {
					return combineLatest([this.GetPatch(patch), this.storage.ref('patchImages/' + patch).getDownloadURL(), of(patchesData[patch])]);
				});
			}),
			switchMap((data) => {
				return forkJoin(data);
			})
		);
	}

	WatchAllLocations() {
		return this.afs.collection('Locations').stateChanges();
	}

	CreateLocation(obj) {
		return this.afs.collection('Locations').add(obj);
	}

	DeleteLocation(locationID) {
		return this.afs.collection('Locations').doc(locationID).delete();
	}

	GetAllLocations() {
		return this.afs.collection('Locations').get();
	}

	GetEventName(logId: string) {
		return this.GetEvent(logId).pipe(
			map((data) => {
				if (data.exists) return data.data();
				else return { eventTitle: null };
			}),
			pluck('eventTitle')
		);
	}

	GetUserXP(userID: string) {
		return this.afs
			.collection('Users')
			.doc<userData>(userID)
			.get()
			.pipe(
				map((user) => {
					if (!user.exists) throwError('user doc not found');
					else {
						return { xp: user.data().xp };
					}
				}),
				pluck('xp')
			);
	}

	log(logType: LogType, logMessage: string, reasonIdType: ReasonIdType, userID: string, reasonID: string, batch: firestore.firestore.WriteBatch) {
		let obj = {
			logType: logType,
			logMessage: logMessage,
			reasonIdType: reasonIdType,
			reasonID: reasonID,
			timeStamp: Date.now(),
		};
		// console.log(obj)

		return batch.set(this.afs.collection('UserLogs').doc(userID).collection('Logs').doc(this.afs.createId()).ref, obj);
	}

	FetchUserLogs(userID: string) {
		return this.afs.collection('UserLogs').doc(userID).collection('Logs').get();
	}

	ListParticipantsWithNoBonus(eventID: string) {
		this.afs
			.collection('Participant')
			.doc(eventID)
			.collection('Lobby')
			.get()
			.pipe(
				map((users) => {
					return users.docs.filter((user) => {
						return !user.data().bonusXPClaimed;
					});
				})
			)
			.subscribe((users) => {
				users.forEach((user) => {
					console.log(user.id);
				});
				console.log(users.length);
			});
	}

	ListParticipantsWithBonus(eventID: string) {
		this.afs
			.collection('Participant')
			.doc(eventID)
			.collection('Lobby')
			.get()
			.pipe(
				map((users) => {
					return users.docs.filter((user) => {
						return user.data().bonusXPClaimed;
					});
				})
			)
			.subscribe((users) => {
				users.forEach((user) => {
					console.log(user.id);
				});
				console.log(users.length);
				return users;
			});
	}

	ListParticipantsBelowXp(eventID: string) {
		this.afs
			.collection('Registration')
			.doc(eventID)
			.collection<registrationData>('EventRegistration', (ref) => ref.where('status', '==', REGISTRATION_STATUS.Attending))
			.get()
			.pipe(take(1))
			.subscribe((x) => {
				x.docs.forEach((doc) => {
					if (doc.data().xp < 85) console.log(doc.id);
				});
			});
	}

	GetEventRewards(eventId: string) {
		return this.afs
			.collection('Event', (e) => e.where('eventID', '==', eventId))
			.get()
			.pipe(
				map((e) => {
					if (!e.empty)
						return {
							badges: e.docs[0].data()['badges'],
							patches: e.docs[0].data()['patches'],
							xp: e.docs[0].data()['xp'],
							bonusXp: e.docs[0].data()['bonusXP'],
						};
				})
			);
	}

	calcExpectedXP() {
		forkJoin([this.afs.collection('Event').get(), this.afs.collection('Users').doc('1p6aGb9UCWYp8ewu1OSupB1rayD2').get()])
			.pipe(
				map((allData) => {
					let events: { [key: string]: any } = {};
					allData[0].docs.map((e) => {
						events[e.id] = e.data();
					});

					let users: { [key: string]: any } = {};

					users[allData[1].id] = allData[1].data();

					Object.keys(users).forEach((userid) => {
						let xpExpected = 0;
						this.afs
							.collection('UserLogs')
							.doc(userid)
							.collection('Logs')
							.get()
							.pipe(
								map((logs) => {
									logs.forEach((log) => {
										// console.log(log.data())
										if (log.data().logType == LogType.XP) {
											const rExp: RegExp = /([0-9]+) ([Xp])\w+/g;
											let XpIndex = rExp.exec(log.data().logMessage);
											xpExpected += Number(XpIndex[1]);
										}
										if (log.data().logType == LogType.Participant) {
											if (events[log.data().reasonID] != undefined) {
												// console.log(`Adding ${eventXpMap[log.data().reasonID].xp} to player`)
												xpExpected += Number(events[log.data().reasonID].xp);
											} else if (!String(log.data().logMessage).includes('team switched'))
												console.warn('CANT FIND EVENT ID : ' + log.data().reasonID);
										}
										if (log.data().logType == LogType.BonusRewards) {
											if (events[log.data().reasonID] != undefined) {
												// console.log(`Adding ${eventXpMap[log.data().reasonID].bonusXp} to player`)
												xpExpected += Number(events[log.data().reasonID].bonusXP);
											}
										}
										if (log.data().logType == LogType.RedeemXP) {
											xpExpected += Number(log.data().logMessage.split(' ')[1]);
										}
									});
									console.log(`UserId: ${userid} EXPECTED: ${xpExpected}: ACTUAL: ${users[userid].xp}`);
								})
							)
							.subscribe();

						// console.log(data.users[userid])
					});
				})
			)
			.subscribe();
	}

	// 	.pipe(
	// 		map(result => {
	// 			let xpExpected: number = 0;

	// 			result.forEach(log => {
	// 				// console.log(log.data())
	// 				if (log.data().logType == LogType.XP) {
	// 					const rExp: RegExp = /([0-9]+) ([Xp])\w+/g
	// 					let XpIndex = rExp.exec(log.data().logMessage)
	// 					xpExpected += Number(XpIndex[1]);
	// 				}
	// 				if (log.data().logType == LogType.Participant) {
	// 					if (eventXpMap[log.data().reasonID] != undefined) {
	// 						// console.log(`Adding ${eventXpMap[log.data().reasonID].xp} to player`)
	// 						xpExpected += eventXpMap[log.data().reasonID].xp;
	// 					}
	// 					else
	// 						if (!String(log.data().logMessage).includes("team switched"))
	// 							console.warn("CANT FIND EVENT ID : " + log.data().reasonID)
	// 				}
	// 				if (log.data().logType == LogType.BonusRewards) {

	// 					if (eventXpMap[log.data().reasonID] != undefined) {
	// 						// console.log(`Adding ${eventXpMap[log.data().reasonID].bonusXp} to player`)
	// 						xpExpected += eventXpMap[log.data().reasonID].bonusXp;
	// 					}

	// 				}
	// 				if (log.data().logType == LogType.RedeemXP) {
	// 					xpExpected += Number(log.data().logMessage.split(" ")[1]);
	// 				}
	// 			})

	// 			console.log(`UserId: ${userId} EXPECTED: ${xpExpected}: ACTUAL: ${result[0].xp}`)
	// 		})
	// 	).pipe(take(1));

	// })

	ListDuplicateBonusXPParticipants(eventID: string) {
		let userlogArr = [];

		this.afs
			.collection('Participant')
			.doc(eventID)
			.collection('Lobby')
			.get()
			.pipe(
				map((users) => {
					return users.docs.map((user) => {
						return combineLatest([
							of(user),
							this.afs
								.collection('UserLogs')
								.doc(user.id)
								.collection('Logs', (ref) => ref.where('reasonID', '==', eventID))
								.get(),
						]);
					});
				}),
				switchMap((obs) => {
					return forkJoin(obs);
				})
			)
			.subscribe((data) => {
				data.map((e) => {
					console.log(e[1].docs.map((x) => x.data()));
				});

				this.GetEventRewards(eventID).subscribe((rewards) => {
					data.map((doc) => {
						let arr = doc[1].docs.filter((data) => {
							let firstWord = data.data().logMessage.split(' ')[0];

							return firstWord == 'Participating';
						});

						if (arr.length > 1) {
							userlogArr.push({ user: doc[0], logs: arr });
							console.log(`${doc[0].id} : ${arr.length}`);
						}
					});

					console.log(userlogArr);
					// let logMessage = "A Bonus XP error has been detected , your account xp and logs have been modified in response";

					// let batch1 = this.afs.firestore.batch();
					// let batch2 = this.afs.firestore.batch();
					// let batchArr = [batch1, batch2]
					// //create as many batches as you need for the size of the event
					// let chunkArr = this.chunkArray(userlogArr, 2)

					// console.log(chunkArr);

					// batchArr.forEach(batch => {
					// 	chunkArr[0].forEach(userLogPair => {

					// 		let redeemCount = userLogPair.logs.filter(doc => {
					// 			return doc.data().logMessage == "Claimed Bonus Rewards For Event"
					// 		}).length

					// 		if (redeemCount > 1) {
					// 			console.log("Double")
					// 			let xpToRemove = (rewards.bonusXp * redeemCount) - rewards.bonusXp;

					// 			batch.update(this.afs.collection('Users').doc(userLogPair.user.id).ref, { xp: firestore.firestore.FieldValue.increment(Number(-1 * xpToRemove)) })

					// 			this.log(LogType.XP, logMessage, ReasonIdType.User, userLogPair.user.id, "V2Kz0fgrEBfOPxfaWVymE9XTyVU2", batch)
					// 			userLogPair.logs.forEach(doc => {
					// 				batch.delete(doc.ref)
					// 			});
					// 		}

					// 	});
					// });

					// console.log(batchArr)
					// batch1.commit().then(() => {
					// 	batch2.commit().then(
					// 		() => {
					// 			console.log("I hope you know what your doing ")
					// 		}
					// 	).catch(err => {
					// 		console.log(err)
					// 	})
					// }).catch(err => {
					// 	console.log(err)
					// })
				});
			});
	}

	rewriteRegistrantList(allUsers: Set<any>, participants: any[], eventID: string) {
		console.log(participants);
		let userSet = new Set<any>();
		participants.forEach((participant) => {
			let obj: registrationData = {
				callsign: allUsers[participant.id].userID,
				xp: allUsers[participant.id].xp,
				status: REGISTRATION_STATUS.Attending,
				team: participant.team,
				timestamp: participant.timestamp,
			};
			userSet[participant.id] = obj;
		});

		console.log(userSet);
		let batch = this.afs.firestore.batch();
		let registryBatch = this.afs.firestore.batch();

		for (let key of [...Object.keys(userSet)]) {
			let reference = {};
			reference[`${eventID}`] = this.afs.collection('Registration').doc(eventID).collection('EventRegistration').doc(key).ref;
			registryBatch.set(this.afs.collection('UserRegistrations').doc(key).ref, reference, { merge: true });
			batch.update(this.afs.collection('Registration').doc(eventID).collection('EventRegistration').doc(key).ref, userSet[key]);
		}

		// console.log(batch);
		// console.log(registryBatch)
		// batch.commit().then(
		// 	() => {
		// 		console.log("Registration Updated")
		// 		registryBatch.commit().then(
		// 			() => {
		// 				console.log("UserRegistration Updated")
		// 			}
		// 		)
		// 	}
		// ).catch(err => {
		// 	console.log(err)
		// 	console.log("Epic Fail")
		// })
	}

	chunkArray(arr, n) {
		let chunkLength = Math.max(arr.length / n, 1);
		let chunks = [];
		for (var i = 0; i < n; i++) {
			if (chunkLength * (i + 1) <= arr.length) chunks.push(arr.slice(chunkLength * i, chunkLength * (i + 1)));
		}
		return chunks;
	}

	//USER BOOKING

	BookUserForEvent(userID: string, eventID: string, rentals: Array<any>, event: boolean) {
		const bookingRef = this.afs.collection('Booking').doc(`${eventID}`);
		return bookingRef.set({ add: true }).then(() => {
			bookingRef.collection('UserBooking').doc(`${userID}`).set({ eventBooked: event, rentals: rentals }, { merge: true });
		});
	}
}
