<h1 mat-dialog-title>Sign In</h1>
<div mat-dialog-content>
    <div class="example-container">
        <form *ngIf="!getAuthService()" class="example-form d-flex flex-column justify-content-center">
            <mat-form-field class="example-full-width">
                <mat-label>Email</mat-label>
                <input matInput [formControl]="emailFormControl" [errorStateMatcher]="matcher"
                    placeholder="pat@example.com">
                <mat-icon matSuffix>email</mat-icon>
                <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                    Please enter a valid email address
                </mat-error>
                <mat-error *ngIf="emailFormControl.hasError('required')">
                    Email is <strong>required</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Enter your password</mat-label>
                <input [formControl]="passwordFormControl" matInput [type]="hide ? 'password' : 'text'">
                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
            </mat-form-field>
            <p class="d-flex justify-content-end">
                <app-dialog dialogName='resetPassword' mat-raised-button style="margin-right: 5px;"></app-dialog>
                <button mat-raised-button style="margin-right: 5px;" color="primary" (click)="onLogin();">Login</button>
            </p>
        </form>
        <div *ngIf="getAuthService()">
            <h3 class="display-3">You're Logged in and ready to play!</h3>
        </div>
    </div>
</div>
<div mat-dialog-actions class="d-flex justify-content-center" *ngIf="!getAuthService() ">
    <button *ngIf="!platform.IOS && !platform.SAFARI" mat-button (click)="onGoogleClick()">
        <img style="max-width: 185px; height: 50px;" src="../../assets/images/googleSignIn.png">
    </button>
    <button *ngIf="platform.IOS || platform.SAFARI" mat-button (click)="onGoogleClickIOS()">
        <img style="max-width: 185px; height: 50px;" src="../../assets/images/googleSignIn.png">
    </button>
</div>