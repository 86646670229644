<div class="mainContainer">

	<div class="liveContainer" *ngIf="event as liveEvent">
		<div *ngIf="(participationStatus | async) as userStatus">
			<mat-card class="event-details m-2">
				<mat-card-header>
					<img mat-card-avatar *ngIf="eventImage | async as image" [src]="sanitize(image)">

					<mat-card-title>{{liveEvent.eventTitle}}</mat-card-title>
					<mat-card-subtitle *ngIf="totalSeconds > 0" class="text-danger">Event Starts In:
						{{eventTimer.Hours}}:{{eventTimer.Minutes}}:{{eventTimer.Seconds}}
					</mat-card-subtitle>

					<mat-card-subtitle
						*ngIf="totalSeconds <= 0 && secondsTillEnd > bonusClaimTime && EventHasRewards(liveEvent) && !liveEvent.bonusButtonDisabled "
						class="text-danger">


						Bonus
						Rewards Available In:
						{{bonusTimer.Hours}}:{{bonusTimer.Minutes}}:{{bonusTimer.Seconds}}
					</mat-card-subtitle>
					<mat-card-subtitle
						*ngIf="totalSeconds <= 0 && secondsTillEnd < bonusClaimTime && EventHasRewards(liveEvent) && !liveEvent.bonusButtonDisabled "
						class="text-danger">
						Bonus
						Rewards Available For:
						{{bonusEndTimer.Hours}}:{{bonusEndTimer.Minutes}}:{{bonusEndTimer.Seconds}}
					</mat-card-subtitle>
				</mat-card-header>

				<!-- IF THE EVENT HASN'T ENDED -->

				<mat-card-content *ngIf="secondsTillEnd > 0">

					<mat-chip-list aria-label="Fish selection">
						<p class="m-2">

							<mat-chip *ngIf="!userStatus.participant" color="accent" selected="true">
								Currently Not Participating
							</mat-chip>
							<mat-chip *ngIf="userStatus.participant" color="primary" selected="true">
								Participating in Game
							</mat-chip>
						</p>
						<p class="m-2" *ngIf="EventHasRewards(liveEvent)">
							<mat-chip *ngIf="userStatus.participant && userStatus.data.bonusXPClaimed " color="primary"
								selected="true">
								Bonus Rewards Claimed</mat-chip>
							<mat-chip
								*ngIf="!userStatus.participant || userStatus.participant && !userStatus.data.bonusXPClaimed "
								color="accent" selected="true">
								Bonus Rewards Not Claimed</mat-chip>
						</p>
						<p class="m-2" *ngIf="!EventHasRewards(liveEvent) ">
							<mat-chip color="muted" selected="true">
								No bonus rewards for this event
							</mat-chip>

						</p>


					</mat-chip-list>

					<hr />

					<!-- GAME NOT STARTED YET -->
					<div class="registration" *ngIf="totalSeconds >= 0">
						<div style="padding: 5px;">
							Waiting for game to start...
						</div>
					</div>
					<div class="registration">
						<div *ngIf="EventHasRewards(liveEvent) && totalSeconds < 0 && userStatus.participant &&  !liveEvent.bonusButtonDisabled && !userStatus.data.bonusXPClaimed "
							style="padding: 5px;">
							You can still redeem bonus rewards when they are available
						</div>
						<div *ngIf="totalSeconds < 0 && userStatus.participant && ( liveEvent.bonusButtonDisabled || userStatus.data.bonusXPClaimed || !EventHasRewards(liveEvent)) "
							style="padding: 5px;">
							All XP/Rewards have been claimed enjoy the game!
						</div>
					</div>

				</mat-card-content>

				<mat-card-content *ngIf="secondsTillEnd <= 0" class="m-3">
					<mat-chip-list class="mat-chip-list-stacked" style="color: white;pointer-events: none;">
						<mat-chip color="accent">
							This event has expired
						</mat-chip>
					</mat-chip-list>
				</mat-card-content>

				<mat-card-actions align="center" *ngIf="totalSeconds <= 0 && secondsTillEnd > 0 ">

					<!-- GAME STARTED -->
					<div class="registration">
						<div>
							<h4 *ngIf="!userStatus.participant">
								Claim XP with:
							</h4>
							<h4
								*ngIf="secondsTillBonus <= 0 && EventHasRewards(liveEvent) && userStatus.participant && !userStatus.data.bonusXPClaimed">
								Claim Bonus Rewards with:
							</h4>
							<h4 *ngIf="secondsTillBonus <= 0 && (!userStatus.participant || (userStatus.participant && EventHasRewards(liveEvent) && !userStatus.data.bonusXPClaimed && !liveEvent.bonusButtonDisabled ))"
								style="margin-top:-10px;">
								GPS <mat-icon color="primary">gps_fixed</mat-icon>
								Or QR Code <mat-icon color="primary">qr_code_scanner</mat-icon>
							</h4>

							<!-- PARTICIPATION -->
							<div *ngIf="!userStatus.participant"
								class="d-flex  justify-content-center align-items-center m-1">

								<button (click)="openGPSRegistrationSheet('register')" mat-fab color="primary"
									class="m-1 pulser">
									<mat-icon>gps_fixed</mat-icon>
								</button>

								<button (click)="openScanQRDialog('register')" mat-fab color="primary"
									class="m-1 pulser">
									<mat-icon>qr_code_scanner</mat-icon>
								</button>

							</div>
							<!-- BONUS REWARDS -->
							<div *ngIf="secondsTillBonus <= 0 && EventHasRewards(liveEvent) && userStatus.participant && !userStatus.data.bonusXPClaimed"
								class="d-flex  justify-content-center align-items-center m-1">

								<button (click)="openGPSRegistrationSheet('bonusXP')" mat-fab color="primary"
									class="m-1 pulser">
									<mat-icon>gps_fixed</mat-icon>
								</button>

								<button (click)="openScanQRDialog('bonusXP')" mat-fab color="primary"
									class="m-1 pulser">
									<mat-icon>qr_code_scanner</mat-icon>
								</button>

							</div>
						</div>
					</div>

				</mat-card-actions>
			</mat-card>

			<mat-card class=" w-100 m-2">

				<mat-tab-group dynamicHeight mat-stretch-tabs>
					<mat-tab label="Team Chat" class="w-100">
						<div style=" width: 100%;">
							<app-chat teamIndex="{{userTeam}}" eventID="{{eventID}}"
								teamName="{{liveEvent.eventTeams[userTeam]}}">
							</app-chat>
						</div>
					</mat-tab>
					<mat-tab label="Lobby">




						<div class="d-flex justify-content-center align-items-center w-100">
							<mat-form-field appearance="fill" class="m-3">
								<mat-label>Select a team</mat-label>
								<mat-select>
									<mat-option *ngFor="let team of liveEvent.eventTeams; index as idx" [value]="team"
										placeholder="userTeam" (click)="selectedTeam = idx">
										{{team}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<hr width="90%" />
						<div class="event-participants">
							<table class="table ">
								<tbody>
									<div *ngFor="let player of participantList | keyvalue">
										<mat-card *ngIf="player.value.team == selectedTeam" class="p-1 m-1">
											<mat-card-header class="align-items-center">

												<img mat-card-avatar src="{{player.value.photo}}"
													alt="{{player.value.user.userID}}" class="profilePhoto">

												<p class="player">
													{{player.value.user.userID}}
												</p>

											</mat-card-header>
										</mat-card>
									</div>


								</tbody>
							</table>
						</div>


					</mat-tab>

				</mat-tab-group>
			</mat-card>

		</div>

	</div>


</div>

<div *ngIf="!event " class="loading"></div>