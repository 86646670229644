<div mat-dialog-content>
	<mat-card>
		<mat-card-title>
			Event Booking
		</mat-card-title>
		<p [style]="bookingExpired && 'color: red'" *ngIf="this.eventData.bookingCutoffTime">
			Time Left to book: {{bookingHours}}:{{bookingMinutes}}:{{bookingSeconds}}
		</p>
		<p style="color: red;">
			Early cancellations will result in a R50 cancellation fee (Up to 12 hours before the event starts). Refunds are not applicable to cancellations
			afterwards.
		</p>
		<div class="p-1" *ngIf="eventData.eventBookable && hasMinXP">
			<p>{{eventData.eventBookingDescription}}</p>
			<div *ngIf="hasMinXP">
				<p>Event Cost: <strong>{{toCurrency(eventCost)}}</strong></p>
				<mat-checkbox [checked]="bookEvent" (change)="bookEventChange(!bookEvent)">Book for event</mat-checkbox>
			</div>
		</div>
		<div class="p-1" *ngIf="eventData.rentals.length > 0">
			<p><strong>Extras are available for this event.</strong></p>
			<mat-selection-list #selectedRentals (selectionChange)="selectionChange(selectedRentals.selectedOptions.selected) ">
				<mat-list-option *ngFor="let rentalItem of eventData.rentals" [value]="rentalItem">
					<div matLine>
						<p style="float: left; margin: 0;">
							{{rentalItem.description}}
						</p>
						<p style="float: right; margin: 0; font-weight: bold;">
							{{toCurrency(rentalItem.cost)}}
						</p>
					</div>
				</mat-list-option>
			</mat-selection-list>
		</div>
		<div style="padding-top: 15px;" [ngStyle]="{display: bookEvent === true || selectionCost > 0 ? 'block' : 'none'}">
			<mat-card-title>Payment Details</mat-card-title>
			<div class="p-1">
				<p>Your total: <strong>{{toCurrency(totalCost)}}</strong></p>
				<div [hidden]="!(totalCost > 0)" id="card-frame"></div>
				<mat-card-subtitle *ngIf="warning" class="text-danger"> *{{warning}}</mat-card-subtitle>
			</div>
		</div>
		<mat-card-actions *ngIf="(bookEvent || selectionCost > 0) && !bookingExpired">
			<button mat-raised-button (click)="handlePayment()">
				<div style="display: flex; align-items: center; gap: 10px;">
					<mat-spinner [diameter]="20" *ngIf="paymentStarted"></mat-spinner>
					<span>Pay Now</span>
				</div>
			</button>
			<p style="color: gray; font-style: italic; padding-top: 15px;" *ngIf="paymentStarted">
				*Please be patient, payment process may take a few minutes...
			</p>
		</mat-card-actions>
	</mat-card>
</div>
