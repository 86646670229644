import { Inject, Injectable } from '@angular/core';
import { Component } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';
import { UpdateServiceService } from './update-service.service';




@Component({
  selector: 'error-snack',
  template: ` <div  class="snip">
                  {{message}} <mat-icon >error</mat-icon>
                </div>`,
  styles: [`
  ::ng-deep .mat-snack-bar-container.error{
          background: #d9534f !important;
  }
    .snip{
      width:100%;
      color:white;
      text-align: center;
      font-family:'Orbitron'
    }

    
  `],
})
export class SnackErrorComponent {

  message: "";


  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {

    this.message = data.message;

  }
}

@Component({
  selector: 'success-snack',
  template: `<div  class="snip2">
                  {{message}} <mat-icon>check_circle</mat-icon>
                </div>`,
  styles: [`
  ::ng-deep .mat-snack-bar-container.success{
          background: #5cb85c !important;
  }
    .snip2{
      width:100%;
      color:white;
      text-align: center;
      font-family:'Orbitron'
    }

    
  `],
})
export class SnackSuccessComponent {

  message: "";


  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {

    this.message = data.message;

  }
}
@Component({
  selector: 'update-snack',
  template: `<div  class="snip" (click)="reloadPage()">
                  {{message}} click to reload
                </div>`,
  styles: [`
  ::ng-deep .mat-snack-bar-container.success{
          background: #5cb85c !important;
  }
    .snip{
      cursor:pointer;
      width:100%;
      color:white;
      text-align: center;
      font-family:'Orbitron'
    }

    
  `],
})
export class UpdateSnackComponent {

  message: "";

  reloadPage() {
    this.updates.activateUpdate().then(() => document.location.reload());
  }

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, public updates: SwUpdate) {

    this.message = data.message;

  }
}

@Injectable({
  providedIn: 'root'
})
export class SnippetService {

  constructor(private _snackBar: MatSnackBar) { }

  openSuccessSnackBar(message: string) {
    this._snackBar.openFromComponent(SnackSuccessComponent, {
      duration: 4000,
      data: { message: message },
      panelClass: "success",
      politeness: "polite"
    });
  }


  openUpdateSnippet() {
    this._snackBar.openFromComponent(UpdateSnackComponent, {
      duration: 100000,
      data: { message: "A new version of the website is avaliable!" },
      panelClass: "success",
      politeness: "assertive"
    });
  }



  openErrorSnackBar(message: string) {
    this._snackBar.openFromComponent(SnackErrorComponent, {
      duration: 4000,
      data: { message: message },
      panelClass: "error",
      politeness: "assertive"
    });
  }
}
