import { Injectable } from '@angular/core';



export class PageState {
  scrollIndices: { [key: string]: Number };
  navbarOpen: boolean;
}

export class StateData {
  images: { [key: string]: string };
  firebaseData: Object;
}

export class State {
  previousState: State;
  nextState: State;

  stateType: any;

  //acess methods
  public data: StateData;
  public pageState: PageState;

  constructor(stateType, next = null, prev = null) {
    this.stateType = stateType
    this.nextState = next;
    this.previousState = prev;
  }

}


@Injectable({
  providedIn: 'root'
})
export class StateServiceService {

  currentState: State;

  newState(stateType: any) {
    let nextState = new State(stateType)
    this.currentState.nextState = nextState;
    nextState.previousState = this.currentState;
    this.currentState = nextState;
  }

  constructor() {
    // console.log("BEEP BOOP States are managed")
  }

  loadState() {
    if (!this.currentState)
      this.currentState = new State(State)
  }


}
