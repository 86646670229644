import { NgcCookieConsentConfig } from "ngx-cookieconsent";

export const consentConfig: NgcCookieConsentConfig = {
    cookie: {
        domain: window.location.hostname
    },
    position: "bottom",
    theme: "classic",
    palette: {
        popup: {
            background: "#000000",
            text: "#ffffff"
        },
        button: {
            background: "#ff0100",
            text: "#000000"
        }
    },
    type: "opt-in",
    revokable: false,

    content: {
        message: "This website uses cookies to ensure you get the best experience on our website.",
        dismiss: "Got it!",
        deny: "Refuse cookies",
        link: "Our Privacy Policy",
        href: "https://www.privacypolicygenerator.info/live.php?token=7x94uJJRiRTIS5lM3wQxD0HqAskiM3xp",
        policy: "Privacy Policy"
    }
}

