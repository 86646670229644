import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NodesComponent } from 'src/app/nodes/nodes.component';
import { DatabaseService } from 'src/app/services/database.service';

@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss']
})
export class RulesComponent implements OnInit, AfterViewInit {

  ruleNames: string[];
  ruleData: any[];
  opened = null;
  constructor(private db: DatabaseService) {

  }

  @ViewChild(NodesComponent, { static: true })
  nodes: NodesComponent;

  openIndex(index) {
    this.opened = index;
  }

  ngOnInit(): void {
    this.nodes.particleName = "particlesLiveEventsDetailsProfile";
    let sub = this.db.LoadRuleBook()
      .get()
      .subscribe(
        (rules) => {
          this.ruleNames = Object.keys(rules.data())
          this.ruleData = Object.values(rules.data())
        },
        (err) => {

        },
        () => {
          sub.unsubscribe();
        }
      )

  }

  ngAfterViewInit() {
    this.nodes.loadLiveEventsDetailConfig();
  }

}
