<mat-nav-list *ngIf="!selectedPatch">
    <a mat-list-item *ngFor="let patch of patches" class="patchItem" (click)="selectPatch(patch)">
        <img class="patchImage" src="{{patch[1]}}">
        <div class="m-2 text-black">
            {{patch[0].data().patchName}}
            <p class="p-1 text-muted overflow">
                <small style="font-size: x-small;">
                    -{{patch[0].data().patchDescription}}
                </small>
            </p>
        </div>
    </a>
</mat-nav-list>

<div *ngIf="selectedPatch && !submitting">
    <h3 class="heading">
        Selected Patch
    </h3>

    <div class="patchItem">
        <img class="patchImage" src="{{selectedPatch[1]}}">
        <div class="m-2 text-black">
            {{selectedPatch[0].data().patchName}}
            <p class="p-1 text-muted overflow">
                <small style="font-size: x-small;">
                    -{{selectedPatch[0].data().patchDescription}}
                </small>
            </p>
        </div>
    </div>

    <h1 class="heading d-flex justify-content-center align-items-center" *ngIf="!imageUploaded">
        We need a picture of your {{selectedPatch[0].data().patchName}} patch.


    </h1>

    <h5 class="heading d-flex justify-content-center align-items-center m-3" *ngIf="imageUploaded">
        You're ready to request a patch!
        <mat-icon class="m-2">thumb_up</mat-icon>
    </h5>
    <div class="d-flex justify-content-center w-100">
        <div class="Neon Neon-theme-dragdropbox w-75 " *ngIf="!imageUploaded">
            <input
                style="z-index: 999; opacity: 0; width: 320px; height: 200px; position: absolute; right: 0px; left: 0px; margin-right: auto; margin-left: auto;"
                #imageInput type="file" accept="image/*" (change)="uploadPatchImage(imageInput)">
            <div class="Neon-input-dragDrop">
                <div class="Neon-input-inner">
                    <div class="Neon-input-icon"><i class="fa fa-file-image-o"></i></div>
                    <div class="Neon-input-text">
                        <h3>Drag&amp;Drop files here</h3> <span style="display:inline-block; margin: 15px 0">or</span>
                    </div><a class="Neon-input-choose-btn blue">
                        <mat-icon>photo_camera</mat-icon> Browse Files
                    </a>
                </div>
            </div>
        </div>
    </div>


    <div class="d-flex justify-content-end" *ngIf="imageUploaded">
        <button mat-raised-button color="primary" (click)="uploadRequest()">Submit Request</button>
    </div>

</div>

<div class="w-100 d-flex justify-content-center" *ngIf="loadingPatches || submitting">
    <mat-spinner></mat-spinner>
</div>