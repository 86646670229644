import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';



interface DialogData {
    name: string;
}
@Component({
    selector: 'app-confirm-dialog',
    templateUrl: '../confirm-dialog/confirm-dialog.component.html',
    styleUrls: ['../confirm-dialog/confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {


    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {

    }

    getString() {
        return this.data["confirmString"];
    }

};
