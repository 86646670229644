<div mat-dialog-content>
	<mat-card>
		<mat-card-title>
			How to cancel a booking
		</mat-card-title>
		To refund your ticket purchase, please email grant@flg.co.za the following information:
		<ul>
			<li>
				Name and surname
			</li>
			<li>
				Cellphone number
			</li>
			<li>
				Callsign (as per COF website)
			</li>
			<li>
				Event date and description
			</li>
			<li>
				Event/Extras you would like to cancel
			</li>
			<li>
				The account details you want the refund to be paid into
			</li>
		</ul>
		<p>
			Refunds are handled on a weekly basis, and are not automated by our system. <br />
			If you have not received a response within a week of your request, please send a Whatsapp to Grant at 079 517 5640
		</p>
	</mat-card>
</div>
