<nav #navbar class=" navbar-expand-custom navbar-expand-xl  navbar bottom-edge-shadow  navbar-dark">
	<span routerLink="/" class="navbar-brand "><img class="icon" src="../../assets/images/Logo.png "></span>
	<button #toggler mat-raised-button [color]="isOpen ? 'primary' : 'accent'" class="navbar-toggler" type="button"
		data-toggle="collapse" data-target="#navbarNavAltMarkup" (click)="toggleOpen()"
		aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
		<span class="d-flex align-items-center" style="font-size: small;">
			<mat-icon>menu</mat-icon>MENU
		</span>
	</button>

	<div class="collapse navbar-collapse" id="navbarNavAltMarkup">
		<div class="navbar-nav justify-content-between w-100">
			<div class="navControls d-flex ">

				<!--*ngIf="authService.user != null" -->
				<a class="nav-item nav-link" *ngIf="authService.user != null" (click)="navigateToToggle('profile')"
					routerLinkActive="active" routerLink="/profile" data-toggle="collapse"
					data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false"
					aria-label="Toggle navigation">Profile</a>
				<a class="nav-item nav-link" routerLink="/events" (click)="navigateToToggle('events')"
					routerLinkActive="active" data-toggle="collapse" data-target="#navbarNavAltMarkup"
					aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">Events</a>
				<a class=" nav-item nav-link" routerLink="/rankings" (click)="navigateToToggle('rankings')"
					routerLinkActive="active" data-toggle="collapse" data-target="#navbarNavAltMarkup"
					aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">Rankings</a>
				<a class="nav-item nav-link" routerLink="/rules" (click)="navigateToToggle('rules')"
					routerLinkActive="active" data-toggle="collapse" data-target="#navbarNavAltMarkup"
					aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">Rules</a>
				<a class="nav-item nav-link" routerLink="/aboutUs" (click)="ScrollToAboutUs()" routerLinkActive="active"
					data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup"
					aria-expanded="false" aria-label="Toggle navigation">About Us</a>
				<a class="nav-item nav-link" routerLink="/contactUs" (click)="ScrollToContactUs()"
					routerLinkActive="active" data-toggle="collapse" data-target="#navbarNavAltMarkup"
					aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">Contact
					Us</a>
				<a class="nav-item nav-link" *ngIf="(authService.admin | async) || (authService.marshal | async)"
					routerLink="/admin" routerLinkActive="active" data-toggle="collapse"
					(click)="navigateToToggle('admin')" data-target="#navbarNavAltMarkup"
					aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">Admin
					Console</a>
			</div>
			<div class="loginContainer" *ngIf="authService.user == null">
				<app-dialog style="margin: 5px;" dialogName="Login"></app-dialog>
				<app-dialog style="margin: 5px;" dialogName="userRegistration">Register</app-dialog>
			</div>
			<div class="logout" *ngIf="authService.user != null">
				<button (click)="logout()" mat-raised-button>Logout</button>
			</div>
		</div>

	</div>

</nav>