import { Component, OnInit } from '@angular/core';
import { Platform } from '@angular/cdk/platform';

@Component({
	selector: 'app-contact-us-page',
	templateUrl: './contact-us-page.component.html',
	styleUrls: ['./contact-us-page.component.scss'],
})
export class ContactUsPageComponent implements OnInit {
	constructor(public platform: Platform) {}

	ngOnInit(): void {}
}
