import { AfterViewInit, Component, Input, OnDestroy, ViewChild, EventEmitter, ElementRef, ViewChildren, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ScrollServiceService } from '../services/scroll-service.service';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements AfterViewInit {

	@Input('collapse') collapse: EventEmitter<boolean>;
	@ViewChild('toggler') toggler: ElementRef<HTMLElement>;
	@ViewChild('navbarNavAltMarkup') navbarNavAltMarkup;
	isOpen = false;

	constructor(
		public authService: AuthService,
		private router: Router,
		private scroll: ScrollServiceService,

		private viewContainerRef: ViewContainerRef,
		private cfr: ComponentFactoryResolver
	) {

	}

	ngAfterViewInit() {
		this.collapse.subscribe(() => {
			if (this.isOpen)
				this.isOpen = false;
		})
	}

	navigateToToggle(route: string) {
		this.router.navigate([route]);

		if (window.innerWidth <= 992)
			this.toggleOpen()
	}

	toggleOpen() {

		this.isOpen = !this.isOpen;

	}

	logout() {
		this.authService.logout().then(() => {
			this.router.navigate(['/']);
		});
		// this.authService.
	}


	ScrollToContactUs() {
		this.router.navigate(['/']);
		this.toggleOpen()
		this.delay(600).then(
			() => {
				this.scroll.scrollToContactUs.emit(true);
			}
		)

	}

	ScrollToAboutUs() {
		this.router.navigate(['/']);
		this.toggleOpen()
		this.delay(600).then(
			() => {
				this.scroll.scrollToAboutUs.emit(true);
			}
		)
	}
	delay(ms: number) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}


	async OpenProfileLazy() {
		this.viewContainerRef.clear();
		const { ProfilePageComponent } = await import('../pages/profile-page/profile-page.component');
		this.viewContainerRef.createComponent(
			this.cfr.resolveComponentFactory(ProfilePageComponent)
		);
	}
}