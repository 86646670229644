import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DatabaseService } from 'src/app/services/database.service';
import { combineLatest, forkJoin, of, Subscription } from 'rxjs';
import firebase from 'firebase/app';
import { DomSanitizer } from '@angular/platform-browser';
import { map, switchMap } from 'rxjs/operators';
import { NodesComponent } from 'src/app/nodes/nodes.component';

@Component({
	selector: 'app-rankings-page',
	templateUrl: './rankings-page.component.html',
	styleUrls: ['./rankings-page.component.scss'],

})
export class RankingsPageComponent implements OnInit, OnDestroy, AfterViewInit {

	lastUserID: firebase.firestore.DocumentSnapshot<any>;
	userList: any[];
	userlistSub: Subscription;

	@ViewChild(NodesComponent, { static: true })
	nodes: NodesComponent;

	throttle = 50;
	scrollDistance = 1;
	scrollUpDistance = 2;

	constructor(private db: DatabaseService, private sanitization: DomSanitizer) {

	}

	ngAfterViewInit() {
		this.nodes.loadLiveRanksConfig();
	}

	ngOnDestroy() {
		if (this.userlistSub)
			this.userlistSub.unsubscribe()
	}

	ngOnInit() {
		this.nodes.particleName = "particlesRanks";
		this.userList = [];
		this.FetchUsers()
		this.userlistSub = new Subscription()
	}

	loadingUsers = false;
	FetchUsers() {
		if (!this.loadingUsers) {
			this.loadingUsers = true;
			this.userlistSub = this.db.GetUsersByRank(this.lastUserID).pipe(
				map(data => {

					if (!data.empty) {

						// this.lastUserID = data.docs[data.docs.length - 1];
						return data.docs.map(doc => {
							this.lastUserID = doc;
							return combineLatest([
								of(doc),
								this.db.FetchProfileImage(doc.id)
							])
						})
					}
					this.loadingUsers = false;
					return []

				}),
				switchMap(data => {
					return forkJoin(data)
				})
			).subscribe(
				(data) => {
					if (data.length) {
						data.forEach(doc => {
							if (doc[0].data().xp > 0)
								this.userList.push({ key: doc[0].id, value: { data: doc[0].data(), image: this.sanitize(doc[1]) } })
						})

					}
					this.loadingUsers = false;
				}, (err) => {
					console.error(err)
				}, () => {
					this.loadingUsers = false;
					this.userlistSub.unsubscribe();
				}
			)

		}
	}

	sanitize(url: string) {
		return this.sanitization.bypassSecurityTrustStyle(`url('${url}')`);
	}

	onScrollDown() {

		this.FetchUsers();
	}



}

