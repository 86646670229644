import { BreakpointObserver } from '@angular/cdk/layout';
import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appResponsiveSidenav]'
})
export class ResponsiveSidenavDirective implements OnInit, OnDestroy {

  @Input() permanentAt: number;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private breakpoint: BreakpointObserver,
    private sidenav: MatSidenav
  ) { }


  ngOnInit() {
    const permanent$ = this.breakpoint
      .observe(`(min-width: ${this.permanentAt}px)`)
      .pipe(
        takeUntil(this.destroy$),
        map(({ matches }) => matches)
      );

    permanent$.subscribe(permanent => {
      this.sidenav.mode = permanent ? "side" : "push";
      this.sidenav.fixedInViewport = permanent ? true : false;;
      this.sidenav.disableClose = permanent ? true : false;;
      this.sidenav.opened = permanent;
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
