<div class="" style="height:100%; padding-top: 10%">
    <div class="example-container d-flex flex-column justify-content-center p-3" style="height: 90%;">
        <div class="liveDialogForm d-flex flex-column justify-content-center "
            *ngIf="GPSstate=='Searching' && GPSError == null">
            <h3 class="text-dark">Connecting to Satellite... </h3>
            <div class="spinner-grow spinner-grow-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>

        </div>
        <div class="liveDialogForm d-flex flex-column justify-content-center "
            *ngIf="GPSstate=='Awaiting' && GPSError == null">
            <h3 class="text-dark" *ngIf="this.data.mode == 'register'">Attempting Registration ... please be patient
            </h3>
            <h3 class="text-dark" *ngIf="this.data.mode == 'bonusXP'">Checking GPS position... please be patient</h3>
            <div class="spinner-grow spinner-grow-secondary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <p [innerHTML]="gpsFeedback"></p>
        </div>
        <div class="liveDialogForm d-flex flex-column justify-content-center"
            *ngIf="GPSstate=='Registered' && GPSError == null">
            <h3 class="text-dark" *ngIf="this.data.mode == 'register'">Welcome to the event soldier.</h3>
            <h3 class="text-dark" *ngIf="this.data.mode == 'bonusXP'">GPS IN RANGE</h3>
            <span class="material-icons  md-48">
                check_circle
            </span>
        </div>
        <div class="liveDialogForm d-flex flex-column justify-content-center"
            *ngIf="GPSstate=='OutOfRange' && GPSError == null">
            <h2 class="text-dark text-bold">You're out of the event range! (~500m)</h2>
            <h5 class="text-dark text-bold">If GPS is unavailable please find a marshal and ask to scan a QR CODE</h5>
            <p [innerHTML]="gpsFeedback"></p>
            <div>
                <agm-map style="height: 200px;" [latitude]="currentPosition.lat" [longitude]="currentPosition.long"
                    [zoom]="13">
                    <agm-marker [latitude]="currentPosition.lat" [longitude]="currentPosition.long">
                    </agm-marker>
                    <agm-circle [latitude]="data.eventLocation[0]" [longitude]="data.eventLocation[1]" [radius]="500"
                        [fillColor]="'red'" [circleDraggable]="false" [editable]="false">
                    </agm-circle>
                </agm-map>
            </div>
        </div>
        <h2 class="GPSError text-danger" [innerHTML]="GPSError"></h2>

        <!-- <div *ngIf=" canClose" class="mt-auto" mat-dialog-actions>
            <button class="btn btn-dark m-1" matDialogClose>close</button>
            <button class="btn btn-danger m-1" (click)="openHelpDialog()">Gps Help</button>
        </div> -->
    </div>
</div>