import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { NodesComponent } from '../../nodes/nodes.component'
import { ScrollServiceService } from 'src/app/services/scroll-service.service';

import { AboutUsPageComponent } from '../about-us-page/about-us-page.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Platform } from '@angular/cdk/platform';
import { State } from 'src/app/services/state-service.service';




@Component({
	selector: 'app-landing-page',
	templateUrl: './landing-page.component.html',
	styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit, AfterViewInit {

	@ViewChild(NodesComponent, { static: true })
	nodes: NodesComponent;

	@ViewChild(AboutUsPageComponent) aboutUs;
	@ViewChild('contact') contact: ElementRef;




	constructor(private scroll: ScrollServiceService, private router: ActivatedRoute, public platform: Platform) { }

	ngOnInit(): void {
		this.nodes.particleName = "particlesLanding"

	}

	ngAfterViewInit() {
		if (this.router.data) {
			this.router.data.subscribe(
				data => {
					if (data.scrollTo == 'aboutUs') {
						this.ScrollToAboutUs();
					} else if (data.scrollTo == 'contactUs') {
						this.ScrollToContactUs();
					}
				}
			)
		}

		this.nodes.loadLandingConfig();
		this.scroll.scrollToAboutUs.subscribe((trigger) => {
			this.ScrollToAboutUs()
		})
		this.scroll.scrollToContactUs.subscribe((trigger) => {
			this.ScrollToContactUs()
		})
	}



	ScrollToContactUs() {

		this.contact.nativeElement.scrollIntoView({ behavior: 'smooth' });
	}

	ScrollToAboutUs() {

		this.aboutUs.el.nativeElement.scrollIntoView({ behavior: 'smooth' });
	}



}
