import { Component, Inject } from "@angular/core";
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from "@angular/material/bottom-sheet";
import { of, Subscription } from "rxjs";
import { AuthService } from "src/app/services/auth.service";
import { DatabaseService } from "src/app/services/database.service";
import { SnippetService } from "src/app/services/snippet.service";
import firebase from 'firebase/app';




@Component({
    selector: 'qr-scanner-dialog',
    templateUrl: './qr-scanner-dialog.html',
    styles: [
        `:host zxing-scanner::ng-deep .scanner {
			height: 400px;
			width: 400px;
			object-fit: contain;
			}`
    ]
})
export class QrScanner {


    constructor(
        private db: DatabaseService,
        public _bottomSheetRef: MatBottomSheetRef<QrScanner>,

        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
        private auth: AuthService,
        private snippet: SnippetService) {


    }


    error: string;
    QRmatch = false;
    qrEnabled = true;
    ScannerSub: Subscription;
    WaitingForCamera = false;

    camerasFoundHandler(err) {
        this.WaitingForCamera = false;
    }

    camerasNotFoundHandler(error) {
        this.WaitingForCamera = true;
        this.error = "Error Camera device Not found!"
    }

    scanErrorHandler(error) {
        this.error = error
    }

    scanSuccessHandler(qrCode) {
        let code = atob(qrCode);
        let match: boolean = code.localeCompare(this.data.eventID) == 0;

        if (this.ScannerSub != null) {
            this.ScannerSub.unsubscribe();
        }

        if (match && !this.QRmatch) {
            this.QRmatch = true;
            this.qrEnabled = false;
            if (this.data.mode == "register") {
                this.ScannerSub = this.db.RegisterParticipantForEvent(this.auth.user.value.uid, code, Number(this.data.team), true)
                    .subscribe((batch) => {
                        this.ScannerSub.add(
                            of(batch.commit())
                                .subscribe(
                                    () => {
                                        firebase.analytics().logEvent('event_participant', {
                                            success: 'true',
                                            BonusXP: "false",
                                            event_title: this.data.eventTitle,
                                            GPS: "false"
                                        });
                                        if (this.data.bonusButtonDisabled) {
                                            this.ScannerSub.add(
                                                this.db.ClaimBonusXP(this.data.eventID, this.auth.user.value.uid.toString()).subscribe(batchStatement => {
                                                    this.ScannerSub.add(of(batchStatement[0][2].commit()).subscribe(
                                                        () => {
                                                            firebase.analytics().logEvent('event_participant', {
                                                                success: 'true',
                                                                BonusXP: "true",
                                                                event_title: this.data.eventTitle,
                                                                GPS: "false"
                                                            });
                                                            this.snippet.openSuccessSnackBar("Welcome to the event soldier!");
                                                            this._bottomSheetRef.dismiss();
                                                            this.ScannerSub.unsubscribe();
                                                        }
                                                    ))
                                                }, err => {
                                                    console.error(err);
                                                    this.snippet.openErrorSnackBar("An error occured claiming xp");
                                                    this.ScannerSub.unsubscribe();
                                                })
                                            )
                                        } else {

                                            this.snippet.openSuccessSnackBar("Welcome to the event soldier!");
                                            this._bottomSheetRef.dismiss();
                                        }
                                    }
                                ))
                    }, err => {
                        this.snippet.openErrorSnackBar(err)
                    })
            } else {
                this.ScannerSub = this.db.ClaimBonusXP(this.data.eventID, this.auth.user.value.uid.toString()).subscribe(batchStatement => {
                    this.ScannerSub.add(of(batchStatement[0][2].commit()).subscribe(
                        () => {
                            this.snippet.openSuccessSnackBar("Bonus Xp Claimed!");
                            this.ScannerSub.unsubscribe();
                        }
                    ))
                }, err => {
                    console.error(err);
                    this.snippet.openErrorSnackBar("An error occured claiming xp");
                    this.ScannerSub.unsubscribe();
                })
            }
        } else {
            this.error = "Incorrect Event Code Scanned!"
        }
    }

}
