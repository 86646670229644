<div class="wrapper" [class.darkwrap]="displayedInfo != 'logs'"
	[class.lightwrap]="displayedInfo == 'logs' || mode == 'edit'">

	<div class="profile-background">
		<div *ngIf="!userProfile" class="w-100 d-flex justify-content-center">
			<mat-spinner></mat-spinner>
		</div>
		<div class="profile-card " *ngIf="mode == 'view' && userProfile ">


			<div class="profile-image-container">
				<img class="profile-image" data-src="{{userProfile.image}}" />
				<button *ngIf="viewingProfileIsUser" class="m-3" mat-mini-fab color="accent" aria-label="rewards"
					(click)="ChangeMode('edit')">
					<mat-icon>edit</mat-icon>
				</button>
			</div>


			<div class="profile-blur " [class.dark]="displayedInfo != 'logs'" [class.light]="displayedInfo == 'logs'">

				<div class="blur-container ">


					<div class="profile-callsign text-white" [class.darkHeading]="displayedInfo == 'logs'">
						{{userProfile.user.data().userID}}
					</div>
					<div class="profile-name text-white small" [class.darkHeading]="displayedInfo == 'logs'">
						{{userProfile.user.data().fullname}}
					</div>
					<div *ngIf="rank" class="profile-name text-white" [class.darkHeading]="displayedInfo == 'logs'">
						{{rank.rank}}
					</div>

					<div class="profile-buttons m-2">
						<div>
							<button *ngIf="displayedInfo == 'rewards'" class="m-2" mat-fab color="primary"
								aria-label="rewards">
								<mat-icon>military_tech</mat-icon>

							</button>
							<button *ngIf="displayedInfo != 'rewards'" (click)="displayedInfo = 'rewards'"
								(click)="LoadRewards(userProfile.user)" class="m-2" mat-mini-fab color="accent"
								aria-label="rewards">
								<mat-icon>military_tech</mat-icon>

							</button>
							<div class="button-text text-white small" [class.darkHeading]="displayedInfo == 'logs'">
								Rewards
							</div>
						</div>
						<div>
							<button *ngIf="displayedInfo == 'profile'" class="m-2" mat-fab color="primary"
								aria-label="Account">
								<mat-icon>account_box</mat-icon>
							</button>
							<button *ngIf="displayedInfo != 'profile'" (click)="displayedInfo = 'profile'" class="m-2"
								mat-mini-fab color="accent" aria-label="Account">
								<mat-icon>account_box</mat-icon>
							</button>
							<div class="button-text text-white small" [class.darkHeading]="displayedInfo == 'logs'">
								Profile
							</div>
						</div>

						<div *ngIf="(canSeeLogs || viewingProfileIsUser)">
							<button *ngIf="displayedInfo == 'logs'" class="m-2" mat-fab color="primary"
								aria-label="Account">
								<mat-icon>comment</mat-icon>
							</button>
							<button *ngIf="displayedInfo != 'logs'" (click)="FetchLogs(userProfile.user.id)" class="m-2"
								mat-mini-fab color="accent" aria-label="Account">
								<mat-icon>comment</mat-icon>
							</button>
							<div class="button-text text-white small" [class.darkHeading]="displayedInfo == 'logs'">
								Logs
							</div>
						</div>
					</div>

					<div class="displayedInfo">

						<div *ngIf="displayedInfo == 'profile'">
							<mat-list>
								<mat-list-item *ngIf="userProfile.user.data().team" class=" w-100 text-white">
									<small>Team:</small>
								</mat-list-item>
								<mat-list-item *ngIf="userProfile.user.data().team" class="text-white teamName"><img
										class="teamPicture" src="{{userProfile.teamImage}}">
									{{userProfile.user.data().team}}</mat-list-item>

								<mat-list-item style="margin-bottom: -30px" *ngIf="rank">
									<small class="w-100 text-center text-white profile-data">XP
										{{userProfile.user.data().xp}}/{{rank.xpToNext}}</small>
								</mat-list-item>

								<mat-list-item *ngIf="rank == null">
									<mat-progress-bar class="w-100" color="warn" mode="indeterminate">
									</mat-progress-bar>
								</mat-list-item>
								<mat-list-item *ngIf="rank">
									<mat-progress-bar class="w-100" color="warn" mode="determinate"
										[value]="rank.percent">
									</mat-progress-bar>
								</mat-list-item>

								<mat-list-item class=" w-100 text-white">
									<small>Service-time:</small>
								</mat-list-item>
								<mat-list-item>
									<p class=" w-100 text-white profile-data">{{calcServiceTime()}} Months
									</p>
								</mat-list-item>
								<mat-list-item class=" w-100 text-white">
									<small>Deployments:</small>
								</mat-list-item>
								<mat-list-item>
									<p class=" w-100 text-white profile-data">
										{{userProfile.user.data().matchesPlayed}} </p>
								</mat-list-item>
								<mat-list-item *ngIf="userProfile.user.data().social" class=" w-100 text-white">
									<small>Social:</small>
								</mat-list-item>
								<mat-list-item *ngIf="userProfile.user.data().social">
									<div *ngIf="userProfile.user.data().social[0] == '@'; then instagram else link">
									</div>

									<ng-template #instagram>
										<a class=" w-100 text-white profile-data"
											href="https://www.instagram.com/{{userProfile.user.data().social.substring(1)}}"
											target="_blank">
											{{userProfile.user.data().social}}
										</a>
									</ng-template>
									<ng-template #link>
										<a href="{{userProfile.user.data().social}}" target="_blank"
											class=" w-100 text-white profile-data">
											{{userProfile.user.data().social}}
										</a>
									</ng-template>

								</mat-list-item>
							</mat-list>
						</div>

						<div *ngIf="displayedInfo == 'rewards'">

							<mat-tab-group dynamicHeight mat-align-tabs="center" class="tab-group profile-tabs w-100">
								<mat-tab label="First">
									<ng-template mat-tab-label>
										<div class="text-white">
											Patches
										</div>
									</ng-template>

									<!-- request a patch -->
									<div class="d-flex justify-content-center m-3"
										*ngIf="viewingProfileIsUser && fetchPendingComplete">
										<button mat-flat-button class="request-patch-button" color="primary"
											(click)="RequestPatch()">Request
											patch <mat-icon class="example-tab-icon">add_circle
											</mat-icon></button>
									</div>
									<div #elseblock style="height: 15px;"></div>


									<!-- loading list -->
									<div class="patch-container" *ngIf="loadingPatches">
										<div class="w-100 d-flex justify-content-center align-items-center"
											style="height: 100%;">
											<mat-spinner></mat-spinner>
										</div>
									</div>
									<!-- empty list display -->
									<div class="patch-container" *ngIf="patchesEmpty">

										<h3 class="text-white  text-center p-3">No Patches Yet ...</h3>
									</div>

									<div class="patch-container" *ngIf="pendingPatches">
										<div *ngFor="let patch of pendingPatches" class="patchItem ">
											<img class="patchImage" src="{{patch[2]}}" style="opacity: 0.2;">
											<div class="m-2 text-white w-100">
												<div class="d-flex justify-content-between align-items-center">
													<p style="height: 100%;" class="text-muted">
														{{patch[1].data().patchName}}
													</p>
													<mat-chip-list class="m-1">
														<mat-chip style="opacity: 0.6;" color="accent">
															<small>Pending</small>
														</mat-chip>
													</mat-chip-list>

												</div>
												<p class="p-1 text-muted ">
													<small>{{patch[1].data().patchDescription}}</small>
												</p>
											</div>
										</div>

									</div>

									<hr style="width: 90%; color: white; background-color: rgba(255, 255, 255, 0.5);"
										class="m-3">


									<div class="patch-container" *ngIf="!loadingPatches">
										<div *ngFor="let patch of patches" class="patchItem ">
											<img class="patchImage" src="{{patch[1]}}">
											<div class="m-2 text-white">
												{{patch[0].data().patchName}}
												<p class="p-1 text-muted ">
													<small>{{patch[0].data().patchDescription}}</small>
												</p>
											</div>
										</div>

									</div>


								</mat-tab>
								<mat-tab label="Second">
									<ng-template mat-tab-label>
										<div class="text-white">
											Achievements
										</div>
									</ng-template>

									<!-- loading list -->
									<div class="badge-container" *ngIf="loadingBadges">
										<div class="w-100 d-flex justify-content-center align-items-center"
											style="height: 100%;">
											<mat-spinner></mat-spinner>
										</div>
									</div>

									<!-- empty list display -->
									<div class="badge-container d-flex justify-content-center" *ngIf="badgesEmpty">
										<h3 class="text-white m-1">No Badges Yet ...</h3>
									</div>

									<div class="badge-container" *ngIf="!loadingBadges">

										<div *ngFor="let badge of badges" class="badgeItem">
											<img class="badgeImage" src="{{badge[1]}}">
											<div class="m-2 text-white">
												{{badge[0].data().badgeName}}
												<p class="p-1 text-muted ">
													<small>-{{badge[0].data().badgeDescription}}</small>
												</p>
											</div>
										</div>

									</div>
								</mat-tab>

							</mat-tab-group>



						</div>


					</div>
				</div>
			</div>
		</div>

		<div class="profile-card" *ngIf="mode == 'edit' && userProfile">

			<button class="m-3" mat-mini-fab color="accent" aria-label="rewards" (click)="ChangeMode('view')">
				<mat-icon>arrow_back</mat-icon>
			</button>

		</div>


		<div *ngIf="mode == 'edit'" class="edit-profile">


			<div class="edit-container">
				<h1 class=" display-1  text-center">Edit Profile</h1>
				<div *ngIf="!showSpinner" [ngClass]="croppedImage ? '' : 'image-offset'">
					<image-cropper [imageChangedEvent]="imageChangedEvent" autoCrop="true" [maintainAspectRatio]="true"
						[aspectRatio]="4 / 3" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded"
						(cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()">
					</image-cropper>

					<div class="profilePicture">
						<div class=" d-flex justify-content-center align-items-center" style="height: 100%;"
							*ngIf="!(userProfile.image)">
							<mat-progress-spinner mode="indeterminate" color="warn">
							</mat-progress-spinner>
						</div>

						<div class="m-2">
							<h5 class="Orbitron d-flex align-items-center justify-content-center">
								<mat-icon class="m-2">info</mat-icon>For best
								results upload a high resolution image, crop with focal point in
								the center.
							</h5>
						</div>
						<div *ngIf="croppedImage" class="image-container"
							[ngStyle]="{'background-image': 'url(' + (croppedImage)  + ')'}">
						</div>

						<label *ngIf="!croppedImage" class="image-upload-container">
							<div class="upload-btn-wrapper">
								<div class="image-container"
									[ngStyle]="{'background-image': 'url(' + (userProfile.image)  + ')'}">
									<div class="overlay2  d-flex justify-content-center align-items-center">
										<p class="text">Upload image</p>
										<mat-icon>photo_library</mat-icon>
									</div>
								</div>
								<input class="w-75" #imageInput type="file" accept="image/*"
									(change)="fileChangeEvent($event)">
							</div>

						</label>


					</div>
				</div>

				<div *ngIf="showSpinner" class=" d-flex justify-content-center align-items-center"
					style="height: 100%;">
					<mat-progress-spinner mode="indeterminate" color="warn">
					</mat-progress-spinner>
				</div>

				<form *ngIf="!showSpinner" [formGroup]="profileForm"
					class="profileForm d-flex flex-column justify-content-center w-100">
					<mat-form-field style="min-width: 90%;" appearance="outline">
						<mat-label *ngIf="userProfile.user.data().userID == null">Enter a callsign</mat-label>
						<mat-label #elseBlock>{{userProfile.user.data().userID}}</mat-label>
						<input formControlName="username" matInput placeholder="Cool new Username">
						<mat-icon matSuffix>border_color</mat-icon>
						<mat-error class="text-danger" *ngIf="profileForm.controls.username.invalid">
							Username is not unique
						</mat-error>
					</mat-form-field>

					<mat-form-field style="min-width: 90%;" appearance="outline">
						<mat-label *ngIf="userProfile.user.data().fullname == null">Enter your fullname</mat-label>
						<mat-label #elseBlock>{{userProfile.user.data().fullname}}</mat-label>

						<input formControlName="fullname" matInput placeholder="Fullname">
						<mat-icon matSuffix>border_color</mat-icon>

						<mat-error class="text-danger" *ngIf="profileForm.controls.fullname.invalid">
							Name format not valid ,[A-z] and spacing only
						</mat-error>
					</mat-form-field>

					<mat-form-field style="min-width: 90%;" appearance="outline">
						<mat-label *ngIf="userProfile.user.data().phoneNumber == null">Enter your phone number
						</mat-label>
						<mat-label #elseBlock>{{userProfile.user.data().phoneNumber}}</mat-label>
						<input formControlName="phone" matInput placeholder="+2784 123 4567">
						<mat-icon matSuffix>border_color</mat-icon>

					</mat-form-field>


					<mat-form-field style="min-width: 90%;" appearance="outline">
						<mat-label *ngIf="userProfile.user.data().social == null">Enter any Social link
						</mat-label>
						<mat-label #elseBlock>>{{userProfile.user.data().social}}</mat-label>
						<input formControlName="social" matInput placeholder="@CofAirsoft">
						<mat-icon matSuffix>edit</mat-icon>

					</mat-form-field>
					<p class="d-flex justify-content-start w-100 m-3">
						<button (click)="SaveProfile()" mat-raised-button style="margin-right: 5px;"
							*ngIf="(profileForm.valid && !profileForm.pristine) || croppedImage">Save Changes</button>
						<button *ngIf="!profileForm.pristine || croppedImage" (click)="ResetProfile()" mat-raised-button
							style="margin-right: 5px;">Clear</button>
					</p>
				</form>


				<hr style="width: 90%; color: black; background-color: black;">

				<h1 class=" display-1  text-center">Edit Team</h1>
				<div class="edit-profile">

					<label class="image-upload-container">
						<div class="upload-btn-wrapper">
							<div class="image-container"
								[ngStyle]="{'background-image': 'url(' + (userProfile.teamImage)  + ')'}">
								<div class="overlay2  d-flex justify-content-center align-items-center">
									<p class="text">Upload team image</p>
									<mat-icon>photo_library</mat-icon>
								</div>
							</div>
							<input #imageInput2 type="file" accept="image/*"
								(change)="uploadProfileTeamPicture(imageInput2)">
						</div>

					</label>

					<form *ngIf="!showSpinner" [formGroup]="profileTeamForm"
						class="profileForm d-flex flex-column justify-content-center">


						<mat-form-field appearance="outline">
							<mat-label *ngIf="!userProfile.user.data().team">Cool Team Name
							</mat-label>
							<mat-label #elseBlock>{{userProfile.user.data().team}}</mat-label>
							<input formControlName="teamName" matInput placeholder="Cool new teamname">
							<mat-icon matSuffix>edit</mat-icon>

						</mat-form-field>
						<p class="d-flex justify-content-start">
							<button (click)="SaveTeam()" mat-raised-button style="margin-right: 5px;"
								*ngIf="(profileTeamForm.valid && !profileTeamForm.pristine)">Save
								Changes</button>
							<button *ngIf=" !profileForm.pristine" (click)="profileTeamForm.reset()" mat-raised-button
								style="margin-right: 5px;">Clear</button>
							<button #elseBlock (click)="DeleteTeam()" mat-raised-button
								style="margin-right: 5px;">Delete
								Team</button>

						</p>
					</form>


				</div>
			</div>
		</div>

		<div *ngIf="displayedInfo == 'logs' && (canSeeLogs || viewingProfileIsUser) && mode != 'edit'"
			label="Profile Logs" class="logs">
			<div class="logsInner">
				<h1 class="w-100 display-1 text-center">Logs</h1>

				<div class="w-100 d-flex justify-content-center align-items-center" style="height: 100%;"
					*ngIf="!dataSource">
					<mat-progress-spinner mode="indeterminate" color="warn">
					</mat-progress-spinner>
				</div>

				<mat-form-field class="LogFilter d-flex justify-content-center w-100">
					<mat-label class="w-100">
						<mat-icon>search</mat-icon> Search
					</mat-label>
					<input class="w-100" matInput (keyup)="applyFilter($event)"
						placeholder="Username / Badge / Patch / Date" #input color="accent">
				</mat-form-field>

				<div class="mat-elevation-z8 ">
					<table mat-table [dataSource]="dataSource" matSort class="w-100">


						<ng-container matColumnDef="Timestamp">
							<th mat-header-cell *matHeaderCellDef mat-sort-header class="p-1">Time</th>
							<td mat-cell *matCellDef="let row" class="p-1">
								<p class="log "><small style="font-size: xx-small;">{{row.timeStamp |
										date:'dd/LLL/yy
										HH:mm'}}</small></p>
							</td>
						</ng-container>

						<ng-container matColumnDef="Log">
							<th mat-header-cell *matHeaderCellDef mat-sort-header class="p-1">Log</th>
							<td mat-cell *matCellDef="let row" class="p-1">
								<p class="log" style="word-break: break-all; max-width: 100%; min-width: 120px; ">
									<small>{{row.logMessage}}</small>
								</p>
							</td>
						</ng-container>

						<!-- <ng-container matColumnDef="Issuer">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>Issuer</th>
											<td mat-cell *matCellDef="let row">
												<div class="log" *ngIf="row.reasonIdType == ReasonIdType.User"><small>User</small></div>
												<div class="log" *ngIf="row.reasonIdType == ReasonIdType.Event"><small>Event</small></div>
											</td>
										</ng-container> -->

						<ng-container matColumnDef="IssuerId">
							<th mat-header-cell *matHeaderCellDef mat-sort-header class="p-1">Issuer Id</th>
							<td mat-cell *matCellDef="let row" class="p-1">
								<p class="log" style="word-break: break-all; max-width: 100%;">
									<small style="font-size: xx-small;">{{row.reasonID}}</small>
								</p>
							</td>
						</ng-container>



						<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
						<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


						<tr class="mat-row" *matNoDataRow>
							<td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
						</tr>
					</table>

					<mat-paginator color="accent" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
				</div>
			</div>





		</div>


	</div>

</div>