import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DatabaseService, registrationData, REGISTRATION_STATUS } from '../../services/database.service';

import { combineLatest, forkJoin, interval, Observable, of, Subscription } from 'rxjs';

import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

import firebase from 'firebase/app';
import { SnippetService } from 'src/app/services/snippet.service';
import { DomSanitizer } from '@angular/platform-browser';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { eventData } from '../../services/database.service';
import { State } from 'src/app/services/state-service.service';
import { MatTooltip } from '@angular/material/tooltip';

class loadedEvent {
	eventID: string;
	eventData: eventData;
	eventImage: Observable<string>;
	canRegister: boolean;
	eventOver: boolean;
	totalEventParticipants: any[];
	slicedParticipantArr: any[];
	patches: any[];
	badges: any[];
	eventMinutes: String;
	eventHours: String;
	eventSeconds: String;
	userRegistedPrior: boolean;
	minimumXp?: number = null;
	eventBookable: boolean = false;
	bookingExpired: boolean = false;
	userBooked: boolean = false;
	minEventBookingXP: number = 0;
	constructor() {
		this.eventID = '';
		this.canRegister = false;
		this.eventImage = null;
		this.eventOver = false;
		this.badges = [];
		this.patches = [];
		this.slicedParticipantArr = [];
		this.totalEventParticipants = [];
		this.eventData = null;
		this.eventHours = '';
		this.eventMinutes = '';
		this.eventSeconds = '';
		this.userRegistedPrior = false;
		this.eventBookable = false;
		this.bookingExpired = false;
	}
}

@Component({
	selector: 'app-events-detail-page',
	templateUrl: './events-detail-page.component.html',
	styleUrls: ['./events-detail-page.component.scss'],
})
export class EventsDetailPageComponent extends State implements OnInit, AfterViewInit, OnDestroy {
	interested: number = 0;
	notAttending: number = 0;

	//infinite scoll
	throttle = 50;
	scrollDistance = 0.5;
	scrollUpDistance = 2;
	@ViewChild('snav', { static: true }) snav;
	loadMore = false;
	opened: boolean = true;
	userParticipatingInEventAlready: boolean = false;
	@ViewChild('toolTip') tooltip: MatTooltip;
	currentUser: any = null;
	eventBookable: boolean;
	bookingData: firebase.firestore.DocumentData = null;
	userXP: number;

	constructor(
		private db: DatabaseService,
		private auth: AuthService,
		private route: ActivatedRoute,
		private router: Router,
		private snippet: SnippetService,
		private sanitization: DomSanitizer,
		private cdr: ChangeDetectorRef
	) {
		super(EventsDetailPageComponent);
	}

	ngOnDestroy() {
		if (this.eventSub) this.eventSub.unsubscribe();
		if (this.initialLoadSub) this.initialLoadSub.unsubscribe();
	}

	userID: string;
	eventsLoaded = false;
	ranks: any;
	eventSub: Subscription;
	initialLoadSub: Subscription;
	loginRequired = true;
	ngOnInit() {
		this.bookingData = null;
		this.nonExpiredEvents = [];
		this.expiredEvents = [];
		this.lastExpiredEvent = null;
		this.lastNonExpiredEvent = null;

		// This fixes the infinite list issue but duplicates the list?

		// this.GetExpiredEvents().subscribe();
		// this.GetNonExpiredEvents().subscribe();

		let authSub = this.auth.afAuth.user.pipe(
			switchMap((user: any) => {
				if (!user) {
					if (this.selectedEvent) this.selectedEvent.userRegistedPrior = false;
					this.userRegistrationStatus = null;
					this.userID = null;
					return of(null);
				} else {
					this.userID = user.uid;
					this.db
						.GetUserXP(user.uid)
						.pipe(
							map((xp) => {
								return xp;
							})
						)
						.subscribe((xp) => {
							this.userXP = xp;
						});
					console.log(user.uid);
					return this.db.FetchUser(user.uid).pipe(take(1));
				}
			})
		);

		let getRanks = this.db.FetchRanks().pipe(
			map((ranks) => {
				this.ranks = ranks.data().ranks;
			})
		);
		this.initialLoadSub = combineLatest([this.GetNonExpiredEvents(), this.GetExpiredEvents(), authSub, getRanks, this.route.queryParams])
			.pipe(
				map((eventsData) => {
					if (eventsData[4].eventID) {
						let eventNew = eventsData[0].find((d) => d.id == eventsData[4].eventID);
						let eventOld = eventsData[1].find((d) => d.id == eventsData[4].eventID);
						return {
							expired: eventNew != null ? false : eventOld != null ? true : false,
							event: !eventNew ? eventOld : eventNew,
							error: !eventNew && !eventOld,
							user: eventsData[2],
						};
					} else
						return {
							expired: false,
							event: eventsData[0][0],
							error: null,
							user: eventsData[2],
						};
				})
			)
			.subscribe((data) => {
				if (data.error) this.router.navigate(['events']);
				if (data.user) this.currentUser = data.user.data();
				this.scrollIndex = 0;
				this.LoadUpEvent(data.event);
				this.upcoming = !data.expired;
				this.selectedEvent.eventID = data.event.id;
				this.eventsLoaded = true;
			});
	}

	onScrollUp() {}

	ngAfterViewInit() {
		this.snav.closedStart.subscribe(() => {
			firebase.analytics().logEvent('view_event_details', {
				event_detail_page_open: 'true',
			});
			setTimeout(() => {
				if (this.tooltip) this.tooltip.show(600);
			}, 1500);
		});

		if (!this.snav.opened) this.snav.toggle();
		this.cdr.detectChanges();
	}

	mobileToggle() {
		if (window.innerWidth <= 1200) {
			this.snav.toggle();
		}
	}

	onScrollDownExpired() {
		let sub = this.GetExpiredEvents().subscribe(
			(data) => {},
			(err) => {
				console.error(err);
			},
			() => {
				sub.unsubscribe();
			}
		);
	}

	onScrollDownNonExpired() {
		let sub = this.GetNonExpiredEvents().subscribe(
			(data) => {},
			(err) => {
				console.error(err);
			},
			() => {
				sub.unsubscribe();
			}
		);
	}

	scrollIndex = 0;
	onParticipantScrollDown() {
		this.scrollIndex += 20;
		this.selectedEvent.slicedParticipantArr = Object.values(this.selectedEvent.totalEventParticipants).slice(0, this.scrollIndex + 20);
	}

	eventExpired(EndTime) {
		const epochNow = Date.now() / 1000;
		return Number(EndTime) - Number(epochNow) + 10800 <= 0;
	}

	bookingExpired(startTime) {
		if (!this.selectedEvent.eventData.bookingCutoffTime) {
			return true;
		}
		return Number(this.selectedEvent.eventData.bookingCutoffTime?.seconds || 0) - Number(startTime) <= 0;
	}

	bookingAvailable() {
		const epochNow = Date.now() / 1000;
		const bookingStartTime = this.selectedEvent.eventData.bookingStartTime?.seconds;
		if (bookingStartTime) {
			return Number(bookingStartTime) - Number(epochNow) <= 0;
		} else {
			return true;
		}
	}

	switchEvent(event) {
		let urlTree = this.router.parseUrl(this.router.url);
		urlTree.queryParams['eventID'] = event.id;
		this.router.navigateByUrl(urlTree);
	}

	selectedEvent: loadedEvent;
	upcoming: boolean = true;
	loadedParticipants = [];

	viewProfile(user) {
		this.router.navigateByUrl(`profile?userID=${user.id}`);
	}

	secondsTillStart = 99999;
	LoadUpEvent(event: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>) {
		this.bookingData = null;
		this.lastExpiredEvent = null;
		this.lastNonExpiredEvent = null;
		if (this.eventSub) this.eventSub.unsubscribe();

		this.selectedEvent = new loadedEvent();

		const availableRentals =
			event.data().rentals?.filter((rental) => {
				if (rental.remaining > 0) {
					return true;
				} else {
					return false;
				}
			}) || [];
		this.selectedEvent.eventData = { ...event.data(), rentals: availableRentals } as eventData;

		const epochNowSeconds = new Date().getTime() / 1000;
		let totalSeconds = Number(event.data().eventStartTime.seconds) - Number(epochNowSeconds);
		this.secondsTillStart = totalSeconds;
		let totalSecondsEnd = Number(event.data().eventEndTime.seconds) - Number(epochNowSeconds);

		this.selectedEvent.bookingExpired = this.bookingExpired(epochNowSeconds);

		this.selectedEvent.canRegister = totalSeconds <= 0 ? false : true;
		this.selectedEvent.eventOver = totalSecondsEnd + 10800 <= 0 ? true : false;
		this.loadMore = false;

		this.getBadges(event);
		this.getPatches(event);

		this.CheckIfUserPlaying(event.id);
		this.SwitchTeamDialog();
		this.selectedEvent.eventImage = this.db.FetchEventImage(event.id);
		this.selectedEvent.minEventBookingXP = event.data().minEventBookingXP || 0;

		this.eventSub = this.LoadAllEventParticipants(event.id).subscribe((users: any[]) => {
			users.forEach((user) => {
				if (user.removed) {
					this.selectedEvent.totalEventParticipants = this.selectedEvent.totalEventParticipants.filter((u) => u.id != user.id);
				} else {
					let obj = {
						callsign: user.callsign,
						xp: user.xp,
						team: user.team,
						timestamp: user.timestamp,
						id: user.id,
					};

					if (user.id == this.userID) this.selectedEvent.totalEventParticipants.unshift(obj);
					else this.selectedEvent.totalEventParticipants.push(obj);
				}
			});

			if (this.getCount() >= this.selectedEvent.eventData.maxParticipants) {
				this.WatchPositionInQueue();
			}
			this.selectedEvent.slicedParticipantArr = Object.values(this.selectedEvent.totalEventParticipants).slice(0, this.scrollIndex + 20);
		});

		if (this.userID) {
			this.loginRequired = false;
			this.WatchUserRegistration(event.id);
			this.WatchUserBooking(event.id);
		}

		this.RestartTimer();
	}

	getBadges(event) {
		of(event.data().badges)
			.pipe(
				map((badges) => {
					return badges.map((badge) => {
						if (badge) return combineLatest([this.db.GetBadge(badge), this.db.FetchBadgeImage(badge)]);
						return of();
					});
				}),
				switchMap((a) => forkJoin(a))
			)
			.subscribe((badges) => {
				this.selectedEvent.badges = badges;
			});
	}

	getPatches(event) {
		of(event.data().patches)
			.pipe(
				map((patches) => {
					return patches.map((patch) => {
						if (patch) return combineLatest([this.db.GetPatch(patch), this.db.FetchPatchImage(patch)]);
						return of();
					});
				}),
				switchMap((a) => forkJoin(a))
			)
			.subscribe((patches) => {
				this.selectedEvent.patches = patches;
			});
	}
	CheckIfUserPlaying(eventID: string) {
		let sub = this.db.GetUserParticipantStatus(this.userID, eventID).subscribe(
			(doc) => {
				if (doc.exists) this.userParticipatingInEventAlready = true;
				else {
					this.userParticipatingInEventAlready = false;
				}
			},
			(err) => {
				console.error(err);
			},
			() => {
				sub.unsubscribe();
			}
		);
	}

	dialogRegistration(event: Boolean) {
		//if evcent returns true the registration was a success , reload buttons
		if (event) {
			this.selectedEvent.userRegistedPrior = true;
			this.userRegistrationStatus.status = 2;
		}
	}

	teamExists() {
		return this.userRegistrationStatus.team != null;
	}

	userRegistrationStatus: registrationData;
	WatchUserRegistrationSub: Subscription;
	WatchUserBookingSub: Subscription;

	WatchUserRegistration(eventID: string) {
		if (this.WatchUserRegistrationSub) this.WatchUserRegistrationSub.unsubscribe();

		this.WatchUserRegistrationSub = new Subscription();

		this.WatchUserRegistrationSub.add(
			this.db.GetUserRegistrationStatus(eventID, this.userID).subscribe((userRegistration) => {
				if (!userRegistration.payload.exists || userRegistration.type == 'removed') {
					this.selectedEvent.userRegistedPrior = false;
					this.userRegistrationStatus = {
						status: null,
						team: null,
						callsign: '',
						xp: 0,
						timestamp: 0,
					};
				} else {
					this.selectedEvent.userRegistedPrior = true;
					this.userRegistrationStatus = userRegistration.payload.data();
				}
			})
		);
	}

	CanBook() {
		const tempCheck =
			this.selectedEvent.canRegister &&
			this.userRegistrationStatus.status == 2 &&
			!this.selectedEvent.userBooked &&
			(this.selectedEvent.eventData.eventBookable || this.selectedEvent.eventData.rentals?.length > 0) &&
			!this.selectedEvent.bookingExpired &&
			this.bookingAvailable();
		if (tempCheck) {
			if (this.userXP >= this.selectedEvent.eventData.minEventBookingXP) {
				return true;
			} else {
				if (this.selectedEvent.eventData.rentals?.length > 0) {
					return true;
				} else {
					return false;
				}
			}
		} else {
			return false;
		}
	}

	WatchUserBooking(eventID: string) {
		if (this.WatchUserBookingSub) this.WatchUserBookingSub.unsubscribe();
		this.WatchUserBookingSub = new Subscription();
		this.WatchUserBookingSub.add(
			this.db.GetUserBookingStatus(eventID, this.userID).subscribe((userBooking) => {
				if (userBooking.payload.exists) {
					this.bookingData = userBooking.payload.data();
					this.selectedEvent.userBooked = true;
				}
			})
		);
	}

	watchQSub: Subscription;
	positionInQueue: number = -1;
	WatchPositionInQueue() {
		if (this.watchQSub) this.watchQSub.unsubscribe();

		this.watchQSub = new Subscription();
		this.watchQSub.add(
			this.db.WatchEventQueue(this.selectedEvent.eventID).subscribe((queue) => {
				this.positionInQueue = queue.findIndex((e) => e.payload.doc.id == this.userID) + 1;
			})
		);
	}

	settingStatus: boolean = false;
	setRegistrationSubscription: Subscription;
	SetRegistrationStatus(status: number) {
		if (status != this.userRegistrationStatus.status) {
			this.settingStatus = true;
			if (this.setRegistrationSubscription != null) this.setRegistrationSubscription.unsubscribe();

			this.setRegistrationSubscription = this.db
				.SetUserRegistrationStatus(this.selectedEvent.eventID, this.userID, status, this.selectedEvent, this.userRegistrationStatus)
				.subscribe(
					(statusOut) => {
						if (statusOut == REGISTRATION_STATUS.InQueue) {
							this.snippet.openSuccessSnackBar('Joined Event Queue');
							firebase.analytics().logEvent('register_event', {
								event_registration: 'true',
								event_title: this.selectedEvent.eventData.eventTitle,
							});
						} else if (statusOut == REGISTRATION_STATUS.Attending) {
							firebase.analytics().logEvent('register_event', {
								event_registration: 'true',
								event_title: this.selectedEvent.eventData.eventTitle,
							});
							this.snippet.openSuccessSnackBar('Registered');
						} else {
							firebase.analytics().logEvent('unregister_event', {
								event_registration: 'false',
								event_title: this.selectedEvent.eventData.eventTitle,
							});
							this.snippet.openSuccessSnackBar('Removed Registration');
						}
						this.settingStatus = false;
						this.setRegistrationSubscription.unsubscribe();
					},
					(err) => {
						console.error(err);
						this.snippet.openErrorSnackBar(err.message);
						this.settingStatus = false;
					}
				);
		}
	}

	sanitize(url: string) {
		return this.sanitization.bypassSecurityTrustResourceUrl(`${url}`);
	}

	RewriteEventRegistrants() {
		this.db
			.GetUsers()
			.pipe(
				map((users) => {
					let userSet = new Set<any>();
					users.docs.map((user) => {
						userSet[user.id] = user.data();
					});
					return userSet;
				})
			)
			.subscribe((usersSet) => {
				console.log(usersSet);
				console.log(this.selectedEvent);
				this.db.rewriteRegistrantList(usersSet, this.selectedEvent.totalEventParticipants, this.selectedEvent.eventID);
			});
	}

	EventHasQueue() {
		if (this.selectedEvent.totalEventParticipants) {
			if (this.selectedEvent.totalEventParticipants.length - this.selectedEvent.eventData.maxParticipants < 0) {
				return false;
			} else {
				return true;
			}
		}
		return false;
	}

	isMobileWidth() {
		if (window.innerWidth <= 678) {
			return true;
		}
		return false;
	}

	CalculateRank(xp) {
		let playerRank = 0;

		let keys = Object.keys(this.ranks);
		for (let i = 0; i < keys.length - 1; i++) {
			playerRank = i;
			if (Number(keys[i]) >= xp) {
				if (i > 0) playerRank--;
				break;
			}
		}
		let values: string[] = Object.values(this.ranks);
		return values[playerRank];
	}

	LoadAllEventParticipants(eventID: string): Observable<any[]> {
		return this.db.GetEventRegisteredParticipants(eventID).pipe(
			map((users) => {
				let list = [];
				users.map((user) => {
					let newparticipant = {
						callsign: null,
						team: '',
						removed: false,
						timestamp: 0,
						id: '',
						xp: 0,
					};
					if (user.type == 'added') {
						newparticipant.callsign = user.payload.doc.data().callsign;
						newparticipant.xp = user.payload.doc.data().xp;
						newparticipant.team = user.payload.doc.data().team;
						newparticipant.removed = false;
						newparticipant.timestamp = user.payload.doc.data().timestamp;
						newparticipant.id = user.payload.doc.id;
						list.push(newparticipant);
					} else if (user.type == 'removed' || (user.type == 'modified' && user.payload.doc.data().status == REGISTRATION_STATUS.NotAttending)) {
						newparticipant.id = user.payload.doc.id;
						newparticipant.removed = true;
						list.push(newparticipant);
					}
				});

				return list;
			})
		);
	}

	lastNonExpiredEvent: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>;
	nonExpiredEvents: any[];
	GetNonExpiredEvents(): Observable<any[]> {
		return new Observable((sub) => {
			sub.add(
				this.db.GetAllEventFilterExpired(false, this.nonExpiredEvents[this.nonExpiredEvents.length - 1]).subscribe(
					(events) => {
						// if (events.docs[events.docs.length - 1])
						// 	this.lastNonExpiredEvent = events.docs[events.docs.length - 1]
						events.docs.map((event) => {
							this.nonExpiredEvents.push(event);
						});

						sub.next(this.nonExpiredEvents);
						sub.complete();
					},
					(err) => {
						sub.error(err);
					}
				)
			);
		});
	}

	lastExpiredEvent: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>;
	expiredEvents: any[];

	GetExpiredEvents(): Observable<any[]> {
		return new Observable((sub) => {
			sub.add(
				this.db.GetAllEventFilterExpired(true, this.expiredEvents[this.expiredEvents.length - 1]).subscribe(
					(events) => {
						if (events.docs[events.docs.length - 1]) this.lastExpiredEvent = events.docs[events.docs.length - 1];
						events.docs.map((event) => {
							this.expiredEvents.push(event);
						});

						sub.next(this.expiredEvents);
						sub.complete();
					},
					(err) => {
						sub.error(err);
					}
				)
			);
		});
	}

	getCount() {
		return Object.values(this.selectedEvent.totalEventParticipants).length;
	}

	tickDelay: Observable<Number> = interval(1000);
	tickSub: Subscription;
	RestartTimer() {
		this.tickSub = this.tickDelay.subscribe((time) => {
			const epochNow = new Date().getTime() / 1000;

			let totalSeconds: any = (Number(this.selectedEvent.eventData.eventStartTime.seconds) - Number(epochNow)).toFixed(0);
			let hours,
				minutes,
				seconds = 0;
			hours = Math.floor(totalSeconds / 3600);
			totalSeconds %= 3600;
			minutes = Math.floor(totalSeconds / 60);
			seconds = totalSeconds % 60;
			if (hours + minutes + seconds < 0) {
				hours = 0;
				minutes = 0;
				seconds = 0;
			}
			this.selectedEvent.eventHours = hours <= 9 ? '0'.concat(String(hours)) : String(hours);
			this.selectedEvent.eventMinutes = minutes <= 9 ? '0'.concat(String(minutes)) : String(minutes);
			this.selectedEvent.eventSeconds = seconds <= 9 ? '0'.concat(String(seconds)) : String(seconds);
		});
	}

	GetBadgeImage(badgeID: string): Observable<any> {
		return this.db.FetchBadgeImage(badgeID);
	}

	GetBadgeData(badgeID: string): Observable<any> {
		return this.db.GetBadge(badgeID);
	}

	GetPatchImage(patchID: string): Observable<any> {
		return this.db.FetchPatchImage(patchID);
	}

	GetPatchData(patchID: string): Observable<any> {
		return this.db.GetPatch(patchID);
	}

	teamSwitchDialogRefEmitter: EventEmitter<boolean> = new EventEmitter<any>();
	SwitchTeamDialog() {
		this.teamSwitchDialogRefEmitter.subscribe((res: Observable<any>) => {
			console.log(res);
			res.subscribe((x) => console.log(x));
			if (res) {
				this.snippet.openSuccessSnackBar('Team Switched successfully');
			} else {
				this.snippet.openSuccessSnackBar('Switching Team failed');
			}
		});
	}
}
