<div class="d-flex justify-content-center">
	<div *ngIf="!eventsLoaded " class="loading"></div>
</div>

<div class="container-responsive">
	<mat-sidenav-container class="sidenav-container text-white">
		<mat-sidenav #snav class="sidenav" appResponsiveSidenav mode="push" [(opened)]="opened" [fixedInViewport]="true" hasBackdrop="false">
			<div class="m-2" style="max-width: 180px; overflow: hidden;">
				<mat-chip-list aria-label="event selection" style="max-width: 100px; margin-top: 50px; margin-bottom: 20px;">
					<mat-chip [color]="upcoming ? 'primary' : 'accent'" (click)="upcoming = true " selected>Upcoming</mat-chip>
					<mat-chip [color]="!upcoming ? 'primary' : 'accent'" (click)="upcoming = false" selected>Expired</mat-chip>
				</mat-chip-list>
			</div>

			<mat-nav-list
				*ngIf="upcoming && selectedEvent"
				class="infiniteScroll"
				infinite-scroll
				[scrollWindow]="false"
				[infiniteScrollDistance]="scrollDistance"
				[infiniteScrollUpDistance]="scrollUpDistance"
				[infiniteScrollThrottle]="throttle"
				(scrolled)="onScrollDownNonExpired()"
				(scrolledUp)="onScrollUp()"
			>
				<div *ngFor="let event of nonExpiredEvents">
					<mat-list-item
						[ngClass]="selectedEvent.eventID == event.id ? 'active': '' "
						*ngIf="!eventExpired( event.data().eventEndTime.seconds)"
						(click)="switchEvent( event)"
						class="text-white listItem listItemEvents p-1"
						(click)="mobileToggle()"
					>
						<span class="w-75 text-left" style="font-size: small;">{{event.data().eventTitle}}</span>
						<small class="w-25 text-right small">{{(event.data().eventStartTime.seconds * 1000) | date: 'LLL EEE dd,H:mm'}}</small>
					</mat-list-item>
				</div>
			</mat-nav-list>

			<mat-nav-list
				*ngIf="!upcoming"
				class="infiniteScroll"
				infinite-scroll
				[scrollWindow]="false"
				[infiniteScrollDistance]="scrollDistance"
				[infiniteScrollUpDistance]="scrollUpDistance"
				[infiniteScrollThrottle]="throttle"
				(scrolled)="onScrollDownExpired()"
				(scrolledUp)="onScrollUp()"
			>
				<div *ngFor="let event of expiredEvents">
					<mat-list-item
						[ngClass]="selectedEvent.eventID == event.id ? eventExpired( event.data().eventEndTime.seconds) ? 'expiredActive':'active'  : '' "
						*ngIf="eventExpired( event.data().eventEndTime.seconds)"
						(click)="switchEvent(event)"
						class="text-white w-100 listItem listItemEvents disabled p-1"
						(click)="mobileToggle()"
					>
						<span class="w-75 text-left" style="font-size: small;">{{event.data().eventTitle}}</span>
						<small class="w-25 text-right">
							<mat-chip-list>
								<mat-chip color="accent" [disabled]="true" selected>Expired </mat-chip>
							</mat-chip-list>
						</small>
					</mat-list-item>
				</div>
			</mat-nav-list>
		</mat-sidenav>

		<mat-sidenav-content class="sidenav-content">
			<div *ngIf="!selectedEvent " class="loading"></div>
			<div #elseBlock class="event-container" *ngIf="selectedEvent as selected">
				<div class="sidenavToggle m-2">
					<button mat-raised-button *ngIf="snav.opened" color="primary" (click)="snav.toggle()">
						<mat-icon>menu</mat-icon>
					</button>

					<button mat-raised-button color="primary" *ngIf="!snav.opened" (click)="snav.toggle()">
						<mat-icon>menu_open</mat-icon>
						Event list
					</button>
				</div>

				<div class="event-details">
					<div class="divider">
						<div class="d-flex flex-wrap w-100">
							<div class="event-image">
								<h1 class="event-title" style="font-size: x-large; margin-top: 8px; margin-bottom: 12px;">
									{{selectedEvent.eventData.eventTitle}}
								</h1>
								<div *ngIf="(selectedEvent.eventImage | async) as image;" style="min-height: 300px; display: flex;">
									<img class="mission-image" src="{{image}}" alt="mission image" />
								</div>
								<div *ngIf="!(selectedEvent.eventImage | async)">
									<div style="height: 100%;" class="d-flex justify-content-center align-items-center mission-image-placeholder">
										<mat-spinner></mat-spinner>
									</div>
								</div>
							</div>

							<div class="flex-flow divider" *ngIf="!isMobileWidth()">
								<h1 class="event-title text-white m-3">
									Participants
								</h1>
								<div
									class="event-participants"
									infinite-scroll
									[scrollWindow]="false"
									[infiniteScrollDistance]="scrollDistance"
									[infiniteScrollUpDistance]="scrollUpDistance"
									[infiniteScrollThrottle]="throttle"
									(scrolled)="onParticipantScrollDown()"
									(scrolledUp)="onScrollUp()"
								>
									<mat-list class="list">
										<mat-list-item class="listItem" (click)="viewProfile(participant)" *ngFor="let participant of selectedEvent.slicedParticipantArr">
											<div class="d-flex flex-row justify-content-between align-items-center w-100" style="height: 100%;">
												<div class="d-flex w-100">
													<div class="usernameContainer">
														<div class="username">
															{{participant.callsign}}
														</div>
														<small class="small">- {{CalculateRank(participant.xp)}}</small>
													</div>
													<div class="team">
														{{selected.eventData.eventTeams[participant.team]}}
													</div>
												</div>
											</div>
										</mat-list-item>
									</mat-list>
								</div>
							</div>
						</div>

						<h3
							class="minXpWarning"
							*ngIf="!loginRequired && currentUser.xp < selected.eventData.minimumXp;"
							style="font-size: meduim; margin-top: 8px; margin-bottom: 12px;"
						>
							You need at least {{selected.eventData.minimumXp}} XP to register for this event.
						</h3>

						<div
							class="registerSubGroup"
							data-toggle="buttons"
							*ngIf="!settingStatus && !selected.eventData.minimumXp || (currentUser.xp >= selected.eventData.minimumXp)"
						>
							<!-- registration buttons -->
							<div class="register-group" *ngIf="userRegistrationStatus != null && !loginRequired">
								<!-- LIVE EVENT REGISTRATION -->
								<div *ngIf="userRegistrationStatus.status == 2 && !selected.eventOver">
									<div
										*ngIf="secondsTillStart <= 0"
										#toolTip="matTooltip"
										matTooltip="Open Game Lobby To Claim XP!"
										style="width: 1px; height: 1px;"
										matTooltipPosition="above"
										matTooltipClass="red"
									></div>
									<button mat-flat-button routerLink="/liveEvent" [queryParams]="{eventID: selected.eventID}" color="primary" class="regButton">
										Open Game Lobby
									</button>

									<div style="display: flex; align-items: center; margin-top: 15px;" *ngIf="bookingData?.eventBooked === true">
										<mat-icon aria-hidden="false" aria-label="Example home icon" style="color: #60cf60; margin-right: 15px;">check_circle</mat-icon>
										<span>Paid for event</span>
									</div>
									<div style="display: flex; align-items: center; margin-bottom: 15px;" *ngIf="bookingData?.rentals.length > 0">
										<mat-icon aria-hidden="false" aria-label="Example home icon" style="color: #60cf60; margin-right: 15px;">check_circle</mat-icon>
										<span>{{'Paid for items: ' + bookingData?.rentals.length}} </span>
									</div>

									<app-dialog
										eventID="{{selected.eventID}}"
										[dataToPass]="selected"
										style="max-width: 30%;"
										*ngIf="!EventHasQueue() && teamExists() && selected.canRegister && selected.eventData.eventTeams.length > 1 "
										dialogName="SwitchTeam"
									>
									</app-dialog>
								</div>

								<div>
									<!-- EventRegistration  -->
									<app-dialog
										eventID="{{selected.eventID}}"
										[dataToPass]="selected"
										style="max-width: 30%;"
										*ngIf="!EventHasQueue() && userRegistrationStatus.status != 2 && userRegistrationStatus.status != 3 && !teamExists() && selected.canRegister  "
										dialogName="EventRegistration"
									>
									</app-dialog>
									<app-dialog
										eventID="{{selected.eventID}}"
										[dataToPass]="selected"
										style="max-width: 30%;"
										*ngIf="EventHasQueue() && userRegistrationStatus.status != 2 && userRegistrationStatus.status != 3 && !teamExists() && selected.canRegister"
										dialogName="EventRegistrationQueue"
									>
									</app-dialog>

									<!-- EventRegistration IN THE QUEUE -->
									<button
										mat-flat-button
										*ngIf="userRegistrationStatus.status != null && userRegistrationStatus.status == 3 && positionInQueue != -1 && !selected.eventOver "
										[color]="'primary'"
										class="regButton"
									>
										IN QUEUE {{positionInQueue}}
									</button>

									<!-- EventRegistration POST TEAM SELECTION -->
									<button
										mat-flat-button
										*ngIf="
									userRegistrationStatus.status != null 
									&& userRegistrationStatus.status != 2
									&& userRegistrationStatus.status != 3
									&& teamExists() 
									&& selected.canRegister"
										[color]=" userRegistrationStatus.status == 2 ? 'primary': 'accent'"
										class="regButton"
										(click)="SetRegistrationStatus(2)"
									>
										{{ (EventHasQueue() ? 'Join Queue':'Register for Game' ) }}
									</button>

									<button
										mat-flat-button
										*ngIf="selected.canRegister && userRegistrationStatus.status != 0 && userRegistrationStatus.status != null"
										style="z-index: 0;"
										class="regButton"
										color="accent"
										(click)="SetRegistrationStatus(0)"
									>
										Unregister
									</button>

									<!-- Need to fix the booking API, this is a temporary removal -->

									<app-dialog eventID="{{selected.eventID}}" [dataToPass]="selected" style="max-width: 30%;" *ngIf="CanBook()" dialogName="Booking">
									</app-dialog>

									<app-dialog
										eventID="{{selected.eventID}}"
										[dataToPass]="selected"
										style="max-width: 30%;"
										dialogName="RefundRequest"
										*ngIf="(userRegistrationStatus.status == 2) && (bookingData?.eventBooked === true || bookingData?.rentals.length > 0)"
									>
									</app-dialog>
								</div>
							</div>
							<mat-spinner diameter="60" *ngIf="settingStatus"></mat-spinner>

							<label class="m-3" *ngIf="!selected.canRegister">
								<mat-chip-list class="mat-chip-list-stacked" style="color: white; pointer-events: none;">
									<mat-chip color="accent">
										Event Registration Disabled
									</mat-chip>
								</mat-chip-list>
							</label>

							<p>
								{{ getCount() }} / {{selected.eventData.maxParticipants}} registered
							</p>

							<!-- <button mat-raised-button (click)="RewriteEventRegistrants()">I hope This works</button> -->
						</div>

						<h3 class="text-white" style="margin-top: 10px; margin-bottom: 10px;" *ngIf="loginRequired">
							Login to register for events
						</h3>

						<a
							mat-raised-button
							href="http://maps.google.com/maps?daddr={{selected.eventData.eventPosition.latitude}},{{selected.eventData.eventPosition.longitude}}"
							target="_blank"
						>
							Open in Google Maps <mat-icon>location_on</mat-icon>
						</a>
					</div>

					<div class="flex-flow divider" *ngIf="isMobileWidth()">
						<h1 class="event-title text-white m-3">Participants</h1>
						<div
							class="event-participants"
							infinite-scroll
							[scrollWindow]="false"
							[infiniteScrollDistance]="scrollDistance"
							[infiniteScrollUpDistance]="scrollUpDistance"
							[infiniteScrollThrottle]="throttle"
							(scrolled)="onParticipantScrollDown()"
							(scrolledUp)="onScrollUp()"
						>
							<mat-list class="list">
								<mat-list-item
									class="listItem"
									(click)="viewProfile(participant)"
									*ngFor="let participant of selectedEvent.slicedParticipantArr | orderBy:'timestamp'"
								>
									<div class="d-flex flex-row justify-content-between align-items-center w-100" style="height: 100%;">
										<div class="d-flex w-100">
											<div class="usernameContainer">
												<div class="username">
													{{participant.callsign}}
												</div>
												<small class="small">- {{CalculateRank(participant.xp)}}</small>
											</div>
											<div class="team">
												{{selected.eventData.eventTeams[participant.team]}}
											</div>
										</div>
									</div>
								</mat-list-item>
							</mat-list>
						</div>
					</div>

					<div class="divider">
						<div class="event-time">
							<h1 class="event-timer">
								{{selected.eventHours}}:{{selected.eventMinutes}}:{{selected.eventSeconds}}
							</h1>
							<p class="bold" style="font-size: medium;">
								{{(selected.eventData.eventStartTime.seconds * 1000) | date: 'medium'}} — {{ (selected.eventData.eventEndTime.seconds * 1000) | date:
								'medium'}}
							</p>
						</div>

						<div class="event-details">
							<div class="loadMore" [ngClass]="{'expand': loadMore }">
								<p [innerHTML]="selected.eventData.eventDescription"></p>
							</div>
							<div *ngIf="!loadMore" class="text-white w-100 text-center" (click)="loadMore = true" style="cursor: pointer;">
								<p style="margin-bottom: -5px; font-family: 'Orbitron';">
									See More
								</p>
								<h1>
									<mat-icon>expand_more</mat-icon>
								</h1>
							</div>
						</div>
					</div>

					<div class="divider">
						<h1 class="event-title text-white m-3">Rewards</h1>
						<hr style="background-color: white; width: 90%; margin-right: 10%;" />
						<div class="w-100">
							<p>
								<span class="m-3" matBadge="{{selected.eventData.xp}}" matBadgeOverlap="false">Event XP</span>

								<span class="m-3" matBadge="{{selected.eventData.bonusXP}}" matBadgeOverlap="false">Bonus XP</span>
							</p>
						</div>

						<mat-tab-group dynamicHeight mat-align-tabs="center" class="tab-group profile-tabs">
							<mat-tab label="First" *ngIf="selected.patches && selected.patches.length">
								<ng-template mat-tab-label>
									<div class="text-white">
										Patches
									</div>
								</ng-template>

								<div class="patch-container">
									<div *ngFor="let patch of selected.patches" class="patchItem">
										<img *ngIf="patch[1]" data-src="{{patch[1]}}" class="patchImage" />
										<div *ngIf="patch[0]" class="m-2 text-white">
											{{patch[0].data().patchName}}
											<p class="p-1 text-muted">
												<small>{{patch[0].data().patchDescription}}</small>
											</p>
										</div>
									</div>
								</div>
							</mat-tab>
							<mat-tab label="Second" *ngIf="selected.badges && selected.badges.length">
								<ng-template mat-tab-label>
									<div class="text-white">
										Achievements
									</div>
								</ng-template>

								<div class="badge-container">
									<div *ngFor="let badge of selected.badges" class="badgeItem">
										<img *ngIf="badge[1] " class="badgeImage" data-src="{{badge[1]}}" />
										<div *ngIf="badge[0]" class="m-2 text-white">
											{{badge[0].data().badgeName}}
											<p class="p-1 text-muted">
												<small>-{{badge[0].data().badgeDescription}}</small>
											</p>
										</div>
									</div>
								</div>
							</mat-tab>
						</mat-tab-group>
					</div>

					<div class="event-address-details divider">
						<div class="event-map">
							<agm-map [latitude]="selected.eventData.eventPosition.latitude" [longitude]="selected.eventData.eventPosition.longitude">
								<agm-marker
									[latitude]="selected.eventData.eventPosition.latitude"
									[longitude]="selected.eventData.eventPosition.longitude"
								></agm-marker>
							</agm-map>
						</div>
						<div class="event-address"></div>
					</div>
				</div>
			</div>
		</mat-sidenav-content>
	</mat-sidenav-container>
</div>
