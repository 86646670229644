import { Injectable, EventEmitter } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class ScrollServiceService {


  public scrollToContactUs: EventEmitter<boolean> = new EventEmitter()
  public scrollToAboutUs: EventEmitter<boolean> = new EventEmitter()

  constructor() { }


}
