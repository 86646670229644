<div>
	<form [formGroup]="passwordResetForm" class="d-flex flex-column">
		<mat-form-field>
			<mat-label>Reset Email</mat-label>
			<input matInput type="email" formControlName="userEmail" />
			<mat-hint>enter email to reset password</mat-hint>
			<mat-error *ngIf="passwordResetForm.controls.userEmail.hasError('required')"> Email is <strong>required</strong> </mat-error>
			<mat-error *ngIf="passwordResetForm.controls.userEmail.hasError('email')">
				Invalid Email format
			</mat-error>
		</mat-form-field>
		<button mat-raised-button color="primary" (click)="resetPassword()" [disabled]="!passwordResetForm.controls.userEmail.valid">
			Reset
		</button>
	</form>
</div>
