import { Component, Inject, Input, Output, EventEmitter, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, AbstractControl, FormGroupDirective, NgForm, Validators, ValidationErrors } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { AuthService } from '../services/auth.service';
import { DatabaseService, REGISTRATION_STATUS } from '../services/database.service';
import { interval, Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import firebase from 'firebase/app';
import { SnippetService } from '../services/snippet.service';
import { Platform } from '@angular/cdk/platform';
import { OnChange } from 'property-watch-decorator';
import axios from 'axios';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface DialogData {
	name: string;
}

@Component({
	selector: 'app-dialog',
	templateUrl: './dialog.component.html',
	styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
	@Input() public dialogName: string = 'dialog';
	@Input() public dataToPass: any;
	@Input() public eventID: String;
	@Output() public dialogRef: EventEmitter<any>;

	constructor(public dialog: MatDialog) {
		this.dialogRef = new EventEmitter(false);
	}

	openLoginDialog(): void {
		this.dialogRef.emit(
			this.dialog
				.open(LoginComponent, {
					width: '400px',
					data: { name: this.dialogName },
				})
				.beforeClosed()
		);

		// this.dialog.open(EmailReminderDialog)
	}

	openEventDialog(): void {
		this.dialogRef.emit(
			this.dialog
				.open(EventDialogComponent, {
					height: '500px',
					data: {
						name: this.dialogName,
						loadedEvent: this.dataToPass,
						eventID: this.eventID,
					},
				})
				.beforeClosed()
		);
	}

	openRegistrationDialog(): void {
		this.dialogRef.emit(
			this.dialog
				.open(UserRegistrationDialogComponent, {
					width: '80%',
					height: '95vh',

					data: {},
					disableClose: true,
				})
				.beforeClosed()
		);

		// this.dialog.open(EmailReminderDialog)
	}

	openPasswordResetDialog() {
		this.dialogRef.emit(
			this.dialog
				.open(PasswordResetDialog, {
					width: '400px',
					data: {},
				})
				.beforeClosed()
		);
	}
	openBookingDialog() {
		this.dialogRef.emit(
			this.dialog
				.open(BookingDialogComponent, {
					width: '600px',
					data: {
						name: this.dialogName,
						loadedEvent: this.dataToPass,
						eventID: this.eventID,
					},
					panelClass: 'booking-panel',
				})
				.beforeClosed()
		);
	}
	openRefundDialog() {
		this.dialogRef.emit(
			this.dialog
				.open(RefundNotificationComponent, {
					width: '600px',
					data: {
						name: this.dialogName,
					},
				})
				.beforeClosed()
		);
	}
}

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		const isSubmitted = form && form.submitted;
		return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
	}
}

@Component({
	selector: 'login-dialog',
	templateUrl: 'login-dialog.html',
})
export class LoginComponent implements AfterViewInit {
	hide = true;

	constructor(
		public authService: AuthService,
		public dialogRef: MatDialogRef<LoginComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData,
		private snippet: SnippetService,
		private db: DatabaseService,
		public platform: Platform
	) {}

	viewInit = false;

	ngAfterViewInit() {
		this.viewInit = true;

		this.emailFormControl.valueChanges.subscribe((value) => {
			this.EmailTrimValidation();
		});
	}

	previousEmail: string = '';
	EmailTrimValidation() {
		if (this.viewInit) {
			let nonTrimmed: string = this.emailFormControl.value;

			if (nonTrimmed != null && this.previousEmail.localeCompare(nonTrimmed) != 0) {
				this.previousEmail = this.emailFormControl.value;
				this.emailFormControl.setValue(nonTrimmed.trim());

				this.emailFormControl.updateValueAndValidity();
			}
		}

		return null;
	}

	emailFormControl = new FormControl(null, {
		validators: [Validators.required, Validators.email],
	});

	passwordFormControl = new FormControl('', [Validators.required]);

	getAuthService() {
		return this.authService.user != null;
	}

	matcher = new MyErrorStateMatcher();

	onNoClick(): void {
		this.dialogRef.close();
	}

	onGoogleClick() {
		this.authService.loginWithGoogle().then();
	}

	onGoogleClickIOS() {
		this.authService.onGoogleClickIOS();
	}

	onLogin() {
		this.authService
			.login(this.emailFormControl.value, this.passwordFormControl.value)
			.then(
				(success) => {
					this.authService.afAuth.currentUser.then((user) => {
						if (user.emailVerified) {
							try {
								this.db.RedeemXP(user.uid, user.email.toLowerCase());
							} catch (error) {
								console.log('no Xp to redeem');
							}
							firebase.analytics().logEvent('login', {
								method: 'user details',
							});
							this.snippet.openSuccessSnackBar('Welcome back');
						} else {
							this.snippet.openErrorSnackBar('Please verify you email address before login');
							this.authService.logout();
						}
					});
					this.dialogRef.close();
				},
				(err) => {
					this.snippet.openErrorSnackBar(err);
					this.passwordFormControl.reset();
				}
			)
			.catch((err) => {
				this.snippet.openErrorSnackBar(err);
				this.passwordFormControl.reset();
			});
	}
}

@Component({
	selector: 'event-registration-dialog',
	templateUrl: './event-registration-dialog.html',
})
export class EventDialogComponent implements OnDestroy, OnInit {
	eventRegSub: Subscription;
	LoadingTeams: boolean = true;

	teamsCountIndices = this.injected.loadedEvent.eventData.eventTeams.map((a) => (a = 0));
	constructor(
		public dialogRef: MatDialogRef<EventDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public injected: any,
		private db: DatabaseService,
		private auth: AuthService,
		private snippet: SnippetService
	) {}

	ngOnInit() {
		this.LoadingTeams = true;
		this.injected.loadedEvent.totalEventParticipants.forEach((participant) => {
			this.teamsCountIndices[participant.team]++;
		});
		this.LoadingTeams = false;
	}

	ngOnDestroy() {
		if (this.eventRegSub) this.eventRegSub.unsubscribe();
	}

	TeamIsFull(teamIndex: number): boolean {
		if (this.teamsCountIndices[teamIndex] >= this.injected.loadedEvent.eventData.eventTeamsMaxPlayers[teamIndex]) return true;
		return false;
	}

	SwitchUserTeam(index: number) {
		if (this.TeamIsFull(index)) return;

		if (this.registrationSub != null) {
			this.registrationSub.unsubscribe();
		}

		this.registrationSub = this.auth.user.subscribe((user) => {
			let switchSub = this.db
				.SwitchUsersTeams(this.injected.eventID, [user.uid], index, user.uid, this.injected.loadedEvent.eventData.eventTitle)
				.subscribe(
					(batch) => {
						this.registrationSub.add(switchSub);
						this.snippet.openSuccessSnackBar('Team Switched successfully');
						this.registrationSub.unsubscribe();
						this.dialogRef.close();
					},
					(err) => {
						console.error(err);
						this.registrationSub.add(switchSub);
						this.snippet.openErrorSnackBar('Error switching team contact Admin if this persists');
						this.registrationSub.unsubscribe();
						this.dialogRef.close();
					}
				);
		});
	}

	registrationSub: Subscription;
	RegisterForEvent(index: number) {
		if (this.TeamIsFull(index)) return;

		if (this.registrationSub != null) {
			this.registrationSub.unsubscribe();
		}

		this.registrationSub = this.auth.user.subscribe(
			(user) => {
				this.eventRegSub = this.db.GetUserRegistrationStatus(this.injected.eventID, user.uid).subscribe(
					(data) => {
						this.registrationSub.add(this.eventRegSub);

						if (data.payload.exists && Object.entries(data.payload.data())['team'] != null) {
							this.snippet.openErrorSnackBar('Error Previously Registered with team contact Admin if this persists');
							this.registrationSub.unsubscribe();
						} else {
							//2 is not registered for the event
							this.registrationSub.add(
								this.db
									.SetUserRegistrationStatus(this.injected.eventID, user.uid, REGISTRATION_STATUS.Attending, this.injected.loadedEvent, {
										team: index,
									})
									.subscribe(
										(statusOut) => {
											if (statusOut == REGISTRATION_STATUS.InQueue) {
												this.snippet.openSuccessSnackBar('Joined Event Queue');
												firebase.analytics().logEvent('register_event', {
													event_registration: 'true',
													event_title: this.injected.loadedEvent.eventData.eventTitle,
												});
											} else if (statusOut == REGISTRATION_STATUS.Attending) {
												firebase.analytics().logEvent('register_event', {
													event_registration: 'true',
													event_title: this.injected.loadedEvent.eventData.eventTitle,
												});
												this.snippet.openSuccessSnackBar('Registered');
											}

											this.registrationSub.unsubscribe();
											this.dialogRef.close();
										},
										(err) => {
											this.snippet.openErrorSnackBar(err.message);
										}
									)
							);
							this.dialogRef.close();
						}
					},
					(err: any) => {
						throw err;
					}
				);
			},
			(err: any) => {
				this.snippet.openErrorSnackBar('An Error occured registering for event');
				// console.log(err);
				this.registrationSub.unsubscribe();
			}
		);
	}
}

@Component({
	selector: 'userRegistration-dialog',
	templateUrl: './registration-dialog.html',
})
export class UserRegistrationDialogComponent implements OnInit {
	NotUnique = false;
	hide = true;
	passwordMismatch = false;
	userCreated = false;
	user: any = null;

	constructor(
		public authService: AuthService,
		private db: DatabaseService,
		public dialogRef: MatDialogRef<UserRegistrationDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData,
		private snippet: SnippetService
	) {}

	ClearWhiteSpace(control: AbstractControl) {
		control.setValue(control.value.trim());
	}

	ngOnInit() {}

	getAuthService() {
		return this.authService.user != null;
	}

	onGoogleClick() {
		this.authService
			.loginWithGoogle()
			.then()
			.catch((err) => console.error(err));
	}

	async uploadProfilePicture(imageInput: any) {
		const file: File = imageInput.files[0];
		const reader = new FileReader();

		// this.profileUrl = this.db.profileUpload;
		reader.addEventListener('load', (event: any) => {
			let registrationSub = this.authService.user.subscribe(
				(user) => {
					let uploadSub = this.db
						.uploadFile(file, user.uid)
						.then((uploadPercent) => {
							this.snippet.openSuccessSnackBar('image uploaded');
						})
						.catch(() => {
							this.snippet.openErrorSnackBar('image upload failed!');
						});
				},
				(err: any) => {
					registrationSub.unsubscribe();
				},
				() => {
					registrationSub.unsubscribe();
				}
			);
		});
	}

	registerUser() {
		if (this.regForm.valid) {
			this.authService.afAuth
				.createUserWithEmailAndPassword(String(this.regForm.controls.email.value).trim(), this.regForm.controls.password.value)
				.then((userDoc) => {
					this.user = userDoc.user;

					let obj = {
						admin: false,
						marshal: false,
						userID: String(this.regForm.value.username).trim(),
						fullname: String(this.regForm.value.fullname).trim(),
						matchesPlayed: 0,
						phoneNumber: this.regForm.value.phone,
						serviceTime: firebase.firestore.Timestamp.fromDate(new Date()),
						social: this.regForm.value.social,
						xp: 0,
						badges: {},
						patches: {},
						referral: this.regForm.value.referral,
						usedRefferal: false,
					};

					this.db
						.CreateNewUserDocument(obj, userDoc.user.uid)
						.then(() => {
							try {
								this.db.RedeemXP(userDoc.user.uid, userDoc.user.email.toLowerCase());
							} catch (error) {
								if (error == 1) this.db.RedeemXPError(userDoc.user.email.toLowerCase());
								else console.log('no Xp to redeem');
							}
							this.userCreated = true;
							this.closeDialog();
							this.authService.logout();
							firebase.analytics().logEvent('sign_up', {
								method: 'user details',
							});
							this.snippet.openSuccessSnackBar('Email verification sent please check your spam and junk folders');
						})
						.catch((err) => {
							throw err;
						});

					userDoc.user.sendEmailVerification();
				})
				.catch((err) => {
					if (err.code == 'auth/email-already-in-use') {
						this.snippet.openErrorSnackBar('Email already in use');
						this.regForm.controls.email.setErrors({
							saveError: err.message,
						});
					}
					this.snippet.openErrorSnackBar('An Error occured during registration');
				});
		} else {
			this.snippet.openErrorSnackBar('Registration form invalid');
		}
	}

	closeDialog() {
		this.dialogRef.close();
	}

	usernameValidation(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
		return this.db.CheckIfUserExists(control.value).pipe(
			map((data) => {
				if (!data.empty) {
					this.NotUnique = true;
					return of({ NotUnique: 'Username is not unique' });
				} else {
					this.NotUnique = false;
					return null;
				}
			})
		);
	}

	previousEmail: string = '';
	EmailTrimValidation(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
		let nonTrimmed: string = this.regForm.controls.email.value;
		if (nonTrimmed != null && this.previousEmail.localeCompare(nonTrimmed) != 0) {
			this.previousEmail = this.regForm.controls.email.value;
			this.regForm.controls.email.setValue(nonTrimmed.trim());
		}
		return of({});
	}

	MustMatch(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
		if (this.regForm != null) {
			if (this.regForm.controls.confirmPassword.value != this.regForm.controls.password.value) {
				this.passwordMismatch = true;
				return of({ passwordMismatch: true });
			}
			this.passwordMismatch = false;
		}
		return null;
	}

	regForm = new FormGroup({
		email: new FormControl(null, {
			updateOn: 'change',
			validators: [Validators.email, Validators.required],
			asyncValidators: this.EmailTrimValidation.bind(this),
		}),
		password: new FormControl(null, {
			updateOn: 'change',
			validators: [Validators.pattern(new RegExp('^(?=.*\\d).{8,20}$')), Validators.required],
		}),
		confirmPassword: new FormControl(null, {
			validators: [this.MustMatch.bind(this), Validators.required],
		}),
		username: new FormControl(null, {
			updateOn: 'change',
			validators: [Validators.pattern('^\\s*[A-Za-z0-9]+(?:[ _-][A-Za-z0-9]+)*\\s*$'), Validators.required, Validators.maxLength(24)],
			asyncValidators: [this.usernameValidation.bind(this)],
		}),
		fullname: new FormControl(null, {
			updateOn: 'change',
			validators: [Validators.pattern("^\\s*[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*\\s*$"), Validators.required, Validators.maxLength(30)],
		}),
		phone: new FormControl(null, {
			updateOn: 'change',
			validators: [
				Validators.pattern('^\\d{3}(-| )*\\d{3}(-| )*\\d{4}$|^\\+\\d{4}(-| )*\\d{3}(-| )*\\d{4}$|^\\+\\d{2}(-| )*\\d{2}(-| )*\\d{3}(-| )*\\d{4}$'),
			],
		}),
		social: new FormControl(null, {
			updateOn: 'change',
			validators: Validators.max(35),
		}),
		referral: new FormControl(null, {
			updateOn: 'change',
			validators: [Validators.email],
		}),
	});
}

@Component({
	selector: 'password-reset-dialog',
	templateUrl: './password-reset-dialog.html',
})
export class PasswordResetDialog {
	constructor(
		private db: DatabaseService,
		public dialogRef: MatDialogRef<PasswordResetDialog>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private auth: AuthService,
		private snippet: SnippetService
	) {}

	passwordResetForm = new FormGroup({
		userEmail: new FormControl(null, {
			validators: [Validators.email, Validators.required],
			updateOn: 'change',
		}),
	});

	resetPassword() {
		if (this.passwordResetForm.valid) {
			this.auth.sendPasswordResetEmail(this.passwordResetForm.controls.userEmail.value).then(
				() => {
					this.dialogRef.close();

					this.snippet.openSuccessSnackBar('Password Reset sent, please be patient');
				},
				(err) => {
					this.dialogRef.close();
					this.snippet.openErrorSnackBar('Error Previously Registered with team Consult Admin if this persists');
				}
			);
		}
	}
}

@Component({
	selector: 'email-reminder-dialog',
	templateUrl: './email-reminder-dialog.html',
})
export class EmailReminderDialog {}

@Component({
	selector: 'booking-dialog',
	templateUrl: './booking-dialog.html',
})
export class BookingDialogComponent implements OnInit {
	@Input() public dataToPass: any;
	selectedRentals: any;
	paymentStarted: boolean;

	constructor(
		public dialogRef: MatDialogRef<BookingDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public injected: any,
		private db: DatabaseService,
		private auth: AuthService,
		private snippet: SnippetService,
		private snackBar: MatSnackBar
	) {}
	//@ts-ignore

	eventCost: string;
	eventData: any;

	@OnChange('onTotalChange')
	totalCost: number;

	bookEvent: boolean;
	warning: string;
	inline: any;
	selectionCost: number;
	bookingCost: number;

	hasMinXP: boolean = false;
	userXP: number = 0;

	tickDelay: Observable<Number> = interval(1000);
	tickSub: Subscription;
	bookingHours: string = '0';
	bookingMinutes: string = '00';
	bookingSeconds: string = '00';

	bookingExpired: boolean = false;

	RestartTimer() {
		this.tickSub = this.tickDelay.subscribe((time) => {
			const epochNow = new Date().getTime() / 1000;

			let totalSeconds: any = (Number(this.eventData.bookingCutoffTime?.seconds || 0) - Number(epochNow)).toFixed(0);
			let hours,
				minutes,
				seconds = 0;
			hours = Math.floor(totalSeconds / 3600);
			totalSeconds %= 3600;
			minutes = Math.floor(totalSeconds / 60);
			seconds = totalSeconds % 60;

			if (hours + minutes + seconds <= 0) {
				hours = 0;
				minutes = 0;
				seconds = 0;
				this.bookingExpired = true;
			}
			this.bookingHours = String(hours);
			this.bookingMinutes = minutes <= 9 ? '0'.concat(String(minutes)) : String(minutes);
			this.bookingSeconds = seconds <= 9 ? '0'.concat(String(seconds)) : String(seconds);
		});
	}

	onTotalChange(value) {
		console.log(value);
	}

	openSnackBar(message: string) {
		this.snackBar.open(message, undefined, {
			duration: 3000,
		});
	}

	toCurrency(value) {
		return value.toLocaleString('en-ZA', {
			style: 'currency',
			currency: 'ZAR',
		});
	}

	ngOnInit(): void {
		this.eventData = this.injected.loadedEvent.eventData;
		this.eventCost = this.eventData.eventCost;
		this.totalCost = 0;
		this.bookEvent = false;
		this.RestartTimer();
		this.db.GetUserXP(this.auth.user.value.uid).subscribe((xp) => {
			this.userXP = xp;
			if (!this.eventData.minEventBookingXP) {
				this.hasMinXP = true;
			} else {
				this.hasMinXP = this.userXP >= this.eventData.minEventBookingXP;
			}
		});
	}

	handlePayment() {
		this.paymentStarted = true;
		let Dialog = this;
		Dialog.warning = '';
		axios
			.post('https://us-central1-cofairsoft-a1d63.cloudfunctions.net/handlePayment', {
				data: {
					eventID: Dialog.injected.eventID,
					eventBooked: Dialog.bookEvent,
					rentals: Dialog.selectedRentals?.map((rental) => rental.description) || [],
					userID: Dialog.auth.user.value.uid,
				},
			})
			.then((data) => {
				//@ts-ignore
				window.open(data?.data.checkoutData.redirectUrl);
			})
			.catch((e) => {
				console.error(e);
			});
	}

	calcTotal() {
		this.totalCost = (this.bookEvent ? this.bookingCost || 0 : 0) + (this.selectionCost || 0);
	}

	bookEventChange(val) {
		this.bookEvent = val;
		if (val === true) {
			this.bookingCost = this.eventData.eventCost;
		} else if (val === false) {
			this.bookingCost = 0;
		}
		this.calcTotal();
	}

	selectionChange(options) {
		this.selectedRentals = options.map((e) => e.value);
		this.selectionCost = options.map((e) => e.value.cost).reduce((prev, current) => prev + current, 0);
		this.calcTotal();
	}
}

@Component({
	selector: 'refund-notification-dialog',
	templateUrl: './refund-notification-dialog.html',
})
export class RefundNotificationComponent implements OnInit {
	@Input() public dataToPass: any;
	constructor(public dialogRef: MatDialogRef<BookingDialogComponent>, @Inject(MAT_DIALOG_DATA) public injected: any) {}
	ngOnInit(): void {}
}
