<div #mainEvent class="eventsContainerHeading">MAIN EVENTS</div>
<div class="eventsContainer" id="shadow" *ngIf="!loading">
    <!-- background -->


    <div class="image-container-background-overlay" [style.background-image]="sanitize(images[currentState])">
        <div class="flex-responsive">

            <div class="image-container">
                <div class="card-container">
                    <mat-card class="card" (mouseover)="toggle(0)" routerLink="/events"
                        [queryParams]="{eventID: mainEvents[0].key}">
                        <mat-card-header>

                            <mat-card-title class="text-white">{{mainEvents[0].data.eventTitle}}</mat-card-title>
                            <mat-card-subtitle class="text-white ">
                                <p style="font-size: small" class="lead text-white">
                                    {{mainEvents[0].data.eventStartTime.seconds*1000 |
                                    date:'medium'}}</p>
                            </mat-card-subtitle>
                        </mat-card-header>
                        <img mat-card-image class="eventImage" src="{{images[0]}}">
                        <mat-card-content>

                            <div class="event-details p-2" *ngIf="mainEvents[0]">


                                <p class="lead" *ngIf="mainEvents[0].key">
                                    <a class="btn btn-danger btn" routerLink="/events"
                                        [queryParams]="{eventID: mainEvents[0].key}" href="#" role="button">View
                                        Event</a>
                                </p>
                            </div>
                        </mat-card-content>
                    </mat-card>
                    <mat-card class="card" (mouseover)="toggle(1)" routerLink="/events"
                        [queryParams]="{eventID: mainEvents[1].key}">
                        <mat-card-header s>

                            <mat-card-title class="text-white text-left">{{mainEvents[1].data.eventTitle}}
                            </mat-card-title>
                            <mat-card-subtitle class="text-white">
                                <p style="font-size: small" class="lead text-white">
                                    {{mainEvents[1].data.eventStartTime.seconds*1000 |
                                    date:'medium'}}</p>
                            </mat-card-subtitle>
                        </mat-card-header>
                        <img mat-card-image class="eventImage" src="{{images[1]}}">
                        <mat-card-content>
                            <div class="event-details p-2" *ngIf="mainEvents[0]">
                                <p class="lead" *ngIf="mainEvents[1].key">
                                    <a class="btn btn-danger btn" routerLink="/events"
                                        [queryParams]="{eventID: mainEvents[1].key}" href="#" role="button">View
                                        Event</a>
                                </p>
                            </div>
                        </mat-card-content>
                    </mat-card>
                    <mat-card class="card" (mouseover)="toggle(2)" routerLink="/events"
                        [queryParams]="{eventID: mainEvents[2].key}">
                        <mat-card-header>

                            <mat-card-title class="text-white">{{mainEvents[2].data.eventTitle}}</mat-card-title>
                            <mat-card-subtitle class="text-white">
                                <p style="font-size: small" class="lead text-white">
                                    {{mainEvents[2].data.eventStartTime.seconds*1000 |
                                    date:'medium'}}</p>
                            </mat-card-subtitle>

                        </mat-card-header>
                        <img mat-card-image class="eventImage" src="{{images[2]}}">
                        <mat-card-content>
                            <div class="event-details p-2" *ngIf="mainEvents[2]">
                                <p class="lead" *ngIf="mainEvents[2].key">
                                    <a class="btn btn-danger btn" routerLink="/events"
                                        [queryParams]="{eventID: mainEvents[2].key}" href="#" role="button">View
                                        Event</a>
                                </p>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>


                <!-- <div (mouseover)="toggle(0)" class=" triangle1" (click)="ScrollToMainEvent(0)"
                    [ngClass]="currentState == 0 ? 'image-triangleActive': 'image-triangle'">
                    <div class="image1 " [ngClass]="currentState == 0 ? 'imageActive': 'image'"
                        [style.background-image]="sanitize(images[0])">
                        <div class="event-text text-1 ">
                            <p>{{mainEvents[0].data.eventTitle}}</p>
                        </div>
                    </div>
                </div>

                <div (mouseover)="toggle(2)" class=" triangle3" (click)="ScrollToMainEvent(2)"
                    [ngClass]="currentState == 2 ? 'image-triangleActive': 'image-triangle'">
                    <div class=" image3" [ngClass]="currentState == 2 ? 'imageActive': 'image'"
                        [style.background-image]="sanitize(images[2])">
                        <div class="event-text text-3">
                            <p>{{mainEvents[2].data.eventTitle}}</p>
                        </div>
                    </div>
                </div>


                <div (mouseover)="toggle(1)" class=" triangle2" (click)="ScrollToMainEvent(1)"
                    [ngClass]="currentState == 1 ? 'image-triangleActive': 'image-triangle'">
                    <div class=" image2" [ngClass]="currentState == 1 ? 'imageActive': 'image'"
                        [style.background-image]="sanitize(images[1])">
                        <div class="event-text text-2">
                            <p>{{mainEvents[1].data.eventTitle}}</p>
                        </div>
                    </div>

                </div> -->

            </div>
        </div>

    </div>
</div>
<div>
    <mat-spinner *ngIf="loading"></mat-spinner>
</div>