<div class="contactUsContainer">
	<h1 class="ContactUsHeading">Contact Us</h1>

	<div [ngClass]="!platform.IOS && !platform.SAFARI ? 'wrapper' : 'wrapperNonFixed'">
		<mat-list class="details">
			<div mat-subheader>Details</div>
			<mat-list-item>
				<mat-icon mat-list-icon>phone</mat-icon>
				<div mat-line>Paul</div>
				<div mat-line>082 351 9476</div>
			</mat-list-item>
			<mat-list-item>
				<mat-icon mat-list-icon>phone</mat-icon>
				<div mat-line>Jessica</div>
				<div mat-line>082 613 3630</div>
			</mat-list-item>
			<mat-list-item>
				<mat-icon mat-list-icon>email</mat-icon>
				<div mat-line>Email</div>
				<div mat-line>cyborgza@hotmail.com</div>
			</mat-list-item>
			<mat-list-item>
				<mat-icon mat-list-icon>book</mat-icon>
				<a mat-line href="https://www.privacypolicygenerator.info/live.php?token=7x94uJJRiRTIS5lM3wQxD0HqAskiM3xp" target="_blank"
					>View Our Privacy Policy</a
				>
			</mat-list-item>

			<mat-divider></mat-divider>
			<div mat-subheader>Social</div>
			<mat-list-item>
				<mat-icon mat-list-icon>social</mat-icon>
				<div mat-line>
					<a target="_" href="https://www.facebook.com/groups/242135326264351">
						<img style="margin: 10px; width: 45px; height: 45px;" src="../../assets/images/facebook.png" />
					</a>
					<a target="_" href="https://www.instagram.com/cofairsoft">
						<img style="margin: 10px; width: 45px; height: 45px;" src="../../assets/images/instagram.png" />
					</a>
				</div>
			</mat-list-item>
		</mat-list>
		<h3 class="FranchisingHeading">Franchising</h3>
		<h4 class="FranchisingSubHeading">Want to start a club?</h4>
		<div [ngClass]="!platform.IOS && !platform.SAFARI ? 'wrapper' : 'wrapperNonFixed'">
			<mat-list class="details">
				<mat-list-item>
					<mat-icon mat-list-icon>phone</mat-icon>
					<div mat-line>Grant</div>
					<div mat-line>079 517 5640</div>
					<div mat-line>grant@flg.co.za</div>
				</mat-list-item>
			</mat-list>
		</div>
	</div>
</div>
