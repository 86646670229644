<button *ngIf="dialogName == 'Login'" mat-raised-button (click)="openLoginDialog()">
	{{dialogName}}
</button>

<button mat-flat-button *ngIf="dialogName == 'EventRegistration'" class="m-3" color="accent" (click)="openEventDialog()">
	Register for Event
</button>
<button mat-flat-button *ngIf="dialogName == 'SwitchTeam'" class="m-3" color="secondary" (click)="openEventDialog()">
	Switch Team
</button>
<button mat-flat-button *ngIf="dialogName == 'EventRegistrationQueue'" color="accent" (click)="openEventDialog()">
	Join Queue
</button>
<button (click)="openRegistrationDialog()" *ngIf="dialogName == 'userRegistration'" mat-raised-button color="primary">
	Register
</button>
<button (click)="openPasswordResetDialog()" color="accent" *ngIf="dialogName == 'resetPassword'" mat-raised-button>
	Reset Password
</button>
<button (click)="openBookingDialog()" color="basic" *ngIf="dialogName == 'Booking'" mat-raised-button>
	Book Tickets/Rentals
</button>
<button (click)="openRefundDialog()" color="basic" *ngIf="dialogName == 'RefundRequest'" mat-raised-button>
	Request Refund
</button>
