import { Component, OnInit } from '@angular/core';

declare var particlesJS: any;

@Component({
	selector: 'app-nodes',
	templateUrl: './nodes.component.html',
	styleUrls: ['./nodes.component.scss']
})
export class NodesComponent implements OnInit {

	particlesArray: Array<any>;
	public particleName = "";

	constructor() { }

	public loadLandingConfig(): void {
		particlesJS.load('particlesLanding', '../../assets/particles.json', function () {

		});
	}

	public loadLiveEventsDetailConfig(): void {
		particlesJS.load('particlesLiveEventsDetailsProfile', '../../assets/particlesEventsDetail.json', function () {

		});
	}

	public loadLiveRanksConfig(): void {
		particlesJS.load('particlesRanks', '../../assets/ranks.json', function () {

		});
	}

	ngOnInit(): void {
	}
}
